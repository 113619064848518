import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { createYears, existingMonths } from "../utils/dates";
import { capitalize } from "../utils/capitalize";
import SuccessToast from "../components/SuccessToast";

const useStyles = makeStyles((theme) => ({
  formInput: {
    display: "flex",
    height: 26,
    width: "100%",
    justifyContent: "space-between",
    padding: -10,
  },
  newExpected: {
    marginTop: 10,
  },
  indicator: {
    marginRight: 10,
    fontSize: 12,
  },
  inputIndicatorNewExpected: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 0.1,
    borderColor: "grey",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    marginTop: 0,
  },
  titleExpected: {
    marginBottom: -15,
    marginTop: 10,
  },
  select: {
    display: "flex",
    marginTop: 19,
    padding: 2,
  },
}));

const NewExpected = ({ agency, handleClose, reload }) => {
  const { get, post } = useFetch();
  const existingYears = createYears();
  const [inputs, setInputs] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    capitalize(new Date().toLocaleDateString("fr-FR", { month: "long" }))
  );
  const [existingExpected, setExistingExpected] = useState([]);
  const [completedDate, setCompletedDate] = useState([]);

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };
  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };
  const classes = useStyles();

  useEffect(() => {
    const getIndicators = async () => {
      const data = await get("indicators");
      setIndicators(data);
      setInputs(data.map((e) => {
        return { group: e.group, name: e.name, id: e._id, value: 0 }
      }))
    };
    getIndicators();
  }, []);

  useEffect(() => {
    const getExpected = async () => {
      const data = await get(
        `expected/agency?year=${year}&agencyId=${agency._id}`
      );
      setExistingExpected(data);
    };
    getExpected();
  }, [agency, year]);

  const handleChange = (e, indicator) => {
    setInputs([
      ...inputs,
      { group: indicator.group, name: e.target.name, id: indicator._id, value: e.target.value },
    ]);
  };

  const handleSubmit = async (e) => {
    function compare(a, b) {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    }
    e.preventDefault();
    inputs.sort(compare);
    const indicators = inputs
      .slice()
      .reverse()
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    const data = {
      agency: agency._id,
      year: year,
      month: month,
      indicator: indicators,
    };
    //console.log(indicators)
    await post("expected/", data);
    SuccessToast("Attendu créé !");
    setInputs([]);
    handleClose();
    reload();
  };

  //console.log(inputs)

  return (
    <div>
      <h1 className={classes.titleExpected}>
        Nouvel attendu pour l'agence {agency.name}
      </h1>
      <div className={classes.select}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={month}
          onChange={handleChangeMonth}
        >
          {existingMonths.map((month) => (
            <MenuItem value={month}>{month}</MenuItem>
          ))}
        </Select>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={year}
          onChange={handleChangeYear}
        >
          {existingYears.map((year) => (
            <MenuItem value={year}>{year}</MenuItem>
          ))}
        </Select>
      </div>
      {existingExpected &&
      existingExpected.length > 0 &&
      existingExpected.map(
        (expected) =>
          expected.year === year &&
          expected.month === month &&
          !completedDate.includes(expected.month + expected.year) &&
          setCompletedDate((completedDate) => [
            ...completedDate,
            expected.month + expected.year,
          ])
      ) &&
      completedDate.includes(month + year) ? (
        <h1>Vous avez déjà créé ce formulaire</h1>
      ) : (
        <form onSubmit={handleSubmit} autoComplete="off">
          {indicators.map((indicator) => (
            <div
              className={classes.formInput}
              style={{
                border: "1px black solid",
                margin: "5px 0",
                padding: "0 0 0 3px",
              }}
            >
              <p className={classes.indicator}>{indicator.group} - {indicator.name}</p>
              <input
                className={classes.inputIndicatorNewExpected}
                type="number"
                name={indicator.name}
                value={inputs.name}
                onChange={(e) => handleChange(e, indicator)}
              />
            </div>
          ))}
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.newExpected}
            type="submit"
            onClick={handleSubmit}
          >
            Enregistrer
          </Button>
        </form>
      )}
    </div>
  );
};

export default NewExpected;
