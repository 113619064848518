import React, { useContext, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DataGrid } from '@mui/x-data-grid'

import Header from '../components/header/header'
import UserContext from '../contexts/UserContext'
import { getUsers } from '../NewAPI'
import { useDidMountEffect } from '../utils/hooks'

const Documents = () => {
  const { user } = useContext(UserContext)

  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)

  const [rules, setRules] = useState([])
  const [agencies, setAgencies] = useState([])
  const [activities, setActivities] = useState([])

  const [ruleFilter, setRuleFilter] = useState(null)
  const [agencyFilter, setAgencyFilter] = useState(null)
  const [activityFilter, setActivityFilter] = useState(null)

  const retrieveSavedFilters = async () => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {}
    const usersFilters = filters?.users || {}
    setRuleFilter(usersFilters.rule)
    setAgencyFilter(usersFilters.agency)
    setActivityFilter(usersFilters.activity)
  }

  const saveFilter = async (key, value) => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {}
    const usersFilters = filters?.users || {}
    if (value) usersFilters[key] = value
    else delete(usersFilters[key])
    filters.users = usersFilters
    localStorage.setItem('filters', JSON.stringify(filters))
  }

  const saveRuleFilter = async (value) => {
    setRuleFilter(value)
    saveFilter('rule', value)
  }

  const saveAgencyFilter = async (value) => {
    setAgencyFilter(value)
    saveFilter('agency', value)
  }

  const saveActivityFilter = async (value) => {
    setActivityFilter(value)
    saveFilter('activity', value)
  }

  const fetchUsers = async () => {
    setLoading(true)
    const response = await getUsers()
    if (response.err !== null) {
      setData([])
    }

    // Add "GROUPE ETPO" agency for global entities
    const fixed = response.map((e) => {
      const data = { ...e, id: e._id }
      if (e.rules.rules === 'Direction groupe + RQSE') {
        let newData = { ...data }
        if (!e.agency || e.agency.length === 0) newData = { ...newData, agency: ['GROUPE ETPO'] }
        if (!e.activity || e.activity.length === 0) newData = { ...newData, activity: ['TMF - TSM'] }
        return newData
      }
      return data
    })

    // Setup filters values
    let rules = fixed.map((e) => e.rules.rules)
    rules = [...new Set(rules.flat())]
    rules.sort()
    setRules(rules)
    let agencies = fixed.map((e) => e.agency)
    agencies = [...new Set(agencies.flat())]
    agencies.sort()
    setAgencies(agencies)
    let activities = fixed.map((e) => e.activity)
    activities = [...new Set(activities.flat())]
    activities.sort()
    setActivities(activities)

    setData(fixed)
    setLoading(false)
  }

  useEffect(() => {
    retrieveSavedFilters().then(() => {
      fetchUsers()
    })
  }, [])

  useDidMountEffect(() => {
    const filtered = data.filter((e) => {
      return (!ruleFilter || e.rules.rules === ruleFilter)
        && (!agencyFilter || e.agency?.includes(agencyFilter))
        && (!activityFilter || e.activity?.includes(activityFilter))
    })
    setFilteredData(filtered)
  }, [data, ruleFilter, agencyFilter, activityFilter])

  const columns = [
    { field: 'firstName', headerName: 'Prénom', flex: 1 },
    { field: 'lastName', headerName: 'Nom', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'rules',
      headerName: 'Droit',
      flex: 1,
      valueGetter: (value) => value.value.rules,
    },
    { field: 'phoneNumber', headerName: 'Numéro de téléphone', flex: 1 },
    {
      field: 'agency',
      headerName: 'Agence(s) / Filiale(s)',
      flex: 1,
      valueGetter: (value) => value.value?.join(', '),
    },
    {
      field: 'activity',
      headerName: 'Métier(s)',
      flex: 1,
      valueGetter: (value) => value.value?.join(', '),
    },
    {
      field: 'creationDate', headerName: 'Date d\'ajout', flex: 1, valueGetter: (params) => {
        return new Date(params.value).toLocaleDateString('fr-FR')
      },
    },
    {
      field: ' ',
      headerName: ' ',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ width: '100%', display: 'inline-flex', alignItems: 'end', justifyContent: 'end' }}>
            <Route render={({ history }) => (
              <Button variant="contained" color="info" onClick={(e) => {
                history.push(
                  `/selecteduser?item=${params.row._id}`,
                )
              }}>Éditer</Button>
            )}/>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <Header/>
      {user?.rules.rules !== 'Utilisateurs application' && user?.rules.rules !== 'Maîtrise' ? (
        <>
          <div className="columns is-centered" style={{ marginTop: 44 }}>
            <div className="column is-12">
              <h1 className="has-text-weight-bold	is-size-1">
                Gestion des comptes
              </h1>
            </div>
          </div>

          <div style={{ display: 'flex', height: '100%', marginBottom: 40, marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Autocomplete
              disablePortal
              id="filter-rules"
              options={rules}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Droit"/>}
              value={ruleFilter}
              onChange={(event, value) => saveRuleFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-agencies"
              options={agencies}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Agence / Filiale"/>}
              value={agencyFilter}
              onChange={(event, value) => saveAgencyFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-activities"
              options={activities}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Métier"/>}
              value={activityFilter}
              onChange={(event, value) => saveActivityFilter(value)}
            />
          </div>

          <div style={{ width: '100%', display: 'inline-flex', marginLeft: 20,  marginBottom: 20 }}>
            {user && user?.rules.rules !== "Maîtrise" && (
              <Route
                render={({ history }) => (
                  <Button
                    variant="contained"
                    onClick={() => history.push("/newuser")}
                  >
                    Créer un nouveau compte
                  </Button>
                )}
              />
            )}
          </div>

          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                loading={loading}
                rows={filteredData}
                columns={columns}
                autoHeight={true}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
                disableColumnMenu
                experimentalFeatures={{ newEditingApi: true }}
              />
            </div>
          </div>
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  )
}

export default Documents
