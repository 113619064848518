const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://skeeper.etpogroup.com/api/'

const RedirectToLogin = () => {
  localStorage.removeItem("token");
  window.location.reload();
};

export const logUserAPI = async (email, password) => {
  const response = await fetch(baseUrl + "log/dashboard", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email, password }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const passwordForgottenAPI = async (email) => {
  const response = await fetch(baseUrl + "log/resetpassword/", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  });
  const responseJson = await response.json();
  return responseJson;
};





export const fetchUsersAPI = async () => {
  const response = await fetch(baseUrl + "users/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  console.log(responseJson);
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const getMyInfoAPI = async () => {
  const response = await fetch(baseUrl + "users/me", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const createUserAPI = async (body) => {
  const response = await fetch(baseUrl + "users/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const getSingleUserAPI = async (id) => {
  const response = await fetch(baseUrl + "users/" + id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const toggleUserStateActivityAPI = async (id, activity) => {
  const response = await fetch(baseUrl + "users/toggleuserstateactivity", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id, activity }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const updateUserAPI = async (body) => {
  const response = await fetch(baseUrl + "users/update", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const updatePasswordUserAPI = async (
  oldPassword,
  newPassword,
  verifNewPassword
) => {
  const response = await fetch(baseUrl + "users/changepassword", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ oldPassword, newPassword, verifNewPassword }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const updateForgotPasswordUserAPI = async (
  newPassword,
  verifNewPassword,
  email
) => {
  const response = await fetch(baseUrl + "log/changeforgotpassword", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ newPassword, verifNewPassword, email: email }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const forgotPasswordAPI = async (email) => {
  const response = await fetch(baseUrl + "log/forgotpassword", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const fetchDocumentsAPI = async () => {
  const response = await fetch(baseUrl + "document/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const getDocumentAPI = async (id) => {
  const response = await fetch(baseUrl + "document/" + id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const updateDocumentAPI = async (body) => {
  const response = await fetch(baseUrl + "document/update", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const deleteDocumentAPI = async (id) => {
  const response = await fetch(baseUrl + "document/" + id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const deleteFormAPI = async (cta) => {
  const response = await fetch(baseUrl + "form/", {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ cta }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const sendDocumentAPI = async (body) => {
  const response = await fetch(baseUrl + "document/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const getNotificationAPI = async (id) => {
  const response = await fetch(baseUrl + "notification/" + id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const fetchNotificationAPI = async () => {
  const response = await fetch(baseUrl + "notification/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const updateNotificationAPI = async (body) => {
  const response = await fetch(baseUrl + "notification/update", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const deleteNotificationAPI = async (id) => {
  const url = "http://localhost:3000/notification/";
  const response = await fetch(baseUrl + "notification/", {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id }),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const createNotificationAPI = async (body) => {
  const response = await fetch(baseUrl + "notification", {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const fetchConstructionSiteAPI = async () => {
  const response = await fetch(baseUrl + "constructionSite/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const getOnlyOurConstructionSiteAPI = async () => {
  const response = await fetch(baseUrl + `constructionSite/mind`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
}

export const fetchSingleConstructionSiteAPI = async (id) => {
  const response = await fetch(baseUrl + "constructionSite/" + id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const toggleConstructionSiteActivityAPI = async (id, activity) => {
  const response = await fetch(
    baseUrl + "constructionSite/toggleconstructionsiteactivity/",
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, activity }),
    }
  );
  const responseJson = await response.json();
  return responseJson;
};

export const createConstructionAPI = async (body) => {
  const response = await fetch(baseUrl + "constructionsite/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const updateConstructionSiteAPI = async (body) => {
  const response = await fetch(baseUrl + "constructionsite/update", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const getUserFromRolesAPI = async (roles) => {
  const response = await fetch(baseUrl + "users/role/" + roles, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  return responseJson;
};

export const createAgencyAPI = async (body) => {
  const response = await fetch(baseUrl + "agency/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const updateAgencyAPI = async (body) => {
  const response = await fetch(baseUrl + "agency/update", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  return responseJson;
};

export const getAgenciesAPI = async () => {
  const response = await fetch(baseUrl + "agency/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  if (responseJson.error === "jwt expired") RedirectToLogin();
  return responseJson;
};

export const getCurrentAgenciesAPI = async () => {
  const response = await fetch(baseUrl + "agency/current", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  return responseJson;
};

export const getSingleAgencyAPI = async (idAgency) => {
  const response = await fetch(baseUrl + "agency/" + idAgency, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();
  return responseJson;
};

export const toggleAgencyActivityAPI = async (id, activity) => {
  const response = await fetch(baseUrl + "agency/toggleconstructionsiteactivity/",
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, activity })
    })
  const responseJson = await response.json()
  return responseJson
}

export const getFormAPI = async (id, agency) => {
  const url = baseUrl + "form/formforagency?agency=" + agency + "&id=" + id
  const encoded = encodeURI(url)
  const response = await fetch(encoded,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
  const responseJson = await response.json()
  return responseJson
}

export const getSingleFormAPI = async (id) => {
  const url = baseUrl + "form/singleform?id=" + id
  const encoded = encodeURI(url)
  const response = await fetch(encoded,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
  const responseJson = await response.json()
  return responseJson
}

export const getApplicationDocumentAPI = async (type) => {
  const response = await fetch(baseUrl + `app/document/${type}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })
  return await response.json()
}

export const sendApplicationDocumentAPI = async (type, file) => {
  const formData = new FormData()
  formData.append('file', file)
  const response = await fetch(baseUrl + `app/document/${type}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: formData,
  })
  return await response.json()
}

export const exportDataExcelAPI = async () => {
  const res = await fetch(baseUrl + 'export', {
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  })
  const blob = await res.blob()
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style = 'display: none;';
  link.href = fileURL;
  link.setAttribute("download", `data.xls`);
  document.body.appendChild(link);
  link.click();
}
