import React, { useContext, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DataGrid } from '@mui/x-data-grid'

import Header from '../components/header/header'
import UserContext from '../contexts/UserContext'
import { getConstructionSites } from '../NewAPI'
import { useDidMountEffect } from '../utils/hooks'

const Construction = () => {
  const { user } = useContext(UserContext)

  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)

  const [constructionCodes, setConstructionCodes] = useState([])
  const [constructionNames, setConstructionNames] = useState([])
  const [agencies, setAgencies] = useState([])
  const [activities, setActivities] = useState([])

  const [constructionCodeFilter, setConstructionCodeFilter] = useState(null)
  const [constructionNameFilter, setConstructionNameFilter] = useState(null)
  const [agencyFilter, setAgencyFilter] = useState(null)
  const [activityFilter, setActivityFilter] = useState(null)

  const retrieveSavedFilters = async () => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {}
    const constructionsFilters = filters?.constructions || {}
    setConstructionCodeFilter(constructionsFilters.constructionCode)
    setConstructionNameFilter(constructionsFilters.constructionName)
    setAgencyFilter(constructionsFilters.agency)
    setActivityFilter(constructionsFilters.activity)
  }

  const saveFilter = async (key, value) => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {}
    const constructionsFilters = filters?.constructions || {}
    if (value) constructionsFilters[key] = value
    else delete(constructionsFilters[key])
    filters.constructions = constructionsFilters
    localStorage.setItem('filters', JSON.stringify(filters))
  }

  const saveConstructionCodeFilter = async (value) => {
    setConstructionCodeFilter(value)
    saveFilter('constructionCode', value)
  }

  const saveConstructionNameFilter = async (value) => {
    setConstructionNameFilter(value)
    saveFilter('constructionName', value)
  }

  const saveAgencyFilter = async (value) => {
    setAgencyFilter(value)
    saveFilter('agency', value)
  }

  const saveActivityFilter = async (value) => {
    setActivityFilter(value)
    saveFilter('activity', value)
  }

  const fetchConstructionSites = async () => {
    setLoading(true)
    const response = await getConstructionSites()
    if (response.err !== null) {
      setData([])
    }

    // Add "GROUPE ETPO" agency for global entities
    const fixed = response.map((e) => {
      const data = { ...e, id: e._id }
      if (e.global) return { ...data, agency: ['GROUPE ETPO'] }
      return data
    })

    // Setup filters values
    const constructionCodes = fixed.map((e) => e.constructionCode)
    constructionCodes.sort()
    setConstructionCodes(constructionCodes)
    const constructionNames = fixed.map((e) => e.constructionName)
    constructionNames.sort()
    setConstructionNames(constructionNames)
    let agencies = fixed.map((e) => e.agency)
    agencies = [...new Set(agencies.flat())]
    agencies.sort()
    setAgencies(agencies)
    let activities = fixed.map((e) => e.activity)
    activities = [...new Set(activities.flat())]
    activities.sort()
    setActivities(activities)

    setData(fixed)
    setLoading(false)
  }

  useEffect(() => {
    retrieveSavedFilters().then(() => {
      fetchConstructionSites()
    })
  }, [])

  useDidMountEffect(() => {
    const filtered = data.filter((e) => {
      return (!constructionCodeFilter || e.constructionCode === constructionCodeFilter)
        && (!constructionNameFilter || e.constructionName === constructionNameFilter)
        && (!agencyFilter || e.agency?.includes(agencyFilter))
        && (!activityFilter || e.activity?.includes(activityFilter))
    })
    setFilteredData(filtered)
  }, [data, constructionCodeFilter, constructionNameFilter, agencyFilter, activityFilter])

  const columns = [
    { field: 'constructionCode', headerName: 'Code chantier', flex: 1 },
    { field: 'constructionName', headerName: 'Nom du chantier', flex: 3 },
    {
      field: 'agency',
      headerName: 'Agence(s) / Filiale(s)',
      flex: 1,
      valueGetter: (value) => value.value?.join(', '),
    },
    { field: 'client', headerName: 'Client', flex: 1 },
    { field: 'city', headerName: 'Ville', flex: 1 },
    {
      field: 'date', headerName: 'Date de création', flex: 1, valueGetter: (params) => {
        return new Date(params.value).toLocaleDateString('fr-FR')
      },
    },
    {
      field: ' ',
      headerName: ' ',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ width: '100%', display: 'inline-flex', alignItems: 'end', justifyContent: 'end' }}>
            <Route render={({ history }) => (
              <Button variant="contained" color="info" onClick={(e) => {
                history.push(
                  `/selectedconstructionsite?item=${params.row._id}`,
                )
              }}>Éditer</Button>
            )}/>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <Header/>
      {user?.rules.rules !== 'Utilisateurs application' && user?.rules.rules !== 'Maîtrise' ? (
        <>
          <div className="columns is-centered" style={{ marginTop: 44 }}>
            <div className="column is-12">
              <h1 className="has-text-weight-bold	is-size-1">
                Gestion des chantiers
              </h1>
            </div>
          </div>

          <div style={{ display: 'flex', height: '100%', marginBottom: 40, marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Autocomplete
              disablePortal
              id="filter-agencies"
              options={constructionCodes}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Code chantier"/>}
              value={constructionCodeFilter}
              onChange={(event, value) => saveConstructionCodeFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-agencies"
              options={constructionNames}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Nom du chantier"/>}
              value={constructionNameFilter}
              onChange={(event, value) => saveConstructionNameFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-agencies"
              options={agencies}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Agence / Filiale"/>}
              value={agencyFilter}
              onChange={(event, value) => saveAgencyFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-activities"
              options={activities}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Métier"/>}
              value={activityFilter}
              onChange={(event, value) => saveActivityFilter(value)}
            />
          </div>

          <div style={{ width: '100%', display: 'inline-flex', marginLeft: 20,  marginBottom: 20 }}>
            {user && user?.rules.rules !== "Maîtrise" && (
              <Route
                render={({ history }) => (
                  <Button
                    variant="contained"
                    onClick={() => history.push("/newConstruction")}
                  >
                    Créer un nouveau chantier
                  </Button>
                )}
              />
            )}
          </div>

          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                loading={loading}
                rows={filteredData}
                columns={columns}
                autoHeight={true}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
                disableColumnMenu
                experimentalFeatures={{ newEditingApi: true }}
              />
            </div>
          </div>
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  )
}

export default Construction
