const InputLog = ({holder, type, icon, setItem}) => (
	<div className="columns is-vcentered is-centered">
		<div className="column is-4" type="text" placeholder={holder} style={{textAlign: 'center', borderRadius: 10, borderWidth: 0}}>
			<div className="control has-icons-left">
				<input className="input is-medium" onChange={((value) => setItem(value.target.value))} type={type} placeholder={holder}/>
			<span className="icon is-left">
				<i className={icon}></i>
			</span>
			</div>
		</div>
	</div>
)

export default InputLog