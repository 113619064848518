import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import { updateForgotPasswordUserAPI } from '../API'
import { useHistory } from 'react-router-dom';

const styles = {
  container: {
    backgroundImage: `url('/assets/background.png')`,
    backgroundSize: "cover",
    height: "102vh",
    width: "102	vw",
    padding: 40
  },
  box: {
    backgroundColor: "white",
    justifyContent: "center",
  },
  title: {
    color: "white",
    fontSize: 38,
    marginBottom: 14,
    fontWeight: "600",
  },
  subTitle: {
    color: "white",
    fontSize: 17,
    marginBottom: 24,
    fontStyle: "italic",
  },
};


const ForgotPassword = () => {
  const history = useHistory();
  const { get } = useFetch();
  const [email, setEmail] = useState('');
  const [emailChecked, setEmailChecked] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false)
  const [password, setPassword] = useState('');
  const [alertPassword, setAlertPassword] = useState(false)
  const [alertPasswordFormat, setAlertPasswordFormat] = useState(false)
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [error, setAnError] = useState({message: null, error: false, type: ""})
  const [url, setUrl] = useState(window.location.href.toString().split("=")[1])
  const [user, setUser] = useState({})

  const handlePassword = async (password, passwordConfirmation, email, setAnError) => {
    if (password && passwordConfirmation && password === passwordConfirmation) {
      if ((password === null || password.length < 8) ||  (password.search(/[a-z]/) == -1) || (password.search(/[A-Z]/) == -1) || (password.search(/[0-9]/) == -1)) {
        setAlertPasswordFormat(true)
        setAlertPassword(false)
      }
      else {
        setPasswordChecked(!passwordChecked);
        setAlertPasswordFormat(false)
        setAlertPassword(false)
        const responseJson = await updateForgotPasswordUserAPI(password, passwordConfirmation, email)
        if (responseJson.error != undefined)
          setAnError({
            message: responseJson.error,
            error: true,
            type: "is-danger",
          });
        else {
          setAlertPassword(true);
          //console.log('Le mot de passe a bien été modifié !')
          setAnError({
            message: `Le mot de passe a bien été modifié`,
            error: false,
            type: "is-success",
          });
        }
      }
    }
    else {
      setAlertPasswordFormat(false)
      setAlertPassword(true)
    }
    //if (!handlingError(password, passwordConfirmation, setAnError)) return
    //console.log(responseJson)

    //console.log(responseJson)

    // history.goBack()
  }

  const handleEnterKeyPress = (event) => {
    if (event.charCode === 13 ) {
      event.preventDefault();
      handleClickEmail()
    }
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleClickEmail = async (event) => {
    if (email.toLowerCase() === user.toLowerCase()) {
      setUser(user.toLowerCase())
      setEmail(email.toLowerCase())
      setEmailChecked(!emailChecked);
      //await put("users/forgotpassword/", email);

    }
    else {
      setAlertEmail(true);
    }
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleChangePasswordConfirmation = (event) => {
    setPasswordConfirmation(event.target.value);
  };

  useEffect(() => {
    let userArray = []
    const getUser = async () => {
      const data = await get(`log/user`);
      for (var key in data) {
        if (data.hasOwnProperty(key) && data[key].forgotPasswordToken) {
            if (data[key].forgotPasswordToken === url) {
              userArray.push(data[key].email)
            }
        }
    }
      setUser(userArray[0]);
    };
    if (url && url.length > 0) {
      getUser();
    }
  }, [url]);


  return (
    <div style={styles.container}>
      {!passwordChecked ? (
        !emailChecked ? (
          <div>
            <h1 style={styles.subTitle}>
              Vous venez de commencer un changement de mot de passe. Pour
              continuer, merci de renseigner l'adresse email liée à votre compte
            </h1>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "33ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                style={{
                  backgroundColor: "white",
                  borderRadius: 4,
                  paddingLeft: 10,
                  height: 50,
                  paddingRight: 10,
                  paddingTop: 10,
                }}
                required
                variant="standard"
                value={email}
                placeholder="Email"
                onChange={handleChangeEmail}
                onKeyPress={handleEnterKeyPress}
                id="standard-basic"
              />
              <div style={{ width: "100%" }}>
                <Button
                  style={{ backgroundColor: "#00d1b2", color: "white" }}
                  variant="outlined"
                  onClick={handleClickEmail}
                >
                  Valider
                </Button>
              </div>
            </Box>
            {alertEmail && (
              <h1 style={{ color: "white" }}>
                L'adresse email renseignée ne correspond pas à celle du compte.
              </h1>
            )}
          </div>
        ) : (
          <div>
            <h1 style={styles.subTitle}>
              Veuillez saisir votre nouveau mot de passe
            </h1>
            <h1 style={styles.subTitle}>
              Pour des raisons de sécurité, votre mot de passe doit contenir au
              moins 8 caractères, avec une majuscule, une minuscule et un chiffre.
            </h1>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "40ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div style={{ width: "100%" }}>
                <TextField
                  style={{
                    backgroundColor: "white",
                    borderRadius: 4,
                    paddingLeft: 10,
                    height: 50,
                    paddingRight: 10,
                    paddingTop: 10,
                    width: 300,
                  }}
                  required
                  value={password}
                  type="password"
                  onChange={handleChangePassword}
                  id="outlined-required"
                  placeholder="Nouveau mot de passe"
                />
              </div>
              <div style={{ width: "100%" }}>
                <TextField
                  style={{
                    backgroundColor: "white",
                    borderRadius: 4,
                    paddingLeft: 10,
                    height: 50,
                    paddingRight: 10,
                    paddingTop: 10,
                    width: 300,
                  }}
                  required
                  type="password"
                  value={passwordConfirmation}
                  onChange={handleChangePasswordConfirmation}
                  id="outlined-required"
                  placeholder="Confirmation du mot de passe"
                />
              </div>
            </Box>
            <Button
              style={{
                marginTop: 30,
                backgroundColor: "#00d1b2",
                color: "white",
              }}
              variant="outlined"
              onClick={() =>
                handlePassword(
                  password,
                  passwordConfirmation,
                  email,
                  setAnError
                )
              }
            >
              Valider
            </Button>
            {alertPassword && (
              <h1 style={{ color: "white" }}>
                Les deux mots de passes ne concordent pas.
              </h1>
            )}
            {alertPasswordFormat && (
              <h1 style={{ color: "white" }}>
                Le format du mot de passe n'est pas le bon.
              </h1>
            )}
          </div>
        )
      ) : (
        <div
          style={{
            flex: 1,
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <h1
            style={{
              color: "white",
              fontSize: 17,
              fontStyle: "italic",
              flexDirection: "flex-end",
            }}
          >
            Merci !
          </h1>
          <h1
            style={{
              color: "white",
              fontSize: 17,
              fontStyle: "italic",
              flexDirection: "flex-end",
            }}
          >
            Votre nouveau mot de passe a bien été enregistré
          </h1>
          {typeof window.orientation === "undefined" ? (
            <Button
              style={{
                backgroundColor: "#00d1b2",
                color: "white",
                marginTop: 20,
              }}
              variant="outlined"
              onClick={() => history.push("/")}
            >
              Se connecter
            </Button>
          ) : (
            <h1
              style={{
                color: "white",
                fontSize: 17,
                fontStyle: "italic",
                flexDirection: "flex-end",
              }}
            >
              Vous pouvez désormais vous reconnecter à l'application
            </h1>
          )}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
