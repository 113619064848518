import { useEffect, useRef } from 'react'

// Hook similar to useEffect but that will not trigger on the first render
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}
