import "./App.css";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import { theme } from './theme'

import Login from "./pages/login";
import Notification from "./pages/notification";
import Document from "./pages/document";
import Users from "./pages/users";
import Dashboard from "./pages/dashboard";
import MyProfil from "./pages/myProfil";
import Newnotification from "./pages/newNotification";
import NewDocument from "./pages/newDocument";
import SelectedNotification from "./pages/selectedNotification";
import SelectedDocument from "./pages/selectedDocument";
import SelecteUser from "./pages/selectedUser";
import Construction from "./pages/construction";
import NewUser from "./pages/newUser";
import NewConstruction from "./pages/newConstruction";
import SelectedConstructionSite from "./pages/selectedConstructionSite";
import Agency from "./pages/agency";
import NewAgency from "./pages/newAgency";
import ChangePassword from "./pages/changePassword";
import PasswordForgotten from "./pages/passwordForgotten";
import LegalMention from "./pages/legalMention";
import SelecteAgency from "./pages/selectedAgency";
import Expected from "./pages/expected";
import ForgotPassword from "./pages/forgotPassword";
import PrivateRoute from "./components/PrivateRoute";
import UserContext from "./contexts/UserContext";
import { useState, useEffect } from "react";
import { fetchCurrentUser } from "./utils/fetchCurrentUser";
import jwt from "jsonwebtoken";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorToast from "./components/ErrorToast";
import HistoryForm from "./pages/history";
import ChooseFormToDisplay from "./pages/chooseFormToDisplay";
import SelectedForm from "./pages/selectedForm";
import Application from "./pages/application";

// TODO => Delete console alerts suppresion line
console.warn = () => {};
console.error = () => {};

function App() {
  const [user, setUser] = useState(
    () => JSON.parse(localStorage.getItem("user")) || null
  );
  const connexionToken = localStorage.getItem("token");
  const PASSWORDHASH = process.env.REACT_APP_PASSWORDHASH;

  if (connexionToken) {
    jwt.verify(connexionToken, PASSWORDHASH, function (err, decode) {
      if (err) {
        console.log(err);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        ErrorToast("Session expiré, veuillez vous reconnecter !");
      }
    });
  }

  const autoLogin = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const userData = await fetchCurrentUser();
      setUser(userData);
      localStorage.setItem("user", JSON.stringify(userData));
    } else {
      setUser(null);
      <Redirect to="/login" />;
    }
  };

  useEffect(() => {
    autoLogin();
  }, []);

  const cache = createCache({
    key: 'css',
    prepend: false,
  })

  return (
    <UserContext.Provider value={{ user }}>
      <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <ToastContainer
            theme="dark"
            position="bottom-right"
            autoClose={3500}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Login setUser={setUser} />}
              />
              <Route
                exact
                path="/login"
                render={(props) => <Login setUser={setUser} />}
              />
              <Route
                exact
                path="/passwordForgotten"
                component={PasswordForgotten}
              />
              <Route
                exact
                path="/forgotpassword"
                component={ForgotPassword}
              />
              <Route exact path="/legalmention" component={LegalMention} />
              <Route exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/historyform" component={HistoryForm} />
              <PrivateRoute
                exact
                path="/chooseformtodisplay"
                component={ChooseFormToDisplay}
              />
              <PrivateRoute exact path="/form" component={SelectedForm} />
              <PrivateRoute exact path="/construction" component={Construction} />
              <PrivateRoute exact path="/documents" component={Document} />
              <PrivateRoute
                exact
                path="/notifications"
                component={Notification}
              />
              <PrivateRoute exact path="/users" component={Users} />
              <PrivateRoute exact path="/myprofil" component={MyProfil} />
              <PrivateRoute
                exact
                path="/newnotification"
                component={Newnotification}
              />
              <PrivateRoute exact path="/newdocument" component={NewDocument} />
              <PrivateRoute exact path="/newuser" component={NewUser} />
              <PrivateRoute
                exact
                path="/newconstruction"
                component={NewConstruction}
              />
              <PrivateRoute
                exact
                path="/selectednotification"
                component={SelectedNotification}
              />
              <PrivateRoute
                exact
                path="/selecteddocument"
                component={SelectedDocument}
              />
              <PrivateRoute exact path="/selecteduser" component={SelecteUser} />
              <PrivateRoute
                exact
                path="/selectedagency"
                component={SelecteAgency}
              />
              <PrivateRoute
                exact
                path="/selectedconstructionsite"
                component={SelectedConstructionSite}
              />
              <PrivateRoute exact path="/agency" component={Agency} />
              <PrivateRoute exact path="/expected" component={Expected} />

              <PrivateRoute exact path="/newagency" component={NewAgency} />
              <PrivateRoute
                exact
                path="/changepassword"
                component={ChangePassword}
              />
              <PrivateRoute
                exact
                path="/application"
                component={Application}
              />
            </Switch>
          </BrowserRouter>
        </div>
      </ThemeProvider>
      </CacheProvider>
    </UserContext.Provider>
  );
}

export default App;
