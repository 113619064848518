import React, { useEffect, useState } from 'react'
import Header from '../components/header/header'
import { toggleUserStateActivityAPI, getSingleAgencyAPI, updateAgencyAPI } from '../API'
import InputAdvanced from '../components/InputAdvanced'
import BackButton from '../components/backButton'
import firebase from "firebase/app"
import  "firebase/storage"
import {storage} from '../firebase/index'
import CheckBoxAdvancedForm from '../components/checkBoxAdvancedForm'
import useFetch from '../hooks/useFetch';
import SuccessToast from "../components/SuccessToast";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const toggleAccountActivity = async (id, isActive) =>
{
	await toggleUserStateActivityAPI(id, isActive)
	window.location.reload();
}

const getDate = (item) => {
	const date = new Date(item)
	return date.toLocaleDateString('fr-FR')
}

const getSingleAgency = async (idAgency, setAgency) => {
	const responseJson = await getSingleAgencyAPI(idAgency)
	setAgency(responseJson)
}



const updateData = async (data, formArray, id, setEdit, logoToSend, put) => {

	let documentURL  = null
	if (logoToSend !== undefined && logoToSend !== null){
		documentURL = await sendDocumentToHostingServer(logoToSend)
		data.urlLogo = documentURL
	}
	if (formArray !== undefined && formArray.length >= 0) {
		const response = await put("agency/togglepermitform", {id: id, permitForms: formArray});
		if (!response.error) SuccessToast("Modifications enregistrées !")
	}
	data._id = id
	const responseJson = await updateAgencyAPI(data)
	setEdit(false)
}

const sendDocumentToHostingServer = (file) => {
	return new Promise((resolve, reject) => {
		const uploadTask = storage.ref('agencies/' + file.name).put(file);
		uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
			() => {},
			(error) => resolve({error}),
			() => {
				uploadTask.snapshot.ref.getDownloadURL().then(resolve)
			}
		)
	})
}

const renderNewImage = (file, setLogoTmp, setLogoToSend) => {
	setLogoToSend(file)
	const img = URL.createObjectURL(file)
	setLogoTmp(img)
}

const SelecteAgency = (props) => {
	const { get, put } = useFetch();
	const [item, setItem] =  useState(null)
	const [newItem, setNewItem] =  useState({})
	const [newArray, setNewArray] =  useState(item ? item.permitForms : []);
	const [formTypes, setFormTypes] =  useState([]);
	const [logoToSend, setLogoToSend] = useState(null)
	const [logoTmp, setLogoTmp] = useState(null)
	const [edit, setEdit] =  useState(false)
	const params = new URLSearchParams(props.location.search)
	const idAgency = params.get('item')
	const { user } = useContext(UserContext);

	const getAllFormTypes = async () => {
		const response = await get("formtype");
		return response;
	};

	useEffect(() => {
		getSingleAgency(idAgency, setItem)
		getAllFormTypes().then(response => {
			setFormTypes(response)
		});
	  }, []);

	if (item === null) return <div>Chargement ...</div>

	return (
    <div>
      <Header />
      {user?.rules.rules != "Maîtrise" &&
      user?.rules.rules != "Utilisateurs application" &&
      user?.rules.rules != "Encadrement" ? (
        <>
          <BackButton />
          {item === null ? (
            <p>Chargement ...</p>
          ) : (
            <div className="columns" style={{ marginTop: 10 }}>
              <div className="column is-4" style={{ marginTop: "5%", paddingBottom: "8%" }}>
                <InputAdvanced
                  labelTitle="Nom de la filiale"
                  value={item.name}
                  onChange={(event) =>
                    setNewItem({ ...newItem, name: event.target.value })
                  }
                  edit={edit}
                />
                <InputAdvanced labelTitle="Activité" value={item.activity} />
                <CheckBoxAdvancedForm
                  valueDropdown={formTypes}
                  defaultValue={{ permitForms: item.permitForms }}
                  edit={!edit}
                  setState={setNewArray}
                  object={newArray}
                />
              </div>
              <div className="column is-6 is-offset-4">
                <div className="columns">
                  <div className="column is-2 ">
                    {!edit ? (
                      <div className="column is-12">
                        <button
                          className="button is-info"
                          onClick={() => setEdit(true)}
                        >
                          <span>Editer</span>
                          <span className="icon is-small">
                            <i className="fas fa-pen"></i>
                          </span>
                        </button>
                      </div>
                    ) : (
                      <div>
                        <div className="column is-12">
                          <button
                            className="button is-danger"
                            onClick={() => setEdit(false)}
                          >
                            <span>Annuler</span>
                            <span className="icon is-small">
                              <i className="fas fa-times"></i>
                            </span>
                          </button>
                        </div>
                        <div className="column is-12">
                          <button
                            className="button is-success"
                            onClick={() =>
                              updateData(
                                newItem,
                                newArray,
                                item._id,
                                setEdit,
                                logoToSend,
                                put
                              )
                            }
                          >
                            <span>Sauvegarder</span>
                            <span className="icon is-small">
                              <i className="fas fa-check"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="column is-2">
                    <div className="column is-12">
                      {item.isActive ? (
                        <button
                          className="button is-danger"
                          onClick={() => toggleAccountActivity(item._id, false)}
                        >
                          {" "}
                          Désactiver le compte
                        </button>
                      ) : (
                        <button
                          className="button is-success"
                          onClick={() => toggleAccountActivity(item._id, true)}
                        >
                          {" "}
                          Activer le compte
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column is-6 is-offset-0">
                  <p>
                    <strong>Date création compte: </strong>
                    {getDate(item.date)}
                  </p>
                </div>
                <div className="column is-6 is-offset-0">
                  <div
                    style={{
                      borderRadius: 5,
                      borderColor: "black",
                      overflow: "scroll",
                      borderWidth: 1,
                      borderStyle: "solid",
                      maxWidth: 400,
                      maxHeight: 400,
                    }}
                  >
                    {newItem.logo === undefined &&
                    (item.urlLogo === undefined || item.urlLogo === null) ? (
                      <p style={{ position: "absolute" }}>Pas d'image</p>
                    ) : null}

                    {item.urlLogo !== undefined && item.urlLogo !== null ? (
                      <img width="100%" height="100%" src={item.urlLogo} />
                    ) : (
                      <img width="100%" height="100%" src={logoTmp} />
                    )}

                    <img />
                  </div>
                  {edit ? (
                    <input
                      type="file"
                      id="avatar"
                      name="avatar"
                      accept="image/png, image/jpeg"
                      onChange={(file) =>
                        renderNewImage(
                          file.target.files[0],
                          setLogoTmp,
                          setLogoToSend
                        )
                      }
                    />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  );
}

const styles = {
	imgDocument: {
		height: 200,
		width: 200,
		borderRadius: 5,
	}
}

export default SelecteAgency
