import { useContext, useEffect, useState } from 'react'

import Header from '../components/header/header'
import UserContext from '../contexts/UserContext'
import ErrorToast from '../components/ErrorToast'
import SuccessToast from '../components/SuccessToast'

import { exportDataExcelAPI, getApplicationDocumentAPI, sendApplicationDocumentAPI } from '../API'

const Application = () => {
  const { user } = useContext(UserContext)

  const [howToDocument, setHowToDocument] = useState({ name: null })
  const [howToDocumentUrl, setHowToDocumentUrl] = useState(null)
  const [faqDocument, setFaqDocument] = useState({ name: null })
  const [faqDocumentUrl, setFaqDocumentUrl] = useState(null)

  const getHowTo = () => getApplicationDocumentAPI('howto').then((data) => {
    if (data?.value) setHowToDocumentUrl(data.value)
  })

  const uploadHowTo = () => {
    if (howToDocument.name) {
      sendApplicationDocumentAPI('howto', howToDocument)
        .then(_ => {
          SuccessToast('Le document "Comment ça marche ?" a bien été modifié.')
          getHowTo()
        })
        .catch(_ => {
          ErrorToast('Une erreur est survenue.')
        })
    }
  }

  const getFaq = () => getApplicationDocumentAPI('faq').then((data) => {
    if (data?.value) setFaqDocumentUrl(data.value)
  })

  const uploadFaq = () => {
    if (faqDocument.name) {
      sendApplicationDocumentAPI('faq', faqDocument)
        .then(_ => {
          SuccessToast('Le document "FAQ" a bien été modifié.')
          getFaq()
        })
        .catch(_ => {
          ErrorToast('Une erreur est survenue.')
        })
    }
  }

  const exportData = async () => {
    try {
      await exportDataExcelAPI()
    } catch (err) {
      ErrorToast("Une erreur est survenue")
    }
  }

  useEffect(() => {
    getHowTo()
    getFaq()
  })

  if (!user?.rules.rules === 'Direction groupe + RQSE') {
    return (
      <div>
        <Header />
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      </div>
    )
  }

  return (
    <div>
      <Header />
      <div>
        <div className="columns is-centered" style={{ marginTop: 44 }}>
          <div className="column is-12">
            <h1 className="has-text-weight-bold	is-size-1">
              Gestion de l'application
            </h1>
          </div>
        </div>

        <div className="columns" style={{ marginTop: 40 }}>
          <div className="column is-6">
            <h2 className="has-text-weight-bold	is-size-4" style={{ marginBottom: 40, marginTop: 20 }}>
              Document "Comment ça marche ?"
            </h2>
            <div className="columns is-centered" style={{ flex: 1, alignItems: 'center', justifyItems: 'center' }}>
              <p className="has-text-right" style={{ marginRight: 20 }}>Choisir un fichier</p>
              <div className="file has-name">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume"
                    onChange={(file) => setHowToDocument(file.target.files[0])} />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">Choisir un fichier</span>
                  </span>
                  <span className="file-name">
                    {howToDocument.name || 'Aucun fichier séléctionné'}
                  </span>
                </label>
              </div>
            </div>
            <button className="button is-success" onClick={uploadHowTo}>Modifier le document</button>
            <div>
              {howToDocumentUrl && (
                <a
                  href={howToDocumentUrl}
                  target="_blank"
                  style={styles.pdfLink}
                    className="button is-white"
                >
                  Ouvrir le PDF
                </a>
              )}
            </div>
          </div>
          <div className="column is-6">
            <h2 className="has-text-weight-bold	is-size-4" style={{ marginBottom: 40, marginTop: 20 }}>
              Document "FAQ"
            </h2>
            <div className="columns is-centered" style={{ flex: 1, alignItems: 'center', justifyItems: 'center' }}>
              <p className="has-text-right" style={{ marginRight: 20 }}>Choisir un fichier</p>
              <div className="file has-name">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume"
                    onChange={(file) => setFaqDocument(file.target.files[0])} />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">Choisir un fichier</span>
                  </span>
                  <span className="file-name">
                    {faqDocument.name || 'Aucun fichier séléctionné'}
                  </span>
                </label>
              </div>
            </div>
            <button className="button is-success" onClick={uploadFaq}>Modifier le document</button>
            <div>
              {faqDocumentUrl && (
                <a
                  href={faqDocumentUrl}
                  target="_blank"
                  style={styles.pdfLink}
                    className="button is-white"
                >
                  Ouvrir le PDF
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="columns" style={{ marginTop: 10 }}>
          <div className="column is-6">
            <h2 className="has-text-weight-bold	is-size-4" style={{ marginBottom: 10, marginTop: 20 }}>
              Export des données
            </h2>
            <button className="button is-success" onClick={exportData}>Exporter les données.</button>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  pdfLink: {
    height: 200,
    width: 200,
    borderRadius: 10,
    flex: 1,
    alignItems: 'center',
    justifyItems: 'center',
    marginTop: 20,
  },
}

export default Application
