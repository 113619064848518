import React from "react";
import { Select } from "antd";

const { Option } = Select;

const getDefaultValue = (defaultValue) => {
  if (!defaultValue) return null;
  const tmp = [];
  defaultValue.forEach((elem) => {
    tmp.push({ value: elem.id, label: elem.fullName });
  });
  return tmp;
};

const InputAdvancedRoles = ({
  label,
  defaultValue,
  values,
  onChange,
  enabled,
}) => {
  return (
    <div class="columns">
      <div class="column" style={{ alignSelf: "center" }}>
        <p>{label}:</p>
      </div>
      <div class="column">
        <Select
          mode="multiple"
          disabled={!enabled}
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          labelInValue={true}
          defaultValue={getDefaultValue(defaultValue)}
					onChange={(value) => {
            onChange(value.map((e) => {
              return { id: e.value, fullName: e.label }
            }))
          }}
        >
          {values.map((elem, index) => {
            const text = `${elem.firstName} ${elem.lastName}`;
            return (
              <Option
                label={`${elem.firstName}${elem.lastName}`}
                value={elem._id}
                key={index}
              >
                {text}
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default InputAdvancedRoles;
