import React, { useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { passwordForgottenAPI } from '../API.js'
import InputLog from '../components/inputLog.js'


const passwordForgotten = async (email, setModalInfo) => {
	const responseJson = await passwordForgottenAPI(email)
	if (!!responseJson.err) {
		setModalInfo({title: "Une erreur est survenue", desc: "L'email fournis n'a pas été trouvé", isActive: true})
	}
	else {
		setModalInfo({title: "Un mail vous a été envoyé", desc: "Un mail contenant votre nouveau mot de passe à usage unique vous a été envoyé", isActive: true})
	}
}


const ModalInfo = ({info, setModalInfo}) => (
	<div className={info.isActive === true ? "modal is-active" : "modal"}>
		<div class="modal-background"></div>
		<div class="modal-card">
			<header class="modal-card-head">
				<p class="modal-card-title">{info.title}</p>
				<button class="delete" aria-label="close"></button>
			</header>
			<section class="modal-card-body">
				<p>{info.desc}</p>
			</section>
			<footer class="modal-card-foot">
				<button class="button is-success" onClick={() => setModalInfo({title: null, desc: null, isActive: false})}>Ok</button>
			</footer>
		</div>
	</div>
)

const LegalMention = (props) => {
	return (
		<div style={styles.container}>

			<div className="" style={{ paddingTop: '1%'}}>
				<div className="" style={{marginLeft: "15%", marginRight: "15%"}}>
					<div style={{backgroundColor: "#d8d8d8", borderRadius: "5%", padding: "5%"}}>
						<h1 style={{fontSize: 42}}>Mentions légales</h1>
						<h2 style={{fontStyle: "italic", fontSize: 24}}>En application de la loi 2000-719 du 1er août 2000 relative à la liberté de communication, modifiant la loi 86-1067 du 30 septembre 1986 relative à la liberté de communication, vous trouverez ci-dessous les informations légales concernant ce site :</h2>
						<h2 style={styles.sectionTitle}>Gestion éditoriale</h2>
						<p style={styles.basicText}>La gestion éditoriale de l’application ainsi que la direction de la publication sont assurées par :</p>
						<p style={styles.basicText}>Identification : Groupe ETPO dénommée ci-après "La Société"</p>
						<p style={styles.basicText}>Siège social :</p>
						<p style={styles.basicText}>2, Impasse Charles Trenet,Immeuble ARMEN,BP 60338, 44803 SAINT-HERBLAIN Cedex +33 2 40 44 27 00</p>
						<p style={styles.basicText}>RCS : 32011691600281</p>
						<p style={styles.basicText}>Capital : 7000 000,00 €</p>
						<p style={styles.basicText}>Code APE : Construction d'autres bâtiments (4120B)</p>
						<p style={styles.basicText}>Directeur de la publication :</p>
						<p style={styles.basicText}>Olivier TARDY, PDG CIFE-Groupe ETPO</p>
						<h2 style={styles.sectionTitle}>Protection des données personnelles</h2>
						<p style={styles.basicText}>Conformément à la loi Informatique et Libertés du 6 janvier 1978 (art. 34), vous disposez d'un droit d'accès, de rectification ou de suppression concernant les données personnelles que nous pourrions être amenés à recueillir (données renseignées par vous).</p>
						<h2 style={styles.sectionTitle}>Droit d'auteur</h2>
						<p style={styles.basicText}>L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques. La reproduction de tout ou partie de ce site sur quelque support que ce soit est formellement interdite sauf notre autorisation expresse. Malgré tous les soins apportés à la réalisation de ce site et à son actualisation régulière, des erreurs peuvent s'être glissées dans les informations et/ou documents présentés. Si vous en constatez n'hésitez pas à nous le faire savoir en nous contactant, nous procéderons aux rectifications correspondantes.</p>
						<h2 style={styles.sectionTitle}>Exactitude de l'information</h2>
						<p style={styles.basicText}>Nous mettons tous les moyens disponibles en œuvre afin d'offrir aux internautes un contenu de qualité sur son site. Cependant, nous ne garantissons pas la complétude, l'exhaustivité et l'exactitude du contenu des informations et des services proposés sur notre site.</p>
						<h2 style={styles.sectionTitle}>Responsabilité de La Société</h2>
						<p style={styles.basicText}>Dans les conditions autorisées par la loi, La Société n'encourt aucune responsabilité: pour toute imprécision, inexactitude, omission ou pour tous dommages résultant d'une intrusion d'un tiers ayant entraîné une modification des informations mises à disposition sur le Site ou rendant impossible son accès. Le visiteur est informé que l'accès au Site pourra être interrompu à tout moment par la Société ... pour des raisons de maintenance, de sécurité ou toute autre contrainte</p>
						<h2 style={styles.sectionTitle}> Droit applicable</h2>
						<p style={styles.basicText}>Les présentes conditions sont régies par le droit français. Les juridictions françaises sont seules compétentes pour connaître de tout litige se rapportant directement ou indirectement à l'accès au présent Site ou à son utilisation.</p>
						<p style={styles.basicText}>Création et hébergement du site Internet</p>
						<p style={styles.basicText}>Toutes les coordonnées concernant la création du site Internet, son hébergement et son évolution sont disponibles via les liens suivants :</p>
						<p style={styles.basicText}>Création: Socialy https://www.socialy.fr/</p>
						<p style={styles.basicText}>Hébergement: DigitalOcean</p>
						<p style={styles.basicText}>Développement: Badr Choukri</p>
						<p style={styles.basicText}>Maintenance: Socialy https://www.socialy.fr/</p>
					</div>
				</div>
			</div>
		</div>
	)
}


const styles = {
	container: {
		// backgroundImage: `url('/assets/background.png')`,
        backgroundSize: "cover",

		height: '102vh',
    	width: '102	vw',
	},
	box: {
		backgroundColor: 'green',
		justifyContent: 'center'
	},
	sectionTitle: {
		fontSize: 34,
	},
	basicText: {
		textAlign: "left",
		fontSize: 22,
	}
}

export default LegalMention