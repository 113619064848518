import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import { Route } from "react-router-dom";
import { getCurrentAgenciesAPI, getMyInfoAPI } from "../API";
import InputAdvanced from "../components/InputAdvanced";
import InputAdvancedActivity from "../components/inputAdvancedActivity";
import CheckBoxAdvancedActivity from "../components/checkBoxAdvancedActivity";
import ErrorToast from "../components/ErrorToast";

const getMyInfo = async (setItem) => {
  const responseJson = await getMyInfoAPI();
  setItem(responseJson);
};

const updateData = async (data, id, setEdit) => {
  data.id = id;
  // const responseJson = await updateUserAPI(data)
  setEdit(false);
};

const getAgency = async (setAgency) => {
  const responseJson = await getCurrentAgenciesAPI();
  responseJson.sort((a, b) => a.name > b.name ? 1 : -1);
  setAgency(responseJson);
};

const logOut = (history) => {
  localStorage.removeItem("token");
  history.push("/login");
  ErrorToast("Déconnecté !");
};

const mergeArray = (arr1, arr2) => {
  const result = arr1.reduce((acc, current, index) => {
    return [...acc, { activity: current, agency: arr2[index] }];
  }, []);
  return result;
};

const MyProfil = () => {
  const [item, setItem] = useState(null);
  const [newItem, setNewItem] = useState({});
  const [edit, setEdit] = useState(false);
  const [agency, setAgency] = useState(null);

  useEffect(() => {
    getMyInfo(setItem);
    getAgency(setAgency);
  }, []);
  if (item === null || agency === null)
    return (
      <div>
        <p>Chargement du profil ...</p>
      </div>
    );
  //   test(item.activity, item.agency)
  return (
    <div>
      <Header />
      <div className="columns is-centered" style={{ marginTop: 44 }}>
        <div className="column is-12">
          <h1 className="has-text-weight-bold	is-size-1">Mon compte</h1>
        </div>
      </div>
      <div className="columns ">
        <div className="column is-4 is-offset-0">
          <InputAdvanced
            labelTitle="Nom"
            value={item.lastName}
            onChange={(event) =>
              setNewItem({ ...newItem, lastName: event.target.value })
            }
          />
          <InputAdvanced
            labelTitle="Prénom"
            value={item.firstName}
            onChange={(event) =>
              setNewItem({ ...newItem, firstName: event.target.value })
            }
          />
          <InputAdvanced
            labelTitle="Email"
            value={item.email}
            onChange={(event) =>
              setNewItem({ ...newItem, email: event.target.value })
            }
          />
          <CheckBoxAdvancedActivity
            valueDropdown={agency}
            defaultValue={{ agency: item.agency, activity: item.activity }}
            edit={!edit}
            setState={setNewItem}
            object={item}
          />
        </div>
        <div className="column is-3 is-offset-4">
          <div className="columns">
            <div className="column is-12">
              <div className="column is-6">
                <Route
                  render={({ history }) => (
                    <button
                      className="button is-info"
                      onClick={() => history.push("/changepassword")}
                    >
                      Modifier mon mot de passe
                    </button>
                  )}
                />
              </div>
              <div className="column is-6">
                <Route
                  render={({ history }) => (
                    <button
                      className="button is-danger"
                      onClick={() => logOut(history)}
                    >
                      Se deconnecter
                    </button>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfil;
