import React from 'react'
import ReactExport from 'react-data-export'

import { Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportDashboardDataButton = ({
  year,
  indicators,
  monthlyCompletion,
  agency,
  activity,
  constructionCode,
  user,
}) => {
  const exported = []

  // Push indicators data
  for (let i = 0; i < (indicators.length); i++) {
    const indicator = indicators[i]
    exported.push({
      indicator: `${indicator.group} - ${indicator.name}`,
      count1: indicator.data[0].expected,
      count2: indicator.data[0].executed,
      count3: indicator.data[1].expected,
      count4: indicator.data[1].executed,
      count5: indicator.data[2].expected,
      count6: indicator.data[2].executed,
      count7: indicator.data[3].expected,
      count8: indicator.data[3].executed,
      count9: indicator.data[4].expected,
      count10: indicator.data[4].executed,
      count11: indicator.data[5].expected,
      count12: indicator.data[5].executed,
      count13: indicator.data[6].expected,
      count14: indicator.data[6].executed,
      count15: indicator.data[7].expected,
      count16: indicator.data[7].executed,
      count17: indicator.data[8].expected,
      count18: indicator.data[8].executed,
      count19: indicator.data[9].expected,
      count20: indicator.data[9].executed,
      count21: indicator.data[10].expected,
      count22: indicator.data[10].executed,
      count23: indicator.data[11].expected,
      count24: indicator.data[11].executed,
      count25: indicator.completion,
    })
  }

  // Push month completion data
  exported.push({
    indicator: '% réalisation',
    count2: monthlyCompletion[0],
    count4: monthlyCompletion[1],
    count6: monthlyCompletion[2],
    count8: monthlyCompletion[3],
    count10: monthlyCompletion[4],
    count12: monthlyCompletion[5],
    count14: monthlyCompletion[6],
    count16: monthlyCompletion[7],
    count18: monthlyCompletion[8],
    count20: monthlyCompletion[9],
    count22: monthlyCompletion[10],
    count24: monthlyCompletion[11],
  })

  let filename = `Attendu & Réalisé - ${year}`
  if (agency) filename += ` - ${agency}`
  if (activity) filename += ` - ${activity}`
  if (constructionCode) filename += ` - ${constructionCode}`
  if (user) filename += ` - ${user}`
  return (
    <ExcelFile
      element={
        <Button
          variant="contained"
        >
          <DownloadIcon style={{ marginRight: 8 }}/>
          Exporter
        </Button>
      }
      filename={filename}
    >
      <ExcelSheet
        data={exported}
        name="Attendu - Réalisé"
      >
        <ExcelColumn label="Indicateurs" value="indicator"/>
        <ExcelColumn label="Attendu Janvier" value="count1"/>
        <ExcelColumn label="Réalisé Janvier" value="count2"/>
        <ExcelColumn label="Attendu Février" value="count3"/>
        <ExcelColumn label="Réalisé Février" value="count4"/>
        <ExcelColumn label="Attendu Mars" value="count5"/>
        <ExcelColumn label="Réalisé Mars" value="count6"/>
        <ExcelColumn label="Attendu Avril" value="count7"/>
        <ExcelColumn label="Réalisé Avril" value="count8"/>
        <ExcelColumn label="Attendu Mai" value="count9"/>
        <ExcelColumn label="Réalisé Mai" value="count10"/>
        <ExcelColumn label="Attendu Juin" value="count11"/>
        <ExcelColumn label="Réalisé Juin" value="count12"/>
        <ExcelColumn label="Attendu Juillet" value="count13"/>
        <ExcelColumn label="Réalisé Juillet" value="count14"/>
        <ExcelColumn label="Attendu Août" value="count15"/>
        <ExcelColumn label="Réalisé Août" value="count16"/>
        <ExcelColumn label="Attendu Septembre" value="count17"/>
        <ExcelColumn label="Réalisé Septembre" value="count18"/>
        <ExcelColumn label="Attendu Octobre" value="count19"/>
        <ExcelColumn label="Réalisé Octobre" value="count20"/>
        <ExcelColumn label="Attendu Novembre" value="count21"/>
        <ExcelColumn label="Réalisé Novembre" value="count22"/>
        <ExcelColumn label="Attendu Décembre" value="count23"/>
        <ExcelColumn label="Réalisé Décembre" value="count24"/>
        <ExcelColumn label="% réalisation" value="count25"/>
      </ExcelSheet>
    </ExcelFile>
  )
}

export default ExportDashboardDataButton
