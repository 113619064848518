import React from 'react'
import { Route } from 'react-router-dom'

const BackButton = () => (
	<Route render={({ history}) => (
		<div style={{position: "absolute", top: "10%", left: "5%"}}>
			<button className="button is-white" onClick={() => history.goBack()}>
				<span className="icon" style={{fontSize: '2em'}}>   
				<i className="fas fa-xs fa-chevron-left"></i>
				</span><span style={{fontFamily: "Exo 2 important"}}>Retour</span>
			</button>
		</div>						
	)}/>
)

export default BackButton