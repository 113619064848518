import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/storage";
import { storage } from "../firebase/index";
import { createNotificationAPI } from "../API";
import BackButton from "../components/backButton";
import Select from "react-select";
import UserContext from "../contexts/UserContext";
import SuccessToast from "../components/SuccessToast";
import { getAuthorizedAgencies } from '../NewAPI'
import { Checkbox } from '@mui/material'

const sendDocumentToHostingServer = (file) => {
  return new Promise((resolve, reject) => {
    const uploadTask = storage.ref("documents/" + file.name).put(file);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => {},
      (error) => resolve({ error }),
      () => uploadTask.snapshot.ref.getDownloadURL().then(resolve)
    );
  });
};

const handleCreationNotification = async (
  history,
  body,
  setBody,
  documentToSend
) => {
  let documentURL = null;
  if (documentToSend.name !== null)
    documentURL = await sendDocumentToHostingServer(documentToSend);
  if (documentURL !== null && !!documentURL.error)
    console.log(documentURL.error);
  else body.documentURL = documentURL;
  createNotification(body);
  SuccessToast("Notification créée");
  history.goBack();
};

const createNotification = async (body) => {
  const responseJson = await createNotificationAPI(body);
  if (responseJson.Error != null) return;
};

const Newnotification = () => {
  const { user } = useContext(UserContext);
  const [body, setBody] = useState({
    title: null,
    content: null,
    type: "",
    documentURL: null,
    autor: `${user.firstName} ${user.lastName}`,
    agency: [],
    global: false,
  });
  const [documentToSend, setDocumentToSend] = useState({ name: null });
  const [agencies, setAgencies] = useState([{ activity: null, agency: null }]);
  const [selectedType, setSelectedType] = useState("");

  const handleChangeType = (value) => {
    setBody({ ...body, type: value.value });
    setSelectedType(value.value);
  };

  const getAgencies = async () => {
    const data = await getAuthorizedAgencies(user)
    setAgencies(data)
  };

  useEffect(() => {
    getAgencies();
  }, []);

  return (
    <div>
      <BackButton />
      {user && (user.rules.rules === 'Maîtrise' || user.rules.rules === 'Encadrement' || user.rules.rules === 'Utilisateurs application') ? <h1>Vous n'avez pas les droits pour créer un nouveau document</h1> : (<>
      <div className="columns is-centered">
        <div className="column is-12">
          <h1 className="has-text-weight-bold	is-size-1">
            Nouvelle notification
          </h1>
          <h2 className="has-text-weight-semi-bold is-size-5">
            Complétez les différents champs afin de créer une nouvelle
            notification
          </h2>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-3">
          <p className="has-text-right">Titre notification</p>
        </div>
        <div className="column is-3">
          <input
            className="input"
            onChange={(event) =>
              setBody({ ...body, title: event.target.value })
            }
            type="text"
            placeholder="Titre notification"
          ></input>
        </div>
      </div>

      {user?.rules.rules === 'Direction groupe + RQSE' && (
        <div className="columns is-centered">
          <div className="column is-3" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            <p className="has-text-right">Groupe ETPO</p>
          </div>

          <div className="column is-3">
            <div className="is-rounded has-text-left">
              <Checkbox
                onChange={(event) => setBody({...body, global: event.target.checked, agency: []})}
              />
            </div>
          </div>
        </div>
      )}

      {!body.global && (
        <div className="columns is-centered">
          <div className="column is-3">
            <p className="has-text-right">Agence(s)/Filiale(s)</p>
          </div>
          <div className="column is-3">
            <div className=" is-rounded">
              <Select
                onChange={(event) => {
                  setBody({
                    ...body,
                    agency: event.map((value) => value.agency),
                    activity: event.map((value) => value.activity),
                  });
                }}
                isMulti
                defaultValue={[]}
                name="colors"
                options={agencies.map((value) => {
                  return {
                    value,
                    label: value.name,
                    agency: value.name,
                    activity: value.activity,
                  };
                })}
                className="basic-multi-select"
                classNamePrefix="Oui"
              />
            </div>
          </div>
        </div>
      )}

      <div className="columns is-centered">
        <div className="column is-3">
          <p className="has-text-right">Type</p>
        </div>
        <div className="column is-3">
          <div className=" is-rounded">
            <Select
              onChange={(value) => handleChangeType(value)}
              name="colors"
              options={["Document", "Article"].map((value) => {
                return { value: value, label: value };
              })}
              className="basic-multi-select"
              classNamePrefix="Oui"
            />
          </div>
        </div>
      </div>

      {selectedType && selectedType === "Article" && (
        <div className="columns is-centered">
          <div className="column is-3">
            <p className="has-text-right">Contenu de la notification</p>
          </div>
          <div className="column is-3">
            <textarea
              onChange={(event) =>
                setBody({ ...body, content: event.target.value })
              }
              className="textarea has-fixed-size"
              placeholder="Contenu de la notification"
            ></textarea>
          </div>
        </div>
      )}

      {selectedType && selectedType.length > 0 && (
        <div className="columns is-centered">
          <div className="column is-3">
            <p className="has-text-right">Choisir un fichier</p>
          </div>
          <div className="column is-3">
            <div className="file has-name">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name="resume"
                  onChange={(file) => setDocumentToSend(file.target.files[0])}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">Choisir un fichier</span>
                </span>
                <span className="file-name">
                  {documentToSend.name || "Aucun fichier séléctionné"}
                </span>
              </label>
            </div>
          </div>
        </div>
      )}

      <div className="columns is-centered">
        <div className="column is-3">
          <Route
            render={({ history }) => (
              <button
                className="button is-success"
                onClick={() =>
                  handleCreationNotification(
                    history,
                    body,
                    setBody,
                    documentToSend
                  )
                }
              >
                Envoyez notification
              </button>
            )}
          />
        </div>
      </div></>)}
    </div>
  );
};

export default Newnotification;
