import React, { useEffect, useState } from "react";
import {
  deleteNotificationAPI,
  getNotificationAPI,
  updateNotificationAPI,
} from "../API";
import Header from "../components/header/header";
import { Route } from "react-router-dom";
import AlertModal from "../components/alertModal";
import BackButton from "../components/backButton";
import InputAdvanced from "../components/InputAdvanced";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const getNotification = async (id, setItem) => {
  const responseJson = await getNotificationAPI(id);
  if (responseJson.Error != null) return;
  setItem(responseJson);
};

const removeItem = async (item, history) => {
  await deleteNotificationAPI(item._id);
  history.goBack();
};

const updateData = async (data, id, setEdit) => {
  data.id = id;
  const responseJson = await updateNotificationAPI(data);
  setEdit(false);
};

const SelectedNotification = (props) => {
  const [item, setItem] = useState(null);
  const [newItem, setNewItem] = useState({});
  const params = new URLSearchParams(props.location.search);
  const idNotification = params.get("item");
  const [modalActivity, setModalActivity] = useState(false);
  const [edit, setEdit] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getNotification(idNotification, setItem);
  }, []);

  return (
    <div>
      <Header />
      {user?.rules.rules != "Utilisateurs application" &&
      user?.rules.rules != "Maîtrise" ? (
        <>
          <BackButton />
          {item === null ? (
            <p>Chargement ...</p>
          ) : (
            <div className="columns" style={{ marginTop: "5%" }}>
              <div className="column is-4 is-offset-1">
                <InputAdvanced
                  labelTitle="Titre du document"
                  value={item.title}
                  onChange={(event) =>
                    setNewItem({ ...newItem, title: event.target.value })
                  }
                  edit={edit}
                />
                {item && item.type !== "Document" && (
                  <InputAdvanced
                    labelTitle="Contenu du document"
                    value={item.content}
                    onChange={(event) =>
                      setNewItem({ ...newItem, content: event.target.value })
                    }
                    edit={edit}
                  />
                )}
                <InputAdvanced
                  labelTitle="Type du document"
                  value={item.type}
                />
                {item.category ? (
                  <div style={{ marginBottom: 20 }}>
                    <InputAdvanced
                      labelTitle="Catégorie"
                      value={item.category}
                    />
                    <InputAdvanced
                      labelTitle="Sous-Catégorie"
                      value={item.subCategory}
                    />
                  </div>
                ) : null}
                {item && item.type === "Document" && item.documentURL && (
                  <iframe
                    src={item.documentURL}
                    width={"300px"}
                    height={"300px"}
                    style={{ borderRadius: "10px", overflow: "hidden" }}
                    scrolling="no"
                  />
                )}
                {item && item.type === "Article" && item.documentURL && (
                  <img
                    src={item.documentURL}
                    style={{
                      height: 300,
                      width: 300,
                      borderRadius: 10,
                      overflow: "hidden",
                    }}
                  />
                )}
              </div>
              <Route
                render={({ history }) => (
                  <AlertModal
                    labelModal="Êtes-vous sur de vouloir supprimer cette notification ?"
                    setModalActivity={setModalActivity}
                    modalActivity={modalActivity}
                    firstAction={() => removeItem(item, history)}
                  />
                )}
              />
              <div className="column is-6">
                <div className="columns">
                  <div className="column is-2 is-offset-5">
                    {!edit ? (
                      <div className="column is-12">
                        <button
                          class="button is-info"
                          onClick={() => setEdit(true)}
                        >
                          <span>Editer</span>
                          <span class="icon is-small">
                            <i class="fas fa-pen"></i>
                          </span>
                        </button>
                      </div>
                    ) : (
                      <div>
                        <div className="column is-12">
                          <button
                            class="button is-danger"
                            onClick={() => setEdit(false)}
                          >
                            <span>Annuler</span>
                            <span class="icon is-small">
                              <i class="fas fa-times"></i>
                            </span>
                          </button>
                        </div>
                        <div className="column is-12">
                          <button
                            class="button is-success"
                            onClick={() =>
                              updateData(newItem, item._id, setEdit)
                            }
                          >
                            <span>Sauvegarder</span>
                            <span class="icon is-small">
                              <i class="fas fa-check"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="column is-2">
                    <div className="column is-12">
                      <button
                        onClick={() => setModalActivity(true)}
                        className="button is-danger"
                      >
                        {" "}
                        Supprimer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  );
};

const styles = {
  imgDocument: {
    height: 200,
    width: 200,
    borderRadius: 5,
  },
};

export default SelectedNotification;
