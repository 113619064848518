import React, { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import DownloadIcon from '@mui/icons-material/Download'
import { getFormDataExport } from '../../NewAPI'

const ExportFormDataButton = ({
  beginDate,
  endDate,
  agency,
  constructionCode,
  user,
  formName,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <LoadingButton
      variant="contained"
      loading={loading}
      onClick={async () => {
        setLoading(true)
        await getFormDataExport(agency, constructionCode, user, formName, beginDate, endDate)
        setLoading(false)
      }}
    >
      <DownloadIcon style={{ marginRight: 8 }}/>
      Exporter les données
    </LoadingButton>
  )
}

export default ExportFormDataButton
