import React, { useEffect, useState } from 'react'
import Header from '../components/header/header'
import { getCurrentAgenciesAPI, getSingleFormAPI } from '../API'
import InputAdvanced from '../components/InputAdvanced'
import BackButton from '../components/backButton'
import CheckBoxAdvancedActivity from '../components/checkBoxAdvancedActivity'

const getForm = async (id, setItem) => {
	const responseJson = await getSingleFormAPI(id)
	if (responseJson.error != null) return
	setItem(responseJson)
}

const getDate = (item) => {
	const date = new Date(item)
	return date.toLocaleDateString('fr-FR')
}

const getAgencies = async (setAgency) => {
	const responseJson = await getCurrentAgenciesAPI()
	setAgency(responseJson)
}

const SelectedForm = (props) => {
	const [item, setItem] =  useState(null)
	const [newItem, setNewItem] =  useState({})
	const [edit, setEdit] =  useState(false)
	const params = new URLSearchParams(props.location.search)
	const idUser = params.get('id')
	const [agency, setAgencies]= useState(null)
	const userAccess = ["Direction groupe + RQSE", "Direction BU + QSE", "Encadrement", "Maîtrise",  "Utilisateurs application"]
	useEffect(() => {
		getForm(idUser, setItem)
		getAgencies(setAgencies)
	  }, []);
	console.log("item:", item)
	console.log("agency:", agency)
	if (item === null || agency === null) return <div>Chargement ...</div>
	return(
		<div>
			<Header/>
			<BackButton />
			{item === null ? <p>Chargement ...</p>
			:
				<div className="columns" style={{marginTop: 10}}>
					<div className="column is-4" style={{marginTop: "5%"}}>
						<InputAdvanced labelTitle="Auteur" value={item.author} onChange={(event) => setNewItem({...newItem, lastName: event.target.value})} edit={edit} />
						<InputAdvanced labelTitle="Date création compte:" value={getDate(item.creationDate)} onChange={(event) => setNewItem({...newItem, lastName: event.target.value})} edit={edit} />
						<CheckBoxAdvancedActivity valueDropdown={agency} defaultValue={{agency: item.agency, activity: item.activity}} edit={true} setState={setNewItem} object={item}/>
						<div>
							<a href={item.pdfUrl} target="_blank" style={styles.imgDocument} className="button is-white">
								<img src={item.pdfUrl}/>
							</a>
							<div>
								<a href={item.pdfUrl} target="_blank">Si l'image ne charge pas, cliquez ici</a>
							</div>
						</div>
						<div className="column is-4" style={{marginTop: "5%"}}>
							<a href={item.pdfUrl} target="_blank" className="button is-white">
							</a>
						</div>
					</div>
					<div className="column is-6">
					</div>
				</div>
			}
		</div>
	)
}

const styles = {
	imgDocument: {
		height: 200,
		width: 200,
		borderRadius: 5,
		borderWidth: 3,
		borderColor: "black",
		borderStyle: "solid",
	}
}

export default SelectedForm
