import React, { useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { passwordForgottenAPI } from '../API.js'
import { forgotPasswordAPI } from '../API.js'
import InputLog from '../components/inputLog.js'


const passwordForgotten = async (email, setModalInfo) => {
	await forgotPasswordAPI(email)
	const responseJson = await passwordForgottenAPI(email)
	if (!!responseJson.err) {
		setModalInfo({title: "Une erreur est survenue", desc: "L'email fournis n'a pas été trouvé", isActive: true})
	}
	else {
		setModalInfo({title: "Un mail vous a été envoyé", desc: "Un mail contenant la démarche à suivre pour définir un nouveau mot de passe vous a été envoyé", isActive: true})
	}
}


const ModalInfo = ({info, setModalInfo}) => (
	<div className={info.isActive === true ? "modal is-active" : "modal"}>
		<div class="modal-background"></div>
		<div class="modal-card">
			<header class="modal-card-head">
				<p class="modal-card-title">{info.title}</p>
				<button class="delete" aria-label="close"></button>
			</header>
			<section class="modal-card-body">
				<p>{info.desc}</p>
			</section>
			<footer class="modal-card-foot">
				<button class="button is-success" onClick={() => setModalInfo({title: null, desc: null, isActive: false})}>Ok</button>
			</footer>
		</div>
	</div>
)

const PasswordForgotten = (props) => {
	const [login, setLogin] = useState("")
	const [modalInfo, setModalInfo] = useState({title: null, desc: null, isActive: false})
	return (
		<div style={styles.container}>
			{localStorage.getItem('token')
				? <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} /> 
				: null}
			<div className="" style={{ paddingTop: '15%'}}>
				<ModalInfo info={modalInfo} setModalInfo={setModalInfo}/>
				<div>
					<h1 style={styles.title}>Mot de passe oublié</h1>
					<p style={styles.subTitle}>Vous avez oublié votre mot de passe ?<br/>Pas de panique ! Pour le réinitialiser, saisissez votre adresse email professionnelle qui est associée à votre compte.<br/> Nous vous enverrons par email les informations pour vous créer un nouveau mot de passe.</p>
					<InputLog holder="Identifiant" setItem={setLogin} type="email" icon="fas fa-envelope"/>
				</div>
				<div style={{marginTop: 40}}>
					<Route render={({ history}) => (
						<div>
							<button class="button is-primary" onClick={() => passwordForgotten(login, setModalInfo)}>Envoyer</button>
						</div>
					)}/>
				</div>
			</div>
		</div>
	)
}


const styles = {
	container: {
		backgroundImage: `url('/assets/background.png')`,
        backgroundSize: 'cover',
		height: '102vh',
    	width: '102	vw',
	},
	box: {
		backgroundColor: 'green',
		justifyContent: 'center'
	},
	title: {
		color: "white",
		fontSize: 38,
		marginBottom: 14,
		fontWeight: "600",
	},
	subTitle: {
		color: "white",
		fontSize: 18,
		marginBottom: 24,
		fontStyle: "italic"
	}
}

export default PasswordForgotten