import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { createConstructionAPI, getCurrentAgenciesAPI, getUserFromRolesAPI, updatePasswordUserAPI } from '../API'
import BackButton from '../components/backButton'

const handlingError = (oldPassword, newPassword, verifNewPassword, setAnError) => {
	if (newPassword === null || newPassword.length < 8) {
		setAnError({message: "Votre mot de passe doit contenir au moins 8 caractères, avec une majuscule, une minuscule et un  chiffre ou un caractère spécial code1", error: true, type: "is-danger"})
		return false
	}
	else if (verifNewPassword === null || verifNewPassword.length <=8) {
		setAnError({message: "Votre mot de passe doit contenir au moins 8 caractères, avec une majuscule, une minuscule et un  chiffre ou un caractère spécial code2", error: true, type: "is-danger"})
		return false
	}
	else if (newPassword !== verifNewPassword) {
		setAnError({message: "Le mot de passe et la confirmation de mot de passe ne sont pas identiques code3", error: true, type: "is-danger"})
		return false
	}
	else if (newPassword.search(/[a-z]/) == -1) {
		setAnError({message: "Votre mot de passe doit contenir au moins 8 caractères, avec une majuscule, une minuscule, un  chiffre et un caractère spécial code4", error: true, type: "is-danger"})
		return false
	}
	else if (newPassword.search(/[A-Z]/) == -1) {
		setAnError({message: "Votre mot de passe doit contenir au moins 8 caractères, avec une majuscule, une minuscule, un  chiffre et un caractère spécial code5", error: true, type: "is-danger"})
		return false
	}
	else if (newPassword.search(/[0-9]/) == -1) {
		setAnError({message: "Votre mot de passe doit contenir au moins 8 caractères, avec une majuscule, une minuscule, un  chiffre et un caractère spécial code6", error: true, type: "is-danger"})
		return false
	}
	else if (newPassword.search(/[.,)\]\}\{\[\(:!?;]/) == -1) {
		setAnError({message: "Votre mot de passe doit contenir au moins 8 caractères, avec une majuscule, une minuscule, un  chiffre et un caractère spécial code7", error: true, type: "is-danger"})
		return false
	}
	setAnError({message: null, error: false, type: ""})
	return true
}

const handlePassword = async (oldPassword, newPassword, verifNewPassword, setAnError) => {
	if (!handlingError(oldPassword, newPassword, verifNewPassword, setAnError)) return
	const responseJson = await updatePasswordUserAPI(oldPassword, newPassword, verifNewPassword)
	if (responseJson.error != undefined) setAnError({message: responseJson.error, error: true, type: "is-danger"})
	else {
		setAnError({message: `Le mot de passe a bien été modifié`, error: false, type: "is-success"})
	}
	// history.goBack()
}

const ChangePassword = () => {
	const [oldPassword, setOldPassword] = useState(null)
	const [newPassword, setNewPassword] = useState(null)
	const [verifNewPassword, setVerifNewPassword] = useState(null)
	const [error, setAnError] = useState({message: null, error: false, type: ""})
	return (
		<div>
			<div class={`notification ${error.type}`}  style={{visibility:  error.message === null ? 'hidden' : 'visible', marginBottom: 0}}>
				<button class="delete" onClick={() => setAnError({message: null, error: false, type: ""})}></button>
				<p>{error.message}</p>
			</div>
			<BackButton />
			<div className="columns is-centered">
				<div className="column is-12">
					<h1 className="has-text-weight-bold	is-size-1">Nouveau mot de passe</h1>
					<h2 className="has-text-weight-semi-bold is-size-5">Changer mon mot de passe.</h2>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right" style={{alignSelf: "center"}}>Mot de passe actuel: </p>
				</div>
				<div className="column is-3">
					<input className="input" onChange={(event) => setOldPassword(event.target.value)} type="password" placeholder="Mot de passe actuel:"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right" style={{alignSelf: "center"}}>Nouveau mot de passe: </p>
				</div>
				<div className="column is-3">
					<input className="input" type="password" onChange={(event) => setNewPassword(event.target.value)} type="password" placeholder="Nouveau mot de passe:"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right" style={{alignSelf: "center"}}>Confirmez nouveau mot de passe: </p>
				</div>
				<div className="column is-3">
					<input className="input password"  type="password" onChange={(event) => setVerifNewPassword(event.target.value)} type="password" placeholder="Nouveau mot de passe:"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<Route render={({ history}) => (
						<button className="button is-success"onClick={() => handlePassword(oldPassword, newPassword, verifNewPassword, setAnError)} >Changer de mot de passe</button>
					)}/>
				</div>
			</div>
		</div>
	)
}

export default ChangePassword