export const existingMonths = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];


export const createYears = () => {
  const existingYears = [];
  for (let i = -5; i < 3; i++) {
    existingYears.push(new Date().getFullYear() - i);
  }
  return existingYears
};
