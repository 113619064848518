import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import {
  toggleUserStateActivityAPI,
  getSingleUserAPI,
  getCurrentAgenciesAPI,
  updateUserAPI,
} from "../API";
import InputAdvanced from "../components/InputAdvanced";
import InputAdvancedRight from "../components/inputAdvancedRight";
import BackButton from "../components/backButton";
import CheckBoxAdvancedActivity from "../components/checkBoxAdvancedActivity";
import InputAdvancedPost from "../components/inputAdvancedPost";
import ErrorToast from "../components/ErrorToast";
import SuccessToast from "../components/SuccessToast";
import UserContext from "../contexts/UserContext";

const getUser = async (id, setItem) => {
  const responseJson = await getSingleUserAPI(id);
  if (responseJson.error != null) return;
  setItem(responseJson);
};

const toggleAccountActivity = async (id, isActive) => {
  await toggleUserStateActivityAPI(id, isActive);
  window.location.reload();
};

const getDate = (item) => {
  const date = new Date(item);
  return date.toLocaleDateString("fr-FR");
};

const SelectedUser = (props) => {
  const [item, setItem] = useState(null);
  const [newItem, setNewItem] = useState({});
  const [edit, setEdit] = useState(false);
  const [agency, setAgency] = useState(null);
  const [allAgencies, setAllAgencies] = useState([]);

  const params = new URLSearchParams(props.location.search);
  const idUser = params.get("item");

  const { user } = useContext(UserContext);

  const userAccess = () => {
    const access = [
      "Direction groupe + RQSE",
      "Direction BU + QSE",
      "Encadrement",
      "Maîtrise",
      "Utilisateurs application",
    ]
    return access.filter((item, index) => {
      return index >= (user?.rules.rules ? access.indexOf(user?.rules.rules) : access.length - 1)
    });
  }

  const getAgency = async () => {
    let responseJson = await getCurrentAgenciesAPI();
    responseJson.sort((a,b)=> (a.name > b.name ? 1 : -1));
    setAgency(responseJson);
    setAllAgencies(responseJson);
  };

  const setEditMode = (edit) => {
    setEdit(edit)

    let data = [...allAgencies]
    if (edit && user?.rules.rules !== "Direction groupe + RQSE") {
      data = data.filter((item) => (user?.agency || []).includes(item.name))
      data.sort((a,b)=> (a.name > b.name ? 1 : -1));
    }
    setAgency(data)
  }

  const userBackofficeRole = [
    { value: "Responsable BU", label: "Responsable BU" },
    { value: "Responsable d'Exploitation", label: "Responsable d'Exploitation" },
    { value: "Préventeur.ice", label: "Préventeur.ice" },
    { value: "Responsable Encadrement", label: "Responsable Encadrement" },
    { value: "Responsable Maîtrise", label: "Responsable Maîtrise" },
    { value: "Autres", label: "Autres" },
  ];

	const updateData = async (data, id, setEdit) => {
    const regExPhoneNumber = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/

		data.id = id;
    if (data.phoneNumber && !regExPhoneNumber.test(data.phoneNumber)) {
      ErrorToast("Format de téléphone non valide");
    } else {
      const responseJson = await updateUserAPI(data);
      if (Object.keys(responseJson).includes("error")) {
        ErrorToast(Object.values(responseJson)[0]);
      } else {
        SuccessToast("Utilisateur mis à jour !");
      }
      setEdit(false);
    }
	};

  useEffect(() => {
    getUser(idUser, setItem);
    getAgency();
  }, []);

  if (agency === null) return <div>Chargement ...</div>;
  return (
    <div>
      <Header />
      <BackButton />
				{item && user && user?.rules.rules !== 'Maîtrise' && user?.rules.rules !== "Utilisateurs application" ? (
					<div className="columns" style={{ marginTop: 10 }}>
          <div className="column is-4" style={{ marginTop: "5%", marginBottom: "50px" }}>
            <InputAdvanced
              labelTitle="Nom"
              value={item.lastName}
              onChange={(event) =>
                setNewItem({ ...newItem, lastName: event.target.value })
              }
              edit={edit}
            />
            <InputAdvanced
              labelTitle="Prénom"
              value={item.firstName}
              onChange={(event) =>
                setNewItem({ ...newItem, firstName: event.target.value })
              }
              edit={edit}
            />
            <InputAdvanced
              labelTitle="Email"
              value={item.email}
              edit={edit}
              onChange={(event) =>
                setNewItem({ ...newItem, email: event.target.value })
              }
            />
            <InputAdvanced
              labelTitle="Numéro de téléphone"
              value={item.phoneNumber}
              edit={edit}
              onChange={(event) =>
                setNewItem({ ...newItem, phoneNumber: event.target.value })
              }
            />
            <InputAdvancedRight
              label="Niveau d'accès"
              defaultValue={item.rules.rules}
              values={userAccess()}
              enabled={edit}
              onChange={(value) => setNewItem({...newItem, rules: value})}
            />
            <InputAdvancedPost
              label="Poste"
              defaultValue={item.backofficeRole}
              values={userBackofficeRole}
              enabled={edit}
              onChange={(value) => setNewItem({...newItem, backofficeRole: value})}
            />
            <CheckBoxAdvancedActivity
              valueDropdown={agency}
              defaultValue={{ agency: item.agency, activity: item.activity }}
              edit={!edit}
              setState={setNewItem}
              object={item}
            />
          </div>
          <div className="column is-6">
            {userAccess().includes(item.rules.rules) && (
              <div className="columns">
                <div className="column is-2 is-offset-4">
                  {!edit ? (
                    <div className="column is-12">
                      <button
                        className="button is-info"
                        onClick={() => setEditMode(true)}
                      >
                        <span>Editer</span>
                        <span className="icon is-small">
                        <i className="fas fa-pen"></i>
                      </span>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="column is-12">
                        <button
                          className="button is-danger"
                          onClick={() => setEditMode(false)}
                        >
                          <span>Annuler</span>
                          <span className="icon is-small">
                          <i className="fas fa-times"></i>
                        </span>
                        </button>
                      </div>
                      <div className="column is-12">
                        <button
                          className="button is-success"
                          onClick={() => updateData(newItem, item._id, setEditMode)}
                        >
                          <span>Sauvegarder</span>
                          <span className="icon is-small">
                          <i className="fas fa-check"></i>
                        </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="column is-2">
                  <div className="column is-12">
                    {item.isActive ? (
                      <button
                        className="button is-danger"
                        onClick={() => toggleAccountActivity(item._id, false)}
                      >
                        {" "}
                        Désactiver le compte
                      </button>
                    ) : (
                      <button
                        className="button is-success"
                        onClick={() => toggleAccountActivity(item._id, true)}
                      >
                        {" "}
                        Activer le compte
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="column is-6 is-offset-3">
              <p>
                <strong>Date création compte: </strong>
                {getDate(item.creationDate)}
              </p>
            </div>
          </div>
        </div>
				) : (<p>Vous n'avez pas les droits vous permettant de modifier le compte d'un utilisateur...</p>)}
    </div>
  );
};

export default SelectedUser;
