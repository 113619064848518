import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#dc0020',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: 'Exo 2',
  },
})
