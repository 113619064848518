import React, { useState } from 'react'
import 'antd/dist/antd.css'
import { Checkbox } from 'antd';


const handleClick = (value, tmpArray, setTmpArray, setForceRefresh, setState, object) => {
	const tmp = tmpArray
	if (tmp.agency.includes(value.name)) {
		const index = tmp.agency.indexOf(value.name)
		tmp.agency.splice(index, 1);
		tmp.activity.splice(index, 1);
	} else {
		tmp.agency.push(value.name)
		tmp.activity.push(value.activity)
	}
	setState({...object, agency: tmp.agency, activity: tmp.activity})
	setTmpArray(tmp)
	setForceRefresh(Math.floor(Math.random() * 1000))
}

const CheckBoxAdvancedActivity = ({defaultValue, setState, edit, object, valueDropdown, cpy}) => {

	const [tmpArray, setTmpArray] = useState(defaultValue)
	const cpyAgency = tmpArray.agency.map((value) => {return value.toUpperCase()})
	const [_, setForceRefresh] = useState(-1)

	return (
		<div className="columns" style={{marginTop: 12}}>
			<div className="column is-offset-1"></div>
			<table class="table is-bordered">
				<thead>
					<tr>
						<th><abbr style={{textDecoration : 'none'}}  title="Activer">Activer</abbr></th>
						<th><abbr style={{textDecoration : 'none'}}  title="Agence">Agence(s)/Filiale(s)</abbr></th>
						<th><abbr style={{textDecoration : 'none'}} title="Activité">Activité</abbr></th>
					</tr>
				</thead>
				<tbody>
					{valueDropdown.map((value => {
						return (<tr>
							<td>
							<Checkbox
								disabled={edit}
								checked={cpyAgency.includes(value.name.toUpperCase()) ? true: false}
								onChange={() => handleClick(value, tmpArray, setTmpArray, setForceRefresh, setState, object)}
								/>
							</td>
							<td>{value.name}</td>
							<td>{value.activity}</td>
						</tr>)
					}))}
				</tbody>
			</table>
		</div>
	)
}

export default CheckBoxAdvancedActivity
