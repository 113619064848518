import React, { useEffect, useState } from 'react'
import Header from '../components/header/header'
import { Route } from 'react-router-dom'
import {getFormAPI } from '../API.js'
import TitleTable from '../components/titleTable'

const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

const getDate = (item) => {
	const date = new Date(item)
	return date.toLocaleDateString('fr-FR')
}

const displayAgencies = (agency) => {
	return <div>
		{agency.map((item) => {
			return <p>{item}</p>
		})}
		</div>
}

// const filterUp = (arr, value,) => {
// 	return arr.sort(function(a, b){
// 		if(a[value] < b[value]) { return -1; }
// 		if(a[value] > b[value]) { return 1; }
// 		return 0;
// 	})
// }

// const filterDown = (arr, value,) => {
// 	return arr.sort(function(a, b){
// 		if(a[value] > b[value]) { return -1; }
// 		if(a[value] < b[value]) { return 1; }
// 		return 0;
// 	})
// }
// const filter = (arr, value, setConstructionSite, setRandom, index, positionFilter, setPositionFilter) => {
// 	if (index !== positionFilter.position) {
// 		setConstructionSite(filterDown(arr, value, setConstructionSite, setRandom))
// 		setPositionFilter({position: index, ascending: false})
// 		setRandom(Math.floor(Math.random() * 100))
// 	}
// 	else if (index === positionFilter.position && positionFilter.ascending) {
// 		setConstructionSite(filterDown(arr, value, setConstructionSite, setRandom))
// 		setPositionFilter({position: index, ascending: false})
// 		setRandom(Math.floor(Math.random() * 100))
// 	}
// 	else {
// 		setConstructionSite(filterUp(arr, value, setConstructionSite, setRandom))
// 		setPositionFilter({position: index, ascending: true})
// 		setRandom(Math.floor(Math.random() * 100))
// 	}
// }

const getForm = async (agency, formId, setForm) => {
	const responseJson = await getFormAPI(formId, agency)
	if (!!responseJson.error) return setForm(null)
	setForm(responseJson)
}

const displayButton = (oldLine, newLine, setLineOpen) => {
	if (oldLine === -1) setLineOpen(newLine)
	else if (oldLine === newLine) setLineOpen(-1)
	else setLineOpen(newLine)
}

const ChooseFormToDisplay = (props) => {
	const id = localStorage.getItem('id')
	const [form, setForm] = useState(null)
	const [selectedagency, setSelectedagency] = useState(null)
	const [lineEditable, setLineEditable] = useState(-1)
	const [itemUpdated, setItemUpdated] = useState({})
	const [error, setAnError] = useState({message: null, error: false, type: ""})
	const [random, setRandom] = useState(-1)
	const [positionFilter, setPositionFilter] = useState({position: -1, ascending: false})
	const [lineToOpen, setLineOpen] = useState(-1)
	const params = new URLSearchParams(props.location.search)
	const agency = params.get('agency')
	const formId = params.get('form')
	useEffect(() => {
		getForm(agency, formId, setForm)
	}, []);
	console.log(form)
	console.log(form)
	console.log(form)
	return(
		<div>
			<Header/>
			<div className="columns is-centered" style={{marginTop: 44}}>
				<div className="column is-12">
					<h1 className="has-text-weight-bold	is-size-1">Choisissez un formulaire</h1>
				</div>
			</div>
			<table className="table is-fullwidth">
				<thead>
					<tr>
						<TitleTable title="Auteur" data={form} field="constructionCode" setData={setForm} setRandom={setRandom} index={0} positionFilter={positionFilter} setPositionFilter={setPositionFilter}/>
						<TitleTable title="Agences" data={form} field="constructionCode" setData={setForm} setRandom={setRandom} index={0} positionFilter={positionFilter} setPositionFilter={setPositionFilter}/>
						<TitleTable title="Date" data={form} field="constructionName" setData={setForm} setRandom={setRandom} index={1} positionFilter={positionFilter} setPositionFilter={setPositionFilter}/>
					</tr>
				</thead>
				<tbody>
					{	
						form  === null ?<p>Chargement ...</p>
						: form.map((item) => {
							return (
								<Route render={({ history}) => (
									<React.Fragment>
										<tr style={item.isActive === false ? {backgroundColor: 'lightgray'} : null}  onClick={() => {history.push(`/form?id=${item._id}`)}}>
											<td style={{cursor: "pointer"}}>{item.author}</td>
											<td style={{cursor: "pointer"}}>{displayAgencies(item.agency)}</td>
											<td style={{cursor: "pointer"}}>{getDate(item.creationDate)}</td>
										</tr>
									</React.Fragment>
								)}/>	
								)
							})
					}
				</tbody>
			</table>
		</div>
	)
}

export default ChooseFormToDisplay