import React, { useState } from 'react'
import 'antd/dist/antd.css'
import { Select, Checkbox } from 'antd';
const { Option } = Select


const handleClick = (value, tmpArray, setTmpArray, setForceRefresh, setState, object) => {
  let tmp = tmpArray
  let index = -1;

  for (let i = 0; i < tmp.permitForms.length; i++) {
    if (tmp.permitForms[i].uniqueId === Number(value.uniqueId)) {
      index = i;
      break;
    }
  }
  
  if (index !== -1) {
		tmp.permitForms.splice(index, 1);
	} else {
		tmp.permitForms.push({uniqueId: value.uniqueId, title: value.title})	
	}

  setState(tmp.permitForms);
	setTmpArray(tmp)
	setForceRefresh(Math.floor(Math.random() * 1000))
}

const CheckBoxAdvancedForm = ({defaultValue, setState, edit, object, valueDropdown, cpy}) => {

	const [tmpArray, setTmpArray] = useState(defaultValue)
	const cpyPermitedForms = tmpArray.permitForms.map((value) => {return value.uniqueId})
	const [_, setForceRefresh] = useState(-1)

	return (
		<div className="columns" style={{marginTop: 22}}>
			<div className="column is-offset-1"></div>
			<table class="table is-bordered">
				<thead>
					<tr>
						<th><abbr style={{textDecoration : 'none'}}  title="Activer">Activer</abbr></th>
						<th><abbr style={{textDecoration : 'none'}}  title="Formulaire">Formulaire</abbr></th>
					</tr>
				</thead>
				<tbody>
					{valueDropdown.map((value => {
						return (<tr>
							<td>
							<Checkbox
								disabled={edit}
								checked={cpyPermitedForms.includes(value.uniqueId) ? true : false}
								onChange={() => handleClick(value, tmpArray, setTmpArray, setForceRefresh, setState, object)}
								/>
							</td>
							<td>{value.title}</td>
						</tr>)
					}))}
				</tbody>
			</table>
		</div>
	)
}

export default CheckBoxAdvancedForm;