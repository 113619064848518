import React from "react";

const filterUp = (arr, value) => {
  return arr.sort(function (a, b) {
    if (a[value] < b[value]) {
      return -1;
    }
    if (a[value] > b[value]) {
      return 1;
    }
    return 0;
  });
};

const filterDown = (arr, value) => {
  return arr.sort(function (a, b) {
    if (a[value] > b[value]) {
      return -1;
    }
    if (a[value] < b[value]) {
      return 1;
    }
    return 0;
  });
};

const filter = (
  arr,
  value,
  setConstructionSite,
  setRandom,
  index,
  positionFilter,
  setPositionFilter
) => {
  if (index !== positionFilter.position) {
    setConstructionSite(filterDown(arr, value, setConstructionSite, setRandom));
    setPositionFilter({ position: index, ascending: false });
    if (setRandom) setRandom(Math.floor(Math.random() * 100));
  } else if (index === positionFilter.position && positionFilter.ascending) {
    setConstructionSite(filterDown(arr, value, setConstructionSite, setRandom));
    setPositionFilter({ position: index, ascending: false });
    if (setRandom) setRandom(Math.floor(Math.random() * 100));
  } else {
    setConstructionSite(filterUp(arr, value, setConstructionSite, setRandom));
    setPositionFilter({ position: index, ascending: true });
    if (setRandom) setRandom(Math.floor(Math.random() * 100));
  }
};

const TitleTable = ({
  title,
  data,
  field,
  setData,
  setRandom,
  index,
  positionFilter,
  setPositionFilter,
}) => {
  return (
    <th>
      <abbr style={{ textDecoration: "none" }} title={title}>
        {title}
        <i
          style={{ cursor: "pointer" }}
          onClick={() =>
            filter(
              data,
              field,
              setData,
              setRandom,
              index,
              positionFilter,
              setPositionFilter
            )
          }
          class={
            positionFilter.position === 0 && positionFilter.ascending
              ? "fas fa-caret-up"
              : "fas fa-caret-down"
          }
        ></i>
      </abbr>
    </th>
  );
};

export default TitleTable;
