import React from "react";
import Select from "react-select";

const InputAdvancedPost = ({
  label,
  defaultValue,
  values,
  onChange,
  enabled,
}) => {
  return (
    <div class="columns">
      <div class="column" style={{ alignSelf: "center" }}>
        <p>{label}:</p>
      </div>
      <div class="column">
        <Select
          onChange={(value) => onChange(value.value)}
          defaultValue={!enabled ? { label: defaultValue } : []}
          name="colors"
          isDisabled={!enabled}
          options={values.map((value) => {
            return { value: value.value, label: value.label };
          })}
          className="basic-multi-select"
          classNamePrefix="Oui"
        />
      </div>
    </div>
  );
};

export default InputAdvancedPost;
