import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import { createAgencyAPI } from '../API'
import Select from 'react-select'
import BackButton from '../components/backButton'
import SuccessToast from "../components/SuccessToast";
import ErrorToast from '../components/ErrorToast';
import { useHistory } from 'react-router-dom';
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const handlingError = (body, setAnError) => {
	if (body.name === null || body.name.length <= 0) {
		ErrorToast("Le champs \"Nom de l'agence\" doit obligatoirement etre remplie.");
		return false
	}
	else if (body.activity === null || body.activity.length < 1) {
		ErrorToast("Le champs \"Activité de l'agence :\" doit obligatoirement etre remplie.");
		return false
	}
	setAnError({message: null, error: false, type: ""})
	return true
}

const createAgency = async (body, setAnError, history) => {
	if (handlingError(body, setAnError) === false) return
	const responseJson = await createAgencyAPI(body)
	if (responseJson.error != null){
		ErrorToast(responseJson.error);
		return
	}
	SuccessToast("Nouvelle agence créé");
  history.goBack();
}


const NewAgency = () => {
	const [body, setBody] = useState({name: null, activity: null})
	const [error, setAnError] = useState({message: null, error: false, type: ""})
	const history = useHistory();
	const { user } = useContext(UserContext);

	return (
		<div>
		{user?.rules.rules != "Maîtrise" &&
      user?.rules.rules != "Utilisateurs application" &&
      user?.rules.rules != "Encadrement" ? (
        <>
			<div class={`notification ${error.type}`}  style={{visibility:  error.message === null ? 'hidden' : 'visible'}}>
				<button class="delete" onClick={() => setAnError({message: null, error: false, type: ""})}></button>
				<p>{error.message}</p>
			</div>
			<BackButton/>
			<div className="columns is-centered">
				<div className="column is-12">
					<h1 className="has-text-weight-bold	is-size-1">Gestion des Filiales</h1>
					<h2 className="has-text-weight-semi-bold is-size-5">Complétez les différents champs afin de créer une nouvelle Agence(s)/Filiale(s)</h2>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p>Nom de l'agence ou filiale :</p>
				</div>
				<div className="column is-3">
					<input className="input" onChange={(event) => setBody({...body, name: event.target.value})} type="text" placeholder="Nom de l'agence :"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p>Activité de l'agence ou filiale :</p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(event) => {setBody({...body, activity: event.activity})}}
							defaultValue={[]}
							name="colors"
							options={[
								{activity: "Batiment", label: "BATIMENT",},
								{activity: "Immobilier", label: "IMMOBILIER"},
								{activity: "International", label: "INTERNATIONAL"},
								{activity: "OA - GC", label: "OA - GC"},
								{activity: "TMF - TSM", label: "TMF - TSM"},
								{activity: "TRAVAUX SPECIAUX", label: "TRAVAUX SPECIAUX"},
							]}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<Route render={({ history}) => (
						<button className="button is-success"onClick={() => createAgency(body, setAnError, history)}>Creer une agence</button>
					)}/>
				</div>
			</div>
			</>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
		</div>
	)
}

export default NewAgency