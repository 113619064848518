import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import firebase from "firebase/app"
import  "firebase/storage"
import {storage} from '../firebase/index'
import { createNotificationAPI, sendDocumentAPI, getMyInfoAPI } from '../API'
import Select from 'react-select'
import BackButton from '../components/backButton'
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import ErrorToast from '../components/ErrorToast'
import SuccessToast from '../components/SuccessToast'
import { Checkbox } from '@mui/material'
import { getAuthorizedAgencies } from '../NewAPI'

const sendDocumentToHostingServer = (file) => {
	return new Promise((resolve, reject) => {
		const uploadTask = storage.ref('documents/' + file.name).put(file);
		uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
			() => {},
			(error) => resolve({error}),
			() => {
				uploadTask.snapshot.ref.getDownloadURL().then(resolve)
			}
		)
	})
}

const sendDocumentToAPI = async (body) => {
	const responseJson = await sendDocumentAPI(body)
	return responseJson
}

const getMyInfo = async (setInfoUser) => {
	const responseJson = await getMyInfoAPI()
	setInfoUser(responseJson)
}

const handlingError = (body, documentToSend, sendNotification, setAnError) => {
	console.log(body);
	if (body.title === null || body.title.length <= 0) {
		ErrorToast( "Le champs \"Titre du document\" doit obligatoirement etre remplie.");
		return false
	}
	else if (documentToSend.name === null) {
		ErrorToast("Le champs \"Choisir un fichier\" doit obligatoirement etre remplie.");
		return false
	}
	else if (!body.global && (body.agency === null || body.agency <= 0)) {
		ErrorToast("Le champs \"Agence/Filiales\" doit obligatoirement etre remplie.");
		return false
	}
	else if (body.category === null) {
		ErrorToast("Le champs \"Catégorie du document\" doit obligatoirement etre remplie.");
		return false
	}
	else if (body.subCategory === null) {
		ErrorToast("Le champs \"Sous-catégorie du document\" doit obligatoirement etre remplie.");
		return false
	}

	setAnError({message: null, error: false, type: ""})
	return true
}

const sendDocument = async (sendNotification, body, documentToSend, infoUser, setAnError, history) => {
	if (!handlingError(body, documentToSend, sendNotification, setAnError)) return
	let documentURL = null
	if (documentToSend.name !== null) documentURL = await sendDocumentToHostingServer(documentToSend)
	if ( documentURL !== null &&!!documentURL.error) console.log(documentURL.error)
	else {
		body.documentType = documentToSend.type
		body.documentURL = documentURL
	}
	const autor = (infoUser.lastName === null || infoUser.lastName.length <= 0)
	|| (infoUser.firstName === null || infoUser.firstName.length <= 0) ? "Non défini" : `${infoUser.firstName} ${infoUser.lastName}`
	let finalBody = {...body, autor: autor}
	const responseJson = await sendDocumentToAPI(finalBody)
	if (sendNotification) createNotificationAPI({title: body.title, type: "Document", autor: autor, notificationId: responseJson.id, documentURL: body.documentURL, category: body.category, subCategory: body.subCategory, agency: body.agency, global: body.global})
	if (responseJson.error != null) return ErrorToast(responseJson.error);
	else SuccessToast(`Le document "${body.title}" a bien été créé`);
	history.goBack();
}

const NewDocument = () => {
	const [sendNotification, setSendNotification] = useState(false)
	const [documentToSend, setDocumentToSend] = useState({name: null})
	const [itemIntex, setItemIndex] = useState(0)
	const [infoUser, setInfoUser] = useState(null)
	const [error, setAnError] = useState({message: null, error: false, type: ""})
	const { user } = useContext(UserContext);
	const itemCategories = [
		{
			title: "Communication",
			subCategory: ["Min prévention", "Support 1/4H", "Flash", "Revue de presque"]
		},
		{
			title: "Bibliothèque",
			subCategory: ["Rappels", "Matériel", "Chantier", "Accident du travail"]
		},
	]
	const [body, setBody] = useState({title: null, content: null, category: null, subCategory: null, documentURL: null, documentType: null, agency: [], global: false})
	const [agencies, setAgencies] = useState([])

	const getAgencies = async () => {
		const data = await getAuthorizedAgencies(user)
		setAgencies(data)
	}

	useEffect(() => {
		getMyInfo(setInfoUser)
		getAgencies()
	}, []);

	if (infoUser === null) {
		return (
			<div>
				<BackButton />
				<p>Chargement création de document...</p>
			</div>
		  )
	}

	return (
		<div>
			<BackButton />
			{user && (user?.rules.rules === 'Maîtrise' || user?.rules.rules === 'Utilisateurs application') ? <h1>Vous n'avez pas les droits pour créer un nouveau document</h1> : (<>
			<div class={`notification ${error.type}`}  style={{visibility:  error.message === null ? 'hidden' : 'visible'}}>
				<button class="delete" onClick={() => setAnError({message: null, error: false, type: ""})}></button>
				<p>{error.message}</p>
			</div>
			<div className="columns is-centered">
				<div className="column is-12">
					<h1 className="has-text-weight-bold	is-size-1">Nouveau document</h1>
					<h2 className="has-text-weight-semi-bold is-size-5">Complétez les différents champs afin de créer un nouveau document</h2>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Titre du document</p>
				</div>
				<div className="column is-3">
					<input className="input" type="text" onChange={(event) => setBody({...body, title: event.target.value})} placeholder="Titre du document"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Choisir un fichier</p>
				</div>
				<div className="column is-3">
					<div className="file has-name">
						<label className="file-label">
							<input className="file-input" type="file" name="resume" onChange={(file) => setDocumentToSend(file.target.files[0])} />
								<span className="file-cta">
									<span className="file-icon">
										<i className="fas fa-upload"></i>
									</span>
									<span className="file-label">Choisir un fichier</span>
								</span>
								<span className="file-name">
									{documentToSend.name || "Aucun fichier séléctionné"}
								</span>
						</label>
					</div>
				</div>
			</div>

			{user?.rules.rules === 'Direction groupe + RQSE' && (
				<div className="columns is-centered">
					<div className="column is-3" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
						<p className="has-text-right">Groupe ETPO</p>
					</div>

					<div className="column is-3">
						<div className="is-rounded has-text-left">
							<Checkbox
								onChange={(event) => setBody({...body, global: event.target.checked, agency: []})}
							/>
						</div>
					</div>
				</div>
			)}

			{!body.global && (
				<div className="columns is-centered">
					<div className="column is-3">
						<p className="has-text-right">Agence(s)/Filiale(s)</p>
					</div>

					<div className="column is-3">
						<div className=" is-rounded">
							<Select
								onChange={(event) => {setBody({...body, agency: event.map((value) => value.agency), activity: event.map((value) => value.activity)})}}
								isMulti
								defaultValue={[]}
								name="colors"
								options={agencies.map((value, index) => {
									return {value: value.name, label: value.name, agency: value.name, activity: value.activity}
								})}
								className="basic-multi-select"
								classNamePrefix="Oui"
							/>
						</div>
					</div>
				</div>
			)}

			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Catégorie du document</p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(value) => {
								setItemIndex(value.index)
								setBody({...body, category: value.value})
							}}
							defaultValue={[]}
							name="colors"
							options={itemCategories.map((value, index) => {
								return {value: value.title, label: value.title, index}
							})}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Sous-catégorie du document</p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(value) => {setBody({...body, subCategory: value.value})}}
							defaultValue={[]}
							name="colors"
							options={itemCategories[itemIntex].subCategory.map((value) => {
								return {value, label: value}
							})}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Voulez vous créer une notification ?</p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(value) => setSendNotification(value.value === true)}
							defaultValue={[{value: false, label: "Non"}]}
							name="colors"
							options={[{value: false, label: "Non"}, {value: true, label: "Oui"}]}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>

			<div className="columns is-centered">
				<div className="column is-3">
					<Route render={({ history}) => (
						<button className="button is-success"onClick={() => sendDocument(sendNotification, body, documentToSend, infoUser, setAnError, history)}>Ajouter le document</button>
					)}/>
				</div>
			</div></>)}
		</div>
	)
}

export default NewDocument
