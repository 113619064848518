import React from 'react'

const InputAdvanced = ({labelTitle, value, edit, onChange,}) => {
	return (
		<div className="columns">
			<div className="column" style={{alignSelf: "center"}}>
				<p>{labelTitle} :</p>
			</div>
			<div className="column">
				<div className="control">
					<input className={ edit ? "input is-info" : "input"}  defaultValue={value} type="text" onChange={onChange} placeholder={labelTitle} readOnly={!edit}/>
				</div>
			</div>
		</div>
	)
}

export default InputAdvanced