import { toast } from "react-toastify";

const ErrorToast = (message) => {
  toast.error(message, {
    theme: "dark",
    position: "bottom-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default ErrorToast;
