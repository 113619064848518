import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { createYears, existingMonths } from "../utils/dates";
import { capitalize } from "../utils/capitalize";
import SuccessToast from "../components/SuccessToast";
import FormControl from "@mui/material/FormControl";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  formInput: {
    display: "flex",
    height: 26,
    width: "100%",
    justifyContent: "space-between",
    padding: -10,
  },
  newExpected: {
    marginTop: 10,
  },
  indicator: {
    marginTop: 10,
    marginRight: 10,
    fontSize: 12,
  },
  inputIndicatorNewExpected: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 0.1,
    borderColor: "grey",
    borderRadius: 5,
    marginTop: 1.6,
  },
  filter: {
    flex: 1,
    flexWrap: "wrap",
    flexDirecton: "row",
    width: 200,
    marginTop: 15
  },
}));

const Executed = ({ handleClose, reload, agencies }) => {
  const { user } = useContext(UserContext);
  const { get, post } = useFetch();
  const existingYears = createYears();
  const [inputs, setInputs] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [indicator, setIndicator] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    capitalize(new Date().toLocaleDateString("fr-FR", { month: "long" }))
  );
  const [existingExpected, setExistingExpected] = useState([]);
  const [completedDate, setCompletedDate] = useState([]);
  const [agency, setAgency] = useState({});

  const [sites, setSites] = useState([]);
  const [site, setSite] = useState({});
  const [filter, setFilter] = useState("");



  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };
  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeIndicator = (event) => {
    setIndicator(event.target.value);
  };

  const handleChangeAgency = (event) => {
    setAgency(event.target.value);
  };

  const handleChangeSite = (event) => {
    setSite(event.target.value);
  };

  const classes = useStyles();

  useEffect(() => {
    const getIndicators = async () => {
      const data = await get("indicators");
      setIndicators(data);
      setInputs(data.map((e) => {
        return { group: e.group, name: e.name, id: e._id, value: 0 }
      }))
    };
    const getSites = async () => {
      const data = await get("constructionsite");
      setSites(data);
    };

    getIndicators();
    getSites();
	}, []);

  const handleSubmit = async (e) => {
    const data = {
      agency: agency._id,
      year: year,
      month: month,
      indicatorId: indicator._id,
      indicatorName: indicator.name,
      siteCode: site.constructionCode,
      jobPosition: agency.activity,
      userId: user._id
    };
    await post("executed/", data);
    SuccessToast("Réalisé créé !");
    setInputs([]);
    handleClose();
    reload();
  };


  return (
    <div>
      <h1 style={{ fontSize: 16, fontWeight: 'bold' }}>Ajout d'un réalisé</h1>
      <div className={classes.filter}>
        <h1>Agence :</h1>
        <FormControl className={classes.child}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={agency}
            onChange={handleChangeAgency}
            style={{ minWidth: 200 }}
          >
            {agencies.map((agency) => (
              <MenuItem value={agency}>{agency.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.filter}>
        <h1>Code chantier :</h1>
        <FormControl className={classes.child}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={site}
            onChange={handleChangeSite}
            style={{ minWidth: 200 }}
          >
            {sites.map((site) => (
              <MenuItem value={site}>{site.constructionCode}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className={classes.filter}>
        <h1>Date :</h1>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={month}
          onChange={handleChangeMonth}
        >
          {existingMonths.map((month) => (
            <MenuItem value={month}>{month}</MenuItem>
          ))}
        </Select>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={year}
          onChange={handleChangeYear}
        >
          {existingYears.map((year) => (
            <MenuItem value={year}>{year}</MenuItem>
          ))}
        </Select>
      </div>

      <div className={classes.filter}>
        <h1>Indicateur :</h1>
        <FormControl className={classes.child}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={indicator}
            onChange={handleChangeIndicator}
            style={{ minWidth: 200 }}
          >
            {indicators.map(
              (indicator) =>
                indicator.manual && (
                  <MenuItem value={indicator}>{indicator.name}</MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </div>

      {Object.keys(indicator).length > 0 &&
      Object.keys(site).length > 0 &&
      Object.keys(agency).length > 0 ? (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          onClick={handleSubmit}
          style={{ marginTop: 30 }}
        >
          Enregistrer
        </Button>
      ) : (
        <Button
          variant="contained"
          disableElevation
          style={{ marginTop: 30 }}
        >
          Enregistrer
        </Button>
      )}
    </div>
  );
};

export default Executed;
