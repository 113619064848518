const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://skeeper.etpogroup.com/api/'

export async function getActiveAgencies() {
  const response = await fetch(baseUrl + 'agency/current', {
    method: 'GET', headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })
  const data = await response.json()
  data.sort((a, b) => (a.name > b.name) ? 1 : -1)
  return data
}

export async function getAuthorizedAgencies(user) {
  let data = await getActiveAgencies()
  if (user?.rules.rules !== 'Direction groupe + RQSE')
    data = data.filter((e) => (user?.agency || []).includes(e.name))
  return data
}

export async function getAgencies() {
  const response = await fetch(baseUrl + 'agency', {
    method: 'GET', headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })
  const data = await response.json()
  data.sort((a, b) => (a.name > b.name) ? 1 : -1)
  return data
}

export async function getConstructionSites(agency, siteCode, siteName, clientName, cityName) {
  const params = new URLSearchParams()
  if (agency) params.set('agency', agency)
  if (siteCode) params.set('siteCode', agency)
  if (siteName) params.set('siteName', agency)
  if (clientName) params.set('clientName', agency)
  if (cityName) params.set('cityName', agency)
  const hasParam = Array.from(params).length > 0

  const response = await fetch(baseUrl + `constructionSite/constructionbyfilters${hasParam ? '?' : ''}` + params.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })

  const data = await response.json()
  data.sort((a, b) => new Date(b.date) - new Date(a.date))
  return data
}

export async function getDashboardData(year, agencyId, activity, siteCode, userId) {
  const params = new URLSearchParams()
  if (year) params.set('year', year)
  if (agencyId) params.set('agencyId', agencyId)
  if (activity) params.set('activity', activity)
  if (siteCode) params.set('siteCode', siteCode)
  if (userId) params.set('userId', userId)
  const hasParam = Array.from(params).length > 0

  const response = await fetch(baseUrl + `data/dashboard${hasParam ? '?' : ''}` + params.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })

  return await response.json()
}

export async function getDocuments(title, category, subCategory, agency) {
  const params = new URLSearchParams()
  if (title) params.set('title', title)
  if (category) params.set('category', category)
  if (subCategory) params.set('subCategory', subCategory)
  if (agency) params.set('agency', agency)
  const hasParam = Array.from(params).length > 0

  const response = await fetch(baseUrl + `document/documentbyfilters${hasParam ? '?' : ''}` + params.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })

  const data = await response.json()
  data.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
  return data
}

export async function getForms(agency, siteCode, user, formName, beginDate, endDate) {
  const params = new URLSearchParams()
  if (agency) params.set('agency', agency)
  if (siteCode) params.set('siteCode', siteCode)
  if (user) params.set('user', user)
  if (formName) params.set('formName', formName)
  if (beginDate) params.set('beginDate', beginDate)
  if (endDate) params.set('endDate', endDate)
  const hasParam = Array.from(params).length > 0

  const response = await fetch(baseUrl + `form/formbyfilters${hasParam ? '?' : ''}` + params.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })

  const data = await response.json()
  data.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
  return data
}

export async function getFormDataExport(agency, siteCode, user, formName, beginDate, endDate) {
  const params = new URLSearchParams()
  if (agency) params.set('agency', agency)
  if (siteCode) params.set('siteCode', siteCode)
  if (user) params.set('user', user)
  if (formName) params.set('formName', formName)
  if (beginDate) params.set('beginDate', beginDate)
  if (endDate) params.set('endDate', endDate)
  const hasParam = Array.from(params).length > 0

  let filename = `Formulaires - ${beginDate} - ${endDate}`
  if (agency) filename += ` - ${agency}`
  if (siteCode) filename += ` - ${siteCode}`
  if (user) filename += ` - ${user}`
  if (formName) filename += ` - ${formName}`

  await fetch(baseUrl + `form/exportdata${hasParam ? '?' : ''}` + params.toString(), {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  }).then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(url => {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = `${filename}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    })
}

export async function getNotifications(title, type, agency) {
  const params = new URLSearchParams()
  if (title) params.set('title', title)
  if (type) params.set('type', type)
  if (agency) params.set('agency', agency)
  const hasParam = Array.from(params).length > 0

  const response = await fetch(baseUrl + `notification/notificationbyfilters${hasParam ? '?' : ''}` + params.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })

  const data = await response.json()
  data.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
  return data
}

export async function getUsers(firstName, lastName, email, rule, agency, activity) {
  const params = new URLSearchParams()
  if (firstName) params.set('firstName', firstName)
  if (lastName) params.set('lastName', lastName)
  if (email) params.set('email', email)
  if (rule) params.set('rule', rule)
  if (agency) params.set('agency', agency)
  if (activity) params.set('activity', activity)
  const hasParam = Array.from(params).length > 0

  const response = await fetch(baseUrl + `users/userbyfilters${hasParam ? '?' : ''}` + params.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  })

  const data = await response.json()
  data.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
  return data
}
