import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { createConstructionAPI, getUserFromRolesAPI } from '../API'
import Select from 'react-select'
import BackButton from '../components/backButton'
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import ErrorToast from '../components/ErrorToast'
import SuccessToast from '../components/SuccessToast'
import { Checkbox } from '@mui/material'
import { getAuthorizedAgencies } from '../NewAPI'

const getUserFromRoles = async (roles, setData) => {
	const responseJson = await getUserFromRolesAPI(roles)
	if (responseJson.length === 0) setData([])
	else setData(responseJson)
}

const handlingError = (body, setAnError) => {
	if (body.constructionCode === null || body.constructionCode.length <= 1) {
		ErrorToast("Le champ \"Code chantier\" doit obligatoirement être rempli.");
		return false
	}
	else if (body.constructionName === null || body.constructionName.length <= 1) {
		ErrorToast("Le champ \"Nom chantier\" doit obligatoirement être rempli.");
		return false
	}
	else if (body.agency === null || body.agency.length < 1 || body.activity === null || body.activity.length < 1) {
		ErrorToast( "Le champ \"Agence/Filiale\" doit obligatoirement être rempli.");
		return false
	}
	else if (body.responsableBu === null ||body.responsableBu.length < 1) {
		ErrorToast( "Le champ \"Responsable BU\" doit obligatoirement être rempli.");
		return false
	}
	else if (body.preventeur === null ||body.preventeur.length < 1) {
		ErrorToast("Le champ \"Préventeur/trice\" doit obligatoirement être rempli.");
		return false
	}
	setAnError({message: null, error: false, type: ""})
	return true
}

const handleCreationConstruction = async (history, body, setAnError) => {
	if (!handlingError(body, setAnError)) return
	const responseJson = await createConstructionAPI(body)
	if (responseJson.error != null) {
		setAnError({message: responseJson.error, error: true, type: "is-danger"})
		return
	}
	SuccessToast(`Le chantier "${body.constructionName}" a bien été crée`);
	history.goBack()
}

const NewConstruction = () => {
	const [body, setBody] = useState({constructionCode: null, constructionName: null, agency: [], activity: null, client: null, adress: null, zipcode: null, city: null, responsableBu: null, responsableExplotation: null, preventeur: null, responsableEncadrement: null, responsableMaitrise: null, global: false})
	const [agencies, setAgencies] = useState([{activity: null, agency: null}])
	const [responsableBuArray, setResponsableBuArray] = useState([])
	const [responsableExplotationArray, setResponsableExplotationArray] = useState([])
	const [preventeurArray, setPreventeurArray] = useState([])
	const [responsableEncadrementArray, setResponsableEncadrementArray] = useState([])
	const [responsableMaitriseArray, setResponsableMaitriseArray] = useState([])
	const [error, setAnError] = useState({message: null, error: false, type: ""})
	const { user } = useContext(UserContext);

	const getAgencies = async () => {
		const data = await getAuthorizedAgencies(user)
		setAgencies(data)
	};

	useEffect(() => {
		getAgencies()
		getUserFromRoles("Responsable BU", setResponsableBuArray)
		getUserFromRoles("Responsable d'Exploitation", setResponsableExplotationArray)
		getUserFromRoles("Préventeur.ice", setPreventeurArray)
		getUserFromRoles("Responsable Encadrement", setResponsableEncadrementArray)
		getUserFromRoles("Responsable Maîtrise", setResponsableMaitriseArray)
	}, []);

	return (
		<div>
			{user && user.rules.rules === 'Maîtrise' || user.rules.rules === 'Utilisateurs application' ? (<h1>Vous n'avez pas les droits pour créer un nouveau chantier...</h1>) : (<>
			<div class={`notification ${error.type}`}  style={{visibility:  error.message === null ? 'hidden' : 'visible', marginBottom: 0}}>
				<button class="delete" onClick={() => setAnError({message: null, error: false, type: ""})}></button>
				<p>{error.message}</p>
			</div>
			<BackButton />
			<div className="columns is-centered">
				<div className="column is-12">
					<h1 className="has-text-weight-bold	is-size-1">Nouveau chantier</h1>
					<h2 className="has-text-weight-semi-bold is-size-5">Complétez les différents champs afin de créer un nouveau chantier</h2>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Code chantier<span style={{color: 'red'}}>*</span></p>
				</div>
				<div className="column is-3">
					<input className="input"  style={{textTransform: "uppercase"}}  onChange={(event) => setBody({...body, constructionCode: event.target.value.toUpperCase().replaceAll(" ", "")})} type="text" placeholder="Code chantier"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Nom du chantier<span style={{color: 'red'}}>*</span></p>
				</div>
				<div className="column is-3">
					<input className="input" onChange={(event) => setBody({...body, constructionName: event.target.value})} type="text" placeholder="Nom du chantier"></input>
				</div>
			</div>
			{user?.rules.rules === 'Direction groupe + RQSE' && (
				<div className="columns is-centered">
					<div className="column is-3" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
						<p className="has-text-right">Groupe ETPO</p>
					</div>
					<div className="column is-3">
						<div className="is-rounded has-text-left">
							<Checkbox
								onChange={(event) => setBody({...body, global: event.target.checked, agency: []})}
							/>
						</div>
					</div>
				</div>
			)}
			{!body.global && (
				<div className="columns is-centered">
					<div className="column is-3">
						<p className="has-text-right">Agence(s)/Filiale(s)<span style={{color: 'red'}}>*</span></p>
					</div>
					<div className="column is-3">
						<div className=" is-rounded">
							<Select
								onChange={(event) => {setBody({...body, agency: event.map((value) => value.agency), activity: event.map((value) => value.activity)})}}
								defaultValue={[]}
								isMulti
								name="colors"
								options={agencies.map((value) => {
									return {value, label: value.name, agency: value.name, activity: value.activity}
								})}
								className="basic-multi-select"
								classNamePrefix="Oui"
							/>
						</div>
					</div>
				</div>
			)}
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Client</p>
				</div>
				<div className="column is-3">
					<input className="input" onChange={(event) => setBody({...body, client: event.target.value})} type="text" placeholder="Client"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Adresse du chantier</p>
				</div>
				<div className="column is-3">
					<input className="input" onChange={(event) => setBody({...body, adress: event.target.value})} type="text" placeholder="Adresse du chantier"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Code postal</p>
				</div>
				<div className="column is-3">
					<input className="input" onChange={(event) => setBody({...body, zipcode: event.target.value})} type="text" placeholder="Code postal"></input>
				</div>
			</div>

			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Ville</p>
				</div>
				<div className="column is-3">
					<input className="input" onChange={(event) => setBody({...body, city: event.target.value})} type="text" placeholder="Ville"></input>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Responsable BU<span style={{color: 'red'}}>*</span></p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(event) => {setBody({...body, responsableBu: event.map((value) => {
								return {id: value.id, fullName: value.label}
							})})}}
							defaultValue={[]}
							isMulti
							name="colors"
							options={responsableBuArray.map((value) => {
								return {value, label: `${value.firstName} ${value.lastName}`, id: value._id}
							})}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Responsable d'exploitation</p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(event) => {setBody({...body, responsableExplotation: event.map((value) => {
								return {id: value.id, fullName: value.label}
							})})}}
							defaultValue={[]}
							isMulti
							name="colors"
							options={responsableExplotationArray.map((value) => {
								return {value, label: `${value.firstName} ${value.lastName}`, id: value._id}
							})}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Préventeur/trice<span style={{color: 'red'}}>*</span></p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(event) => {setBody({...body, preventeur: event.map((value) => {
								return {id: value.id, fullName: value.label}
							})})}}
							defaultValue={[]}
							isMulti
							name="colors"
							options={preventeurArray.map((value) => {
								return {value, label: `${value.firstName} ${value.lastName}`, id: value._id}
							})}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Responsable encadrement</p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(event) => {setBody({...body, responsableEncadrement: event.map((value) => {
								return {id: value.id, fullName: value.label}
							})})}}
							defaultValue={[]}
							isMulti
							name="colors"
							options={responsableEncadrementArray.map((value) => {
								return {value, label: `${value.firstName} ${value.lastName}`, id: value._id}
							})}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<p className="has-text-right">Responsable maîtrise</p>
				</div>
				<div className="column is-3">
					<div className=" is-rounded">
						<Select
							onChange={(event) => {setBody({...body, responsableMaitrise: event.map((value) => {
								return {id: value.id, fullName: value.label}
							})})}}
							defaultValue={[]}
							isMulti
							name="colors"
							options={responsableMaitriseArray.map((value) => {
								return {value, label: `${value.firstName} ${value.lastName}`, id: value._id}
							})}
							className="basic-multi-select"
							classNamePrefix="Oui"
						/>
					</div>
				</div>
			</div>
			<div className="columns is-centered">
				<div className="column is-3">
					<Route render={({ history}) => (
						<button className="button is-success"onClick={() => handleCreationConstruction(history, body, setAnError)} >Créer un chantier</button>
					)}/>
				</div>
			</div></>)}
		</div>
	)
}

export default NewConstruction
