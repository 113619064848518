import React, { useRef } from 'react'
import 'antd/dist/antd.css'
// import Select from 'react-select'
// import Select from 'react-dropdown-select';
import { Select } from 'antd';
const { Option } = Select;


const getAgencyLabel = (agency) => {
	const agencyArray = []
	agency.map((value) => {
		agencyArray.push({label: value})
	})
	return agencyArray
}

const displayAllAgenciesAvailable = (valueDropdown, values) => {
	const found = valueDropdown.some(r=> values.indexOf(r.name) >= 0)
}

const addValue = (event, setState, object) => {
	const agency = object.agency
	const activity = object.activity
	agency.push(event.agency)
	activity.push(event.activity)
	setState({...object, agency, activity})

}

const clearValue = (object, setState) => {
	setState({...object, agency: [], activity: []})
}

const decideTypesAction = (value, setState, object) => {
	if (value.length === 0) clearValue(object, setState)
	else {

	}
}

const getDefaultValue = (defaultValue) => {
	const agency = defaultValue[0]
	const activity = defaultValue[1]
	const values = []
	for (let i = 0; i < agency.length; i++) {
		values.push([agency[i], activity[i]])
	}
	const tmp = []
	values.forEach((elem, index) => {
		// tmp.push(agency[index])
		tmp.push({value: elem, label: elem[0]})
	})
	return tmp
}

const InputAdvancedActivity = ({labelTitle, defaultValue, setState, edit, object, valueDropdown, cpy}) => {
	return (
		<div className="columns">
			<div className="column" style={{alignSelf: "center"}}>
				<p>{labelTitle}:</p>
			</div>
			<div className="column">
				<Select
					mode="multiple"
					disabled={!edit}
					allowClear
					style={{ width: '100%' }}
					placeholder="Please select"
					
					labelInValue={true}
					optionFilterProp="label"
					// defaultValue={defaultValue}
					defaultValue={getDefaultValue(defaultValue)}
					onChange={(value) =>{
						decideTypesAction(value, setState, object)
					}}>
						{valueDropdown.map((elem, index) => {
							const test = []
							test.push(elem.name)
							test.push(elem.activity)
							return <Option key={index} value={test} label="test">{elem.name}</Option>
						})
					}
				</Select>
			</div>
		</div>
	)
}

export default InputAdvancedActivity