import React from 'react'

const AlertModal = ({labelModal, modalActivity, setModalActivity, firstAction}) => {
	return (
		<div className="modal" className={modalActivity ? "modal is-active" : "modal"}>
			<div className="modal-background"></div>
			<div className="modal-content"  style={{backgroundColor: 'white', height: "auto", borderRadius: 5}}>
				<p style={{marginTop: 25, marginBottom: 25}}>{labelModal}</p>
				<footer className="modal-card-foot">
					<button className="button is-danger" onClick={() => firstAction()}>Supprimer</button>
					<button className="button" onClick={() => setModalActivity(false)}>Annuler</button>
    			</footer>
			</div>
				<button className="modal-close is-large" aria-label="close" onClick={() => setModalActivity(false)}></button>
		</div>
	)
}

export default AlertModal