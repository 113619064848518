import React, { useEffect, useState } from 'react'
import { fetchSingleConstructionSiteAPI, getCurrentAgenciesAPI, getUserFromRolesAPI, toggleConstructionSiteActivityAPI, updateConstructionSiteAPI } from '../API'
import BackButton from '../components/backButton'
import Header from '../components/header/header'
import InputAdvanced from '../components/InputAdvanced'
import InputAdvancedRoles from '../components/inputAdvancedRoles'
import CheckBoxAdvancedActivity from '../components/checkBoxAdvancedActivity'
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import { Checkbox } from '@mui/material'

const fetchInfoConstructionSite = async (idNotification, setItem) => {
	const response = await fetchSingleConstructionSiteAPI(idNotification)
	setItem(response)
}

const toggleConstructionSiteActivity = async (id, activity) => {
	await toggleConstructionSiteActivityAPI(id, activity)
	window.location.reload();
}

const getAgency = async (setAgency) => {
	const responseJson = await getCurrentAgenciesAPI()
	responseJson.sort((a, b) => a.name > b.name ? 1 : -1);
	setAgency(responseJson)
}

const updateData = async (data, id, setEdit) => {
	console.log('updateData')
	console.log(data)
	data.id = id
	await updateConstructionSiteAPI(data)
	setEdit(false)
}

const getUserFromRoles = async (roles, setData) => {
	const responseJson = await getUserFromRolesAPI(roles)
	if (responseJson.length === 0) setData([])
	else setData(responseJson)
}

const SelectedConstructionSite = (props) => {
	const [item, setItem] = useState(null)
	const [newItem, setNewItem] =  useState({})
	const params = new URLSearchParams(props.location.search)
	const idNotification = params.get('item')
	const [edit, setEdit] =  useState(false)
	const [responsableBuArray, setResponsableBuArray] = useState([])
	const [responsableExplotationArray, setResponsableExplotationArray] = useState([])
	const [preventeurArray, setPreventeurArray] = useState([])
	const [responsableEncadrementArray, setResponsableEncadrementArray] = useState([])
	const [responsableMaitriseArray, setResponsableMaitriseArray] = useState([])
	const [agency, setAgency]= useState(null)
	const { user } = useContext(UserContext);

	useEffect(() => {
		fetchInfoConstructionSite(idNotification, setItem)
		getUserFromRoles("Responsable BU", setResponsableBuArray )
		getUserFromRoles("Responsable d'Exploitation", setResponsableExplotationArray)
		getUserFromRoles("Préventeur.ice", setPreventeurArray)
		getUserFromRoles("Responsable Encadrement", setResponsableEncadrementArray)
		getUserFromRoles("Responsable Maîtrise", setResponsableMaitriseArray)
		getAgency(setAgency)
	}, []);

	if (item === null || agency === null) return (<div><Header /><p>Chargement ...</p></div>)
	return (
		<div>
			<Header />
			{user?.rules.rules != "Utilisateurs application" && user?.rules.rules != "Maîtrise" ? (
        <>
			<BackButton />
			<div className="columns" style={{marginTop: "5%" }}>
				<div className="column is-4 is-offset-1" style={{marginBottom: "50px"}}>
					<InputAdvanced labelTitle="Code chantier" value={item.constructionCode} onChange={(event) => setNewItem({...newItem, constructionCode: event.target.value})} edit={false} />
					<InputAdvanced labelTitle="Nom du chantier" value={item.constructionName} onChange={(event) => setNewItem({...newItem, constructionName: event.target.value})} edit={edit} />
					<InputAdvanced labelTitle="Client" value={item.client} onChange={(event) => setNewItem({...newItem, client: event.target.value})} edit={edit} />
					<InputAdvanced labelTitle="Adresse" value={item.adress} onChange={(event) => setNewItem({...newItem, adress: event.target.value})} edit={edit} />
					<InputAdvanced labelTitle="Code Postal" value={item.zipcode} onChange={(event) => setNewItem({...newItem, zipcode: event.target.value})} edit={edit} />
					<InputAdvanced labelTitle="Ville" value={item.city} onChange={(event) => setNewItem({...newItem, city: event.target.value})} edit={edit} />
					<hr></hr>
					<InputAdvancedRoles
						label="Responsable BU"
						defaultValue={item.responsableBu}
						values={responsableBuArray}
						enabled={edit}
						onChange={(value) => setNewItem({...newItem, responsableBu: value})}
					/>
					<InputAdvancedRoles
						label="Responsable d'Exploitation"
						defaultValue={item.responsableExplotation}
						values={responsableExplotationArray}
						enabled={edit}
						onChange={(value) => setNewItem({...newItem, responsableExplotation: value})}
					/>
					<InputAdvancedRoles
						label="Préventeur.ice"
						defaultValue={item.preventeur}
						values={preventeurArray}
						enabled={edit}
						onChange={(value) => setNewItem({...newItem, preventeur: value})}
					/>
					<InputAdvancedRoles
						label="Responsable Encadrement"
						defaultValue={item.responsableEncadrement}
						values={responsableEncadrementArray}
						enabled={edit}
						onChange={(value) => setNewItem({...newItem, responsableEncadrement: value})}
					/>
					<InputAdvancedRoles
						label="Responsable Maîtrise"
						defaultValue={item.responsableMaitrise}
						values={responsableMaitriseArray}
						enabled={edit}
						onChange={(value) => setNewItem({...newItem, responsableMaitrise: value})}
					/>
					<hr></hr>
					{(!edit || user?.rules.rules === 'Direction groupe + RQSE') && (
						<div className="columns">
							<div className="column is-3" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
								<p className="has-text-right">Groupe ETPO</p>
							</div>

							<div className="column is-3">
								<div className="is-rounded has-text-left">
									<Checkbox
										checked={newItem?.global != null ? newItem?.global : item?.global}
										disabled={!edit}
										onChange={(event) => setNewItem({ ...newItem, global: event.target.checked, agency: [] })}
									/>
								</div>
							</div>
						</div>
					)}
					{!(newItem?.global != null ? newItem?.global : item?.global) && (
						<CheckBoxAdvancedActivity valueDropdown={agency} defaultValue={{agency: item.agency, activity: item.activity}} edit={!edit} setState={setNewItem} object={newItem}/>
					)}
				</div>
				<div className="columns">
					</div>
					<div className="column is-6">
						<div className="columns">
							<div className="column is-2 is-offset-4">
								{!edit ?
									<div className="column is-12">
										<button class="button is-info" onClick={() => setEdit(true)}><span>Editer</span><span class="icon is-small"><i class="fas fa-pen"></i></span></button>
									</div>
								: <div>
									<div className="column is-12">
										<button class="button is-danger" onClick={() => setEdit(false)}><span>Annuler</span><span class="icon is-small"><i class="fas fa-times"></i></span></button>
									</div>
									<div className="column is-12">
										<button class="button is-success" onClick={() => updateData(newItem, item._id, setEdit)}><span>Sauvegarder</span><span class="icon is-small"><i class="fas fa-check"></i></span></button>
									</div>
								</div>
								}
							</div>
							<div className="column is-4">
								<div className="column is-4">
									{
										item.isActive ? <button onClick={() => toggleConstructionSiteActivity(item._id, false)} className="button is-danger"> Désactiver</button>
										: <button onClick={() => toggleConstructionSiteActivity(item._id, true)} className="button is-success"> Activer</button>
									}
								</div>
							</div>
						</div>
				</div>
			</div>
			</>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
		</div>
	)
}

export default SelectedConstructionSite
