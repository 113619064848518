import React, { useContext, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DataGrid } from '@mui/x-data-grid'

import Header from '../components/header/header'
import UserContext from '../contexts/UserContext'
import { getNotifications } from '../NewAPI'
import { useDidMountEffect } from '../utils/hooks'

const Documents = () => {
  const { user } = useContext(UserContext)

  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)

  const [types, setTypes] = useState([])
  const [agencies, setAgencies] = useState([])
  const [activities, setActivities] = useState([])

  const [typeFilter, setTypeFilter] = useState(null)
  const [agencyFilter, setAgencyFilter] = useState(null)
  const [activityFilter, setActivityFilter] = useState(null)

  const retrieveSavedFilters = async () => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {}
    const notificationsFilters = filters?.notifications || {}
    setTypeFilter(notificationsFilters.type)
    setAgencyFilter(notificationsFilters.agency)
    setActivityFilter(notificationsFilters.activity)
  }

  const saveFilter = async (key, value) => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {}
    const notificationsFilters = filters?.notifications || {}
    if (value) notificationsFilters[key] = value
    else delete(notificationsFilters[key])
    filters.notifications = notificationsFilters
    localStorage.setItem('filters', JSON.stringify(filters))
  }

  const saveTypeFilter = async (value) => {
    setTypeFilter(value)
    saveFilter('type', value)
  }

  const saveAgencyFilter = async (value) => {
    setAgencyFilter(value)
    saveFilter('agency', value)
  }

  const saveActivityFilter = async (value) => {
    setActivityFilter(value)
    saveFilter('activity', value)
  }

  const fetchNotifications = async () => {
    setLoading(true)
    const response = await getNotifications()
    if (response.err !== null) {
      setData([])
    }

    // Add "GROUPE ETPO" agency for global entities
    const fixed = response.map((e) => {
      const data = { ...e, id: e._id }
      if (e.global) return { ...data, agency: ['GROUPE ETPO'] }
      return data
    })

    // Setup filters values
    let types = fixed.map((e) => e.type)
    types = [...new Set(types.flat())]
    types.sort()
    setTypes(types)
    let agencies = fixed.map((e) => e.agency)
    agencies = [...new Set(agencies.flat())]
    agencies.sort()
    setAgencies(agencies)
    let activities = fixed.map((e) => e.activity)
    activities = [...new Set(activities.flat())]
    activities.sort()
    setActivities(activities)

    setData(fixed)
    setLoading(false)
  }

  useEffect(() => {
    retrieveSavedFilters().then(() => {
      fetchNotifications()
    })
  }, [])

  useDidMountEffect(() => {
    const filtered = data.filter((e) => {
      return (!typeFilter || e.type === typeFilter)
        && (!agencyFilter || e.agency?.includes(agencyFilter))
        && (!activityFilter || e.activity?.includes(activityFilter))
    })
    setFilteredData(filtered)
  }, [data, typeFilter, agencyFilter, activityFilter])

  const columns = [
    { field: 'title', headerName: 'Titre', flex: 3 },
    { field: 'type', headerName: 'Type', flex: 1 },
    {
      field: 'agency',
      headerName: 'Agence(s) / Filiale(s)',
      flex: 1,
      valueGetter: (value) => value.value?.join(', '),
    },
    {
      field: 'creationDate', headerName: 'Date d\'ajout', flex: 1, valueGetter: (params) => {
        return new Date(params.value).toLocaleDateString('fr-FR')
      },
    },
    {
      field: ' ',
      headerName: ' ',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ width: '100%', display: 'inline-flex', alignItems: 'end', justifyContent: 'end' }}>
            <Route render={({ history }) => (
              <Button variant="contained" color="info" onClick={(e) => {
                history.push(
                  `/selectednotification?item=${params.row._id}`,
                )
              }}>Éditer</Button>
            )}/>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <Header/>
      {user?.rules.rules !== 'Utilisateurs application' && user?.rules.rules !== 'Maîtrise' ? (
        <>
          <div className="columns is-centered" style={{ marginTop: 44 }}>
            <div className="column is-12">
              <h1 className="has-text-weight-bold	is-size-1">
                Gestion des notifications
              </h1>
            </div>
          </div>

          <div style={{ display: 'flex', height: '100%', marginBottom: 40, marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Autocomplete
              disablePortal
              id="filter-types"
              options={types}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Type"/>}
              value={typeFilter}
              onChange={(event, value) => saveTypeFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-agencies"
              options={agencies}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Agence / Filiale"/>}
              value={agencyFilter}
              onChange={(event, value) => saveAgencyFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-activities"
              options={activities}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Métier"/>}
              value={activityFilter}
              onChange={(event, value) => saveActivityFilter(value)}
            />
          </div>

          <div style={{ width: '100%', display: 'inline-flex', marginLeft: 20,  marginBottom: 20 }}>
            {user && user?.rules.rules !== "Maîtrise" && (
              <Route
                render={({ history }) => (
                  <Button
                    variant="contained"
                    onClick={() => history.push("/newnotification")}
                  >
                    Créer une nouvelle notification
                  </Button>
                )}
              />
            )}
          </div>

          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                loading={loading}
                rows={filteredData}
                columns={columns}
                autoHeight={true}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
                disableColumnMenu
                experimentalFeatures={{ newEditingApi: true }}
              />
            </div>
          </div>
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  )
}

export default Documents
