import React, { useContext, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { saveAs } from 'file-saver'
import Promise from 'bluebird'

import dayjs from 'dayjs'
import 'dayjs/locale/fr'

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import DownloadIcon from '@mui/icons-material/Download'
import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'
import { DataGrid } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Header from '../components/header/header'
import ExportFormDataButton from '../components/export/exportFormDataButton'
import UserContext from '../contexts/UserContext'
import { getForms } from '../NewAPI'
import { useDidMountEffect } from '../utils/hooks'

const HistoryForm = () => {
  const { user } = useContext(UserContext)

  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)

  const [agencies, setAgencies] = useState([])
  const [constructionCodes, setConstructionCodes] = useState([])
  const [users, setUsers] = useState([])
  const [formNames, setFormNames] = useState([])

  const [agencyFilter, setAgencyFilter] = useState(null)
  const [constructionCodeFilter, setConstructionCodeFilter] = useState(null)
  const [userFilter, setUserFilter] = useState(null)
  const [formNameFilter, setFormNameFilter] = useState(null)
  const [beginDateFilter, setBeginDateFilter] = useState(dayjs(new Date(new Date().getFullYear(), 0, 1)))
  const [endDateFilter, setEndDateFilter] = useState(dayjs(new Date(new Date().getFullYear(), 11, 31)))

  const getFormNameFromId = (formId) => {
    switch (formId) {
      case 1:
        return 'Accueil nouveaux arrivants'
      case 2:
        return 'Autorisation de conduite temporaire'
      case 3:
        return 'Fiche de Sécurité (Plongée)'
      case 4:
        return 'Prise de Poste'
      case 5:
        return '1/4 QSE'
      case 6:
        return 'Presque Accident Situation Dangereuse'
      case 7:
        return 'Formation Spécifique au Poste de Travail'
      case 8:
        return 'Dialogue prévention'
      case 9:
        return 'Visite Sécurité Hebdomadaire'
      case 10:
        return 'Visite prévention'
      case 11:
        return 'Inspection réglementaire'
      case 12:
        return 'Journal de Chantier'
      case 13:
        return 'Feuille d\'attachement journalière'
      case 14:
        return 'Questionnaire de Satisfaction'
    }

    return ''
  }

  const retrieveSavedFilters = async () => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {}
    const formsFilters = filters?.forms || {}
    setAgencyFilter(formsFilters.agency)
    setConstructionCodeFilter(formsFilters.constructionCode)
    setUserFilter(formsFilters.user)
    setFormNameFilter(formsFilters.formName)
    const beginDate = formsFilters.beginDate
    setBeginDateFilter(beginDate ? dayjs(beginDate) : dayjs(new Date(new Date().getFullYear(), 0, 1)))
    const endDate = formsFilters.endDate
    setEndDateFilter(endDate ? dayjs(endDate) : dayjs(new Date(new Date().getFullYear(), 11, 31)))
  }

  const saveFilter = async (key, value) => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {}
    const formsFilters = filters?.forms || {}
    if (value) formsFilters[key] = value
    else delete(formsFilters[key])
    filters.forms = formsFilters
    localStorage.setItem('filters', JSON.stringify(filters))
  }

  const saveAgencyFilter = async (value) => {
    setAgencyFilter(value)
    saveFilter('agency', value)
  }

  const saveConstructionCodeFilter = async (value) => {
    setConstructionCodeFilter(value)
    saveFilter('constructionCode', value)
  }

  const saveUserFilter = async (value) => {
    setUserFilter(value)
    saveFilter('user', value)
  }

  const saveFormNameFilter = async (value) => {
    setFormNameFilter(value)
    saveFilter('formName', value)
  }

  const saveBeginDateFilter = async (value) => {
    setBeginDateFilter(value)
    saveFilter('beginDate', value)
  }

  const saveEndDateFilter = async (value) => {
    setEndDateFilter(value)
    saveFilter('endDate', value)
  }

  const fetchForms = async () => {
    setLoading(true)
    const beginDate = beginDateFilter.format('YYYY-MM-DD')
    const endDate = endDateFilter.add(1, 'day').format('YYYY-MM-DD')
    const response = await getForms(null, null, null, null, beginDate, endDate)
    if (response.err !== null) {
      setData([])
    }

    // Add more info to form data
    const fixed = response.map((e) => {
      const data = { ...e, id: e._id, formName: getFormNameFromId(e.formId) }
      if (e.global) return { ...data, agency: ['GROUPE ETPO'] }
      return data
    })

    // Setup filters values
    let agencies = fixed.map((e) => e.agency)
    agencies = [...new Set(agencies.flat())]
    agencies.sort()
    setAgencies(agencies)
    let constructionCodes = fixed.map((e) => e.constructionCode)
    constructionCodes = [...new Set(constructionCodes.flat())]
    constructionCodes.sort()
    setConstructionCodes(constructionCodes)
    let users = fixed.map((e) => {
      return { label: `${e.user.firstName} ${e.user.lastName}`, id: e.user._id }
    })
    users = [...new Map(users.map(item => [item.id, item])).values()]
    users.sort((a, b) => (a.label > b.label) ? 1 : -1)
    setUsers(users)
    let formNames = fixed.map((e) => {
      return { label: e.formName, id: e.formId }
    })
    formNames = [...new Map(formNames.map(item => [item.id, item])).values()]
    formNames.sort((a, b) => (a.label > b.label) ? 1 : -1)
    setFormNames(formNames)

    setData(fixed)
    setLoading(false)
  }

  const exportForms = async () => {
    const forms = filteredData
    const formNameArray = []
    setExportLoading(true)
    const JSZip = require('jszip')
    const zip = new JSZip()

    const download = (url) => {
      return fetch(url).then((resp) => resp.blob())
    }

    const downloadByGroup = (urls) => {
      return Promise.map(urls, async (url) => {
        return await download(url)
      })
    }

    const exportZip = (blobs) => {
      blobs.forEach((blob, i) => {
        let formName = ''
        const formId = forms[i].formId
        if (formId === 1) formName = 'Accueil_Nouveaux_Arrivants_'
        if (formId === 2) formName = 'Autorisation_de_Conduite_'
        if (formId === 3) formName = 'FichedeSécurité_'
        if (formId === 4) formName = 'Prise_de_Poste_'
        if (formId === 5) formName = '1_4H_QSE_'
        if (formId === 6) formName = 'Presque_Accident_Situation_Dangereuse_'
        if (formId === 7) formName = 'Formation_Spécifique_au_Poste_de_Travail_'
        if (formId === 8) formName = 'Dialogue_Prévention_'
        if (formId === 9) formName = 'Visite_Sécurité_Hebdomadaire_'
        if (formId === 50) formName = 'Visite_Prévention_'
        if (formId === 11) formName = 'Inspection_Réglementaire_'
        if (formId === 12) formName = 'Journal_de_Chantier_'
        if (formId === 13) formName = 'Feuille_d\'attachement_journalière_'
        if (formId === 14) formName = 'Questionnaire_de_Satisfaction_'

        let formDate = forms[i].creationDate.substring(0, 50)
        formDate = formDate.replace(/-/g, '')
        formName = `${formName}${forms[i].constructionCode}_${formDate}.pdf`
        formName = formName.replace(/-/g, '_')
        if (formNameArray.includes(formName)) {
          formName = formName.replace('.pdf', `_${i}.pdf`)
        }
        formNameArray.push(formName)

        zip.file(formName, blob)
      })

      zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        const fileName = `Formulaires-${dayjs(new Date()).format('DD/MM/YYYY')}.zip`
        setExportLoading(false)
        return saveAs(zipFile, fileName)
      })
    }

    const downloadAndZip = () => {
      const formsUrl = filteredData.map((e) => e.pdfUrl)
      return downloadByGroup(formsUrl).then(exportZip)
    }

    downloadAndZip()
  }

  useEffect(() => {
    retrieveSavedFilters()
  }, [])

  useDidMountEffect(() => {
    fetchForms()
  }, [beginDateFilter, endDateFilter])

  useDidMountEffect(() => {
    const filtered = data.filter((e) => {
      return (!agencyFilter || e.agency.includes(agencyFilter))
        && (!constructionCodeFilter || e.constructionCode === constructionCodeFilter)
        && (!userFilter || e.user._id === userFilter?.id)
        && (!formNameFilter || e.formId === formNameFilter?.id)
    })
    setFilteredData(filtered)
  }, [data, agencyFilter, constructionCodeFilter, userFilter, formNameFilter])

  const columns = [
    {
      field: 'agency',
      headerName: 'Agence(s) / Filiale(s)',
      flex: 1,
      valueGetter: (value) => value.value?.join(', '),
    },
    {
      field: 'activity',
      headerName: 'Activité',
      flex: 1,
      valueGetter: (value) => value.value?.join(', '),
    },
    { field: 'constructionCode', headerName: 'Chantier', flex: 1 },
    { field: 'formName', headerName: 'Formulaire', flex: 1 },
    {
      field: 'user', headerName: 'Salarié', flex: 1, valueGetter: (params) => {
        return `${params.value.firstName} ${params.value.lastName}`
      },
    },
    {
      field: 'creationDate', headerName: 'Date', flex: 1, valueGetter: (params) => {
        return new Date(params.value).toLocaleDateString('fr-FR')
      },
    },
    {
      field: ' ',
      headerName: ' ',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ width: '100%', display: 'inline-flex', alignItems: 'end', justifyContent: 'end', gap: 10 }}>
            <Route render={({ history }) => (
              <Button variant="contained" color="success" onClick={(e) => {
                window.open(params.row.pdfUrl)
              }}>Visualiser</Button>
            )}/>
            <Route render={({ history }) => (
              <Button variant="contained" color="info" onClick={(e) => {
                history.push(
                  `/selectedconstructionsite?item=${params.row._id}`,
                )
              }}>Éditer</Button>
            )}/>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <Header/>
      {user?.rules.rules !== 'Utilisateurs application' && user?.rules.rules !== 'Maîtrise' ? (
        <>
          <div className="columns is-centered" style={{ marginTop: 44 }}>
            <div className="column is-12">
              <h1 className="has-text-weight-bold	is-size-1">
                Historique des formulaires
              </h1>
            </div>
          </div>

          <div style={{
            display: 'flex',
            height: '100%',
            marginBottom: 40,
            marginTop: 20,
            alignItems: 'center',
            justifyContent: 'center',
            gap: 20,
          }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Date de début"
                value={beginDateFilter}
                onChange={(newValue) => saveBeginDateFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Date de fin"
                value={endDateFilter}
                onChange={(newValue) => saveEndDateFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <div style={{
            display: 'flex',
            height: '100%',
            marginBottom: 40,
            marginTop: 20,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Autocomplete
              disablePortal
              id="filter-agencies"
              options={agencies}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Agence / Filiale"/>}
              value={agencyFilter}
              onChange={(event, value) => saveAgencyFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-agencies"
              options={constructionCodes}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Code chantier"/>}
              value={constructionCodeFilter}
              onChange={(event, value) => saveConstructionCodeFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-users"
              options={users}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Utilisateur"/>}
              value={userFilter}
              onChange={(event, value) => saveUserFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-activities"
              options={formNames}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Formulaire"/>}
              value={formNameFilter}
              onChange={(event, value) => saveFormNameFilter(value)}
            />
          </div>

          <div style={{ width: '100%', display: 'inline-flex', marginLeft: 20, marginBottom: 20, gap: 10 }}>
            <LoadingButton
              variant="contained"
              loading={exportLoading}
              onClick={() => exportForms()}
            >
              <DownloadIcon style={{ marginRight: 8 }}/>
              Exporter les PDFs
            </LoadingButton>
            <ExportFormDataButton
              beginDate={beginDateFilter}
              endDate={endDateFilter}
              agency={agencyFilter}
              constructionCode={constructionCodeFilter}
              user={userFilter}
              formName={formNameFilter}
            />
          </div>

          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                loading={loading}
                rows={filteredData}
                columns={columns}
                autoHeight={true}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
                disableColumnMenu
                experimentalFeatures={{ newEditingApi: true }}
              />
            </div>
          </div>
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  )
}

export default HistoryForm
