import React, { useEffect, useState } from 'react'
import Header from '../components/header/header'
import  "firebase/storage"
import {storage} from '../firebase/index'
import { Route } from 'react-router-dom'
import { deleteDocumentAPI, getDocumentAPI, updateDocumentAPI } from '../API'
import AlertModal from '../components/alertModal'
import BackButton from '../components/backButton'
import InputAdvanced from '../components/InputAdvanced'
import CheckBoxAdvancedActivity from '../components/checkBoxAdvancedActivity'
import SuccessToast from "../components/SuccessToast";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import { getAuthorizedAgencies } from '../NewAPI'
import { Checkbox } from '@mui/material'

const getDocument = async (id, setItem) => {
	const responseJson = await getDocumentAPI(id)
	if (responseJson.error != null) return
	setItem(responseJson)
}

const removeItemFromDatabase = async (id) => {
	await deleteDocumentAPI(id);
	SuccessToast("Document supprimé !");
}

const updateData = async (data, id, setEdit) => {
	data.id = id
	await updateDocumentAPI(data)
	setEdit(false)
	SuccessToast("Document modifié !");
}

const removeItem = (item, history) => {
	removeItemFromDatabase(item._id)
	if (item.documentURL === null) return history.push('/documents')
	const document = storage.refFromURL(item.documentURL);
	document.delete().then(() => {
		history.goBack()
		}).catch((error) => {
			return history.push('/documents')
			console.log(error)
	});
}

const SelectedDocument = (props) => {
	const [item, setItem] =  useState(null)
	const [newItem, setNewItem] =  useState({})
	const params = new URLSearchParams(props.location.search)
	const idNotification = params.get('item')
	const [modalActivity, setModalActivity] = useState(false)
	const [edit, setEdit] =  useState(false)
	const [agencies, setAgencies] = useState([]);
	const { user } = useContext(UserContext);

	const getAgencies = async () => {
		const data = await getAuthorizedAgencies(user)
		setAgencies(data)
	}

	useEffect(() => {
		getDocument(idNotification, setItem)
		getAgencies()
	}, [])

	if (!item || !agencies?.length) return <div>Chargement ...</div>

	return (
    <div>
      <Header />
      {user?.rules.rules !== "Utilisateurs application" && user?.rules.rules !== "Maîtrise" ? (
        <>
          <BackButton />
          <div className="columns" style={{ marginTop: "5%" }}>
            <Route
              render={({ history }) => (
                <AlertModal
                  labelModal="Êtes-vous sur de vouloir supprimer ce document ?"
                  setModalActivity={setModalActivity}
                  modalActivity={modalActivity}
                  firstAction={() => removeItem(item, history)}
                />
              )}
            />
            <div
              className="column is-4 is-offset-0"
              style={{ marginBottom: "50px" }}
            >
              <InputAdvanced
                labelTitle="Titre du document"
                value={item?.title}
                onChange={(event) =>
                  setNewItem({ ...newItem, title: event.target.value })
                }
                edit={edit}
              />

							{(!edit || user?.rules.rules === 'Direction groupe + RQSE') && (
								<div className="columns">
									<div className="column is-3" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
										<p className="has-text-right">Groupe ETPO</p>
									</div>

									<div className="column is-3">
										<div className="is-rounded has-text-left">
											<Checkbox
												checked={newItem?.global != null ? newItem?.global : item?.global}
												disabled={!edit}
												onChange={(event) => setNewItem({ ...newItem, global: event.target.checked, agency: [] })}
											/>
										</div>
									</div>
								</div>
							)}

							{!(newItem?.global != null ? newItem?.global : item?.global) && (
								<CheckBoxAdvancedActivity
									valueDropdown={agencies}
									defaultValue={{ agency: item.agency, activity: item.activity }}
									edit={!edit}
									setState={setNewItem}
									object={item}
								/>
							)}
            </div>
            <div className="column is-offset-4">
              <div className="columns">
                <div className="column is-2">
                  {!edit ? (
                    <div className="column is-12">
                      <button
                        class="button is-info"
                        onClick={() => setEdit(true)}
                      >
                        <span>Editer</span>
                        <span class="icon is-small">
                          <i class="fas fa-pen"></i>
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="column is-12">
                        <button
                          class="button is-danger"
                          onClick={() => setEdit(false)}
                        >
                          <span>Annuler</span>
                          <span class="icon is-small">
                            <i class="fas fa-times"></i>
                          </span>
                        </button>
                      </div>
                      <div className="column is-12">
                        <button
                          class="button is-success"
                          onClick={() => updateData(newItem, item._id, setEdit)}
                        >
                          <span>Sauvegarder</span>
                          <span class="icon is-small">
                            <i class="fas fa-check"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="column is-4">
                  <div className="column is-12">
                    <button
                      className="button is-danger"
                      onClick={() => setModalActivity(true)}
                    >
                      {" "}
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
              <div className="column is-6">
                <p>
                  <strong>Catégorie: </strong>
                  {item?.category}
                </p>
                <p>
                  <strong>Sous-catégorie: </strong>
                  {item?.subCategory}
                </p>
                <a
                  href={item?.documentURL}
                  target="_blank"
                  style={styles.imgDocument}
                  className="button is-white"
                >
                  <img src={item?.documentURL} />
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  );
}

const styles = {
	imgDocument: {
		height: 200,
		width: 200,
		borderRadius: 10,
		overflow:"hidden",
	}
}

export default SelectedDocument
