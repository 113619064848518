import React, { useContext, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid'

import Header from '../components/header/header'
import UserContext from '../contexts/UserContext'
import { getAgencies } from '../NewAPI'

const Documents = () => {
	const { user } = useContext(UserContext)

	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)

	const fetchAgencies = async () => {
		setLoading(true)
		const response = await getAgencies()
		if (response.err !== null) {
			setData([])
		}

		// Map id properly for data table
		const fixed = response.map((e) => ({ ...e, id: e._id }))
		setData(fixed)

		setLoading(false)
	}

	useEffect(() => {
		fetchAgencies()
	}, [])

	const columns = [
		{ field: 'name', headerName: 'Agence', flex: 1 },
		{ field: 'activity', headerName: 'Activité', flex: 1 },
		{
			field: 'date', headerName: 'Date de création', flex: 1, valueGetter: (params) => {
				return new Date(params.value).toLocaleDateString('fr-FR')
			},
		},
		{
			field: 'isActive', headerName: 'Compte activé ?', flex: 1, valueGetter: (params) => {
				return params.value ? 'Oui' : 'Non'
			},
		},
		{
			field: ' ',
			headerName: ' ',
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<div style={{ width: '100%', display: 'inline-flex', alignItems: 'end', justifyContent: 'end' }}>
						<Route render={({ history }) => (
							<Button variant="contained" color="info" onClick={(e) => {
								history.push(
									`/selectedagency?item=${params.row._id}`,
								)
							}}>Éditer</Button>
						)}/>
					</div>
				)
			},
		},
	]

	return (
		<div>
			<Header/>
			{user?.rules.rules !== 'Utilisateurs application' && user?.rules.rules !== 'Maîtrise' ? (
				<>
					<div className="columns is-centered" style={{ marginTop: 44 }}>
						<div className="column is-12">
							<h1 className="has-text-weight-bold	is-size-1">
								Gestion des agences / filiales
							</h1>
						</div>
					</div>

					<div style={{ width: '100%', display: 'inline-flex', marginLeft: 20,  marginBottom: 20 }}>
						{user && user?.rules.rules !== "Maîtrise" && (
							<Route
								render={({ history }) => (
									<Button
										variant="contained"
										onClick={() => history.push("/newagency")}
									>
										Créer une nouvelle agence ou filiale
									</Button>
								)}
							/>
						)}
					</div>

					<div style={{ display: 'flex', height: '100%' }}>
						<div style={{ flexGrow: 1 }}>
							<DataGrid
								loading={loading}
								rows={data}
								columns={columns}
								autoHeight={true}
								pageSize={50}
								rowsPerPageOptions={[50]}
								disableSelectionOnClick
								disableColumnMenu
								experimentalFeatures={{ newEditingApi: true }}
							/>
						</div>
					</div>
				</>
			) : (
				<h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
			)}
		</div>
	)
}

export default Documents
