import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useFetch from "../hooks/useFetch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import NewExpected from "./newexpected";
import { createYears, existingMonths } from "../utils/dates";
import SuccessToast from "../components/SuccessToast";
import ErrorToast from "../components/ErrorToast";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "33%",
    position: "relative",
    overflow: "auto",
    backgroundColor: "#fcfcfa",
    paddingLeft: 15
  },
  root2: {
    width: "100%",
    backgroundColor: "#fcfcfa",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  divStyle: {
    display: "flex",
    paddingTop: 10,
    height: "82vh",
  },
  formDiv: {
    width: "33%",
    marginTop: -10,
  },
  formDivIndicators: {
    width: "33%",
    marginTop: -10,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "39%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formInput: {
    display: "flex",
    height: 26,
    width: "100%",
    justifyContent: "space-between",
    padding: -10,
  },
  newExpected: {
    marginTop: 10,
  },
  indicator: {
    marginRight: 10,
    fontSize: 12,
  },
  inputIndicator: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 0.1,
    borderColor: "grey",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    marginTop: 0,
  },
  titleExpected: {
    marginBottom: 10,
    marginTop: 0,
  },
  paper: {
    position: "relative",
    width: "36%",
    backgroundColor: theme.palette.background.paper,
    border: "0.2px solid #000",
    boxShadow: theme.shadows[5],
    marginTop: 75,
    minHeight: "88vh",
    marginLeft: "32%",
    padding: 8,
  },
}));

const Expected = () => {
  const existingYears = createYears();
  const { get, put, post, patch } = useFetch();
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState({});
  const [month, setMonth] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [monthDuplicate, setMonthDuplicate] = useState("");
  const [yearDuplicate, setYearDuplicate] = useState(new Date().getFullYear());
  const [indicators, setIndicators] = useState([]);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [toReload, setToReload] = useState(false);
  const [existingExpected, setExistingExpected] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [selectedExpected, setSelectedExpected] = useState({});
  const [countInput, setCountInput] = useState(0);
  const [canEdit, setCanEdit] = useState(false);
  const { user } = useContext(UserContext);
  const [openDuplicateExpected, setOpenDuplicateExpected] = React.useState(false);

  const handleOpenDuplicateExpected = (selectedExpected) =>
    setOpenDuplicateExpected(true);
  const handleCloseDuplicateExpected = () => setOpenDuplicateExpected(false);



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handleChange = (event) => {
    setYear(event.target.value);
    setYearDuplicate(event.target.value);
  };

  const handleChangeMonthDuplicate = (event) => {
    setMonthDuplicate(event.target.value);
  };

  const handleChangeYearDuplicate = (event) => {
    setYearDuplicate(event.target.value);
  };

  const reload = () => {
    setToReload(!toReload);
    setMonth("");
    setAgency({});
  };

  const handleChangeExpected = (e, indicator) => {
    setCountInput(countInput + 1);
    setInputs(
      [].concat.apply(
        [],
        [
          ...selectedExpected.indicator.slice(
            0,
            selectedExpected.indicator.length
          ),
          inputs,
          { name: e.target.name, id: indicator.id, value: e.target.value },
        ]
      )
    );
  };

  const checkEditRule = () => {
    for (const rule in user.rules) {
      if (rule === "editDashboard" && user.rules[rule] === true) {
        setCanEdit(true);
      }
    }
  };

  const handleDuplicateExpected = async(selectedExpected, monthDuplicate, yearDuplicate) => {
    //console.log(monthDuplicate);
    //console.log(yearDuplicate);
    console.log(selectedExpected);
    const data = {
      agency: selectedExpected.agency,
      year: yearDuplicate,
      month: monthDuplicate,
      indicator: selectedExpected.indicator,
    };
    await post("expected/duplicateExpected/", data);
    reload();
    handleCloseDuplicateExpected();

    //  SuccessToast("Attendu modifié !");
    //  setInputs([]);
    //  handleClose();

  }

  const handleSubmitUpdateExpected = async (e) => {
    function compare(a, b) {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    }
    e.preventDefault();
    inputs.sort(compare);
    const indicators = inputs
      .slice()
      .reverse()
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    const data = {
      agency: agency._id,
      year: year,
      month: month,
      indicator: indicators,
    };
    if (inputs.length === 0) {
      ErrorToast("Vous n'avez rien modifié");
    } else {
      await put("expected/updateexpected/", data);
      SuccessToast("Attendu modifié !");
      setInputs([]);
      handleClose();
      reload();
    }
  };

  useEffect(() => {
    const getAgencies = async () => {
      let finalAgencies = []
      const data = await get("agency");
      if (user && user?.rules.rules === 'Direction groupe + RQSE')
      {
        data.map(agency => finalAgencies.push(agency))
      }
      else {
        for (const agency in data) {
          for (const key in user.agency)
          if (data[agency].name === user.agency[key]) {
            finalAgencies.push(data[agency])
          }
        }
    }
    setAgencies(finalAgencies);
    };

    const getIndicators = async () => {
      const data = await get("indicators");
      setIndicators(data);
    };

    getIndicators();
    getAgencies();
    checkEditRule();
  }, []);

  useEffect(() => {
    checkEditRule();
  }, [user]);

  useEffect(() => {
    const getExpected = async () => {
      const data = await get(
        `expected/agency?year=${year}&agencyId=${agency._id}`
      );
      setExistingExpected(data);
    };
    getExpected();
  }, [agency, year]);

  useEffect(() => {
    setInputs([]);
  }, [month, agency]);

  useEffect(() => {
    setMonth("");
  }, [agency]);

  const selectExpectedByMonth = (month, year) => {
    setMonth(month);
    setMonthDuplicate(month)
    setShow(true);
    const data = existingExpected.find(
      (expected) => expected.month === month && expected.year === year
    );
    setSelectedExpected(data);
  };

  const classes = useStyles();

  const body = (
    <div className={classes.paper}>
      <NewExpected agency={agency} handleClose={handleClose} reload={reload} />
    </div>
  );

  //console.log(canEdit)
  console.log(year)

  return (
    <div>
      <Header />
      {user?.rules.rules != "Maîtrise" &&
      user?.rules.rules != "Utilisateurs application" &&
      user?.rules.rules != "Encadrement" ? (
        <>
          <div className="columns is-centered" style={{ marginTop: 44 }}>
            <div className="column is-12">
              <h1 className="has-text-weight-bold	is-size-1">
                Gestion des attendus
              </h1>
            </div>
          </div>
          {Object.entries(agency).length > 0 &&
            (user?.rules.rules === "Direction groupe + RQSE" ||
              user?.rules.rules === "Direction BU + QSE") && (
              <button
                style={{
                  backgroundColor: "#cccccc",
                  color: "white",
                  fontStyle: "italic",
                  fontWeight: 500,
                }}
                class="button"
                onClick={handleOpen}
              >
                Créer un nouvel attendu
              </button>
            )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
          >
            {body}
          </Modal>
          <div className={classes.divStyle}>
            <List
              className={classes.root}
              subheader={
                <h1>
                  <abbr
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Agence(s)/Filiale(s)
                  </abbr>
                </h1>
              }
            >
              {agencies.map((agency) => (
                <li key={`section-${agency}`} className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListItem
                      button
                      key={`item-${agency.id}`}
                      onClick={() => setAgency(agency)}
                    >
                      <ListItemText>
                        <td>{agency.name}</td>
                      </ListItemText>
                    </ListItem>
                  </ul>
                </li>
              ))}
            </List>
            {Object.entries(agency).length > 0 && (
              <div className={classes.formDiv} style={{ marginTop: "8px" }}>
                <h1>
                  <abbr
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Attendu(s) pour l’agence/filiale {agency.name} pour l’année
                    :
                  </abbr>
                </h1>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={year}
                  onChange={handleChange}
                >
                  {existingYears.map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
                <List className={classes.root2}>
                  {existingMonths.map(
                    (month) =>
                      existingExpected &&
                      existingExpected.map(
                        (expected, i) =>
                          month === expected.month && (
                            <li
                              key={`section-${expected._id}`}
                              className={classes.listSection}
                            >
                              <ul className={classes.ul}>
                                <ListItem
                                  button
                                  key={`item-${expected._id}`}
                                  onClick={() =>
                                    selectExpectedByMonth(expected.month, year)
                                  }
                                >
                                  <ListItemText>
                                    <tr>{expected.month}</tr>
                                  </ListItemText>
                                  {(user?.rules.rules ===
                                    "Direction groupe + RQSE" ||
                                    user?.rules.rules ===
                                      "Direction BU + QSE") && (
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#cccccc",
                                        color: "white",
                                        fontWeight: 500,
                                      }}
                                      disableElevation
                                      className={classes.newExpected}
                                      //type="submit"
                                      onClick={() =>
                                        handleOpenDuplicateExpected(
                                          selectedExpected
                                        )
                                      }
                                    >
                                      Dupliquer
                                    </Button>
                                  )}
                                </ListItem>
                              </ul>
                            </li>
                          )
                      )
                  )}
                </List>
              </div>
            )}
            {month && (
              <div className={classes.formDivIndicators}>
                <h1>
                  <abbr
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Attendu - {month} {year}
                  </abbr>
                </h1>
                <form onSubmit={handleSubmitUpdateExpected} autoComplete="off">
                  {month &&
                    month.length > 0 &&
                    selectedExpected &&
                    selectedExpected.indicator.map((expected) => (
                      <div
                        className={classes.formInput}
                        style={{
                          border: "1px black solid",
                          margin: "5px 0",
                          padding: "0 0 0 3px",
                        }}
                      >
                        <tr className={classes.indicator}>{expected.group ? `${expected.group} -` : ''} {expected.name}</tr>
                        {inputs.length === 0 ? (
                          <input
                            className={classes.inputIndicator}
                            type="number"
                            placeholder={expected.value}
                            name={expected.name}
                            value={(e) =>
                              e.currentTarget.value
                                ? e.currentTarget.value
                                : expected.value
                            }
                            onChange={(e) => handleChangeExpected(e, expected)}
                          />
                        ) : (
                          <input
                            className={classes.inputIndicator}
                            type="number"
                            placeholder={expected.value}
                            name={expected.name}
                            onChange={(e) => handleChangeExpected(e, expected)}
                          />
                        )}
                      </div>
                    ))}
                  {(user?.rules.rules === "Direction groupe + RQSE" ||
                    user?.rules.rules === "Direction BU + QSE") && (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      className={classes.newExpected}
                      type="submit"
                      onClick={handleSubmitUpdateExpected}
                    >
                      Enregistrer
                    </Button>
                  )}

                  <Modal
                    open={openDuplicateExpected}
                    onClose={handleCloseDuplicateExpected}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2, marginBottom: 2 }}
                      >
                        Sélectionner le mois sur lequel dupliquer l’attendu :
                      </Typography>
                      <div>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={monthDuplicate}
                          onChange={handleChangeMonthDuplicate}
                        >
                          {existingMonths.map((monthDuplicate) => (
                            <MenuItem value={monthDuplicate}>
                              {monthDuplicate}
                            </MenuItem>
                          ))}
                        </Select>
                        {year !== 2022 && (
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={yearDuplicate}
                            onChange={handleChangeYearDuplicate}
                          >
                            {existingYears.map((yearDuplicate) => (
                              <MenuItem value={yearDuplicate}>
                                {yearDuplicate}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </div>
                      <Button
                        sx={{ mt: 2, marginBottom: 2 }}
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.newExpected}
                        //type="submit"
                        onClick={() =>
                          handleDuplicateExpected(
                            selectedExpected,
                            monthDuplicate,
                            yearDuplicate
                          )
                        }
                      >
                        Dupliquer
                      </Button>
                    </Box>
                  </Modal>
                </form>
              </div>
            )}
          </div>
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  );
};


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default Expected;
