import React, { useContext, useEffect, useState } from 'react'

import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

import Header from '../components/header/header'
import ExportDashboardDataButton from '../components/export/exportDashboardDataButton'
import Executed from './executed'
import { createYears, existingMonths } from '../utils/dates'
import UserContext from '../contexts/UserContext'
import { getAuthorizedAgencies, getConstructionSites, getDashboardData, getUsers } from '../NewAPI'

const Dashboard = () => {
  const { user } = useContext(UserContext)
  const years = createYears()
  years.sort()

  const [data, setData] = useState({ indicators: [], monthlyCompletion: [] })
  const [loading, setLoading] = useState(false)

  const [agencies, setAgencies] = useState([])
  const [fullAgencies, setFullAgencies] = useState([])
  const [activities, setActivities] = useState([])
  const [constructionCodes, setConstructionCodes] = useState([])
  const [users, setUsers] = useState([])

  const [year, setYear] = useState(new Date().getFullYear())
  const [agencyFilter, setAgencyFilter] = useState(null)
  const [activityFilter, setActivityFilter] = useState(null)
  const [constructionCodeFilter, setConstructionCodeFilter] = useState(null)
  const [userFilter, setUserFilter] = useState(null)

  const [editModal, setEditModal] = useState(false)

  const fetchFilters = async () => {
    const responses = await Promise.all([
      getAuthorizedAgencies(user),
      getConstructionSites(),
      getUsers(),
    ])
    const agenciesResponse = responses[0]
    if (agenciesResponse.err !== null) {
      setAgencies([])
      setActivities([])
    }
    const constructionSitesResponse = responses[1]
    if (constructionSitesResponse.err !== null) {
      setConstructionCodes([])
    }
    const usersResponse = responses[2]
    if (usersResponse.err !== null) {
      setUsers([])
    }

    let agencies = agenciesResponse.map((e) => {
      return { label: e.name, id: e._id }
    })
    agencies = [...new Map(agencies.map(item => [item.id, item])).values()]
    agencies.sort((a, b) => (a.label > b.label) ? 1 : -1)
    setAgencies(agencies)
    setFullAgencies(agenciesResponse)
    let activities = agenciesResponse.map((e) => e.activity)
    activities = [...new Set(activities.flat())]
    activities.sort()
    setActivities(activities)
    let constructionCodes = constructionSitesResponse.map((e) => e.constructionCode)
    constructionCodes = [...new Set(constructionCodes.flat())]
    constructionCodes.sort()
    setConstructionCodes(constructionCodes)
    let users = usersResponse.map((e) => {
      return { label: `${e.firstName} ${e.lastName}`, id: e._id }
    })
    users = [...new Map(users.map(item => [item.id, item])).values()]
    users.sort((a, b) => (a.label > b.label) ? 1 : -1)
    setUsers(users)
  }

  const fetchData = async () => {
    const response = await getDashboardData(year, agencyFilter?.id, activityFilter, constructionCodeFilter, userFilter?.id)
    if (response.err !== null) {
      setData({ indicators: [], monthlyCompletion: [] })
    }

    setData(response)
  }

  const toggleEditModal = () => {
    setEditModal(!editModal)
  }

  useEffect(() => {
    fetchFilters()
  }, [])

  useEffect(() => {
    setLoading(true)
    fetchData().then(() => {
      setLoading(false)
    })
  }, [year, agencyFilter, activityFilter, constructionCodeFilter, userFilter])

  return (
    <div>
      <Header/>
      {user?.rules.rules !== 'Utilisateurs application' ? (
        <>
          <div className="columns is-centered" style={{ marginTop: 44 }}>
            <div className="column is-12">
              <h1 className="has-text-weight-bold	is-size-1">
                Dashboard
              </h1>
            </div>
          </div>

          <div style={{
            display: 'flex',
            height: '100%',
            marginBottom: 40,
            marginTop: 20,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <FormControl style={{ marginRight: 20 }}>
              <InputLabel id="demo-simple-select-label">Année</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Année"
                value={year}
                onChange={(event) => setYear(event.target.value)}
              >
                {years.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Autocomplete
              disablePortal
              id="filter-agencies"
              options={agencies}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Agence / Filiale"/>}
              value={agencyFilter}
              onChange={(event, value) => setAgencyFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-activities"
              options={activities}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Métier"/>}
              value={activityFilter}
              onChange={(event, value) => setActivityFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-construction-codes"
              options={constructionCodes}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Code chantier"/>}
              value={constructionCodeFilter}
              onChange={(event, value) => setConstructionCodeFilter(value)}
            />
            <Autocomplete
              disablePortal
              id="filter-users"
              options={users}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Utilisateur"/>}
              value={userFilter}
              onChange={(event, value) => setUserFilter(value)}
            />
          </div>

          <div style={{ width: '100%', display: 'inline-flex', marginLeft: 20, gap: 10 }}>
            {(user?.rules.rules === 'Direction groupe + RQSE' || user?.rules.rules === 'Direction BU + QSE') && (
              <>
                <Button
                  variant="contained"
                  onClick={toggleEditModal}
                >
                  <EditIcon style={{ marginRight: 8 }}/>
                  Mode edition
                </Button>

                <Modal
                  open={editModal}
                  onClose={toggleEditModal}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Paper style={{ padding: 20, minWidth: 640 }}>
                    <Executed handleClose={toggleEditModal} reload={() => {}} agencies={fullAgencies}/>
                  </Paper>
                </Modal>
              </>
            )}

            <ExportDashboardDataButton
              year={year}
              indicators={data.indicators}
              monthlyCompletion={data.monthlyCompletion}
              agency={agencyFilter}
              activity={activityFilter}
              constructionCode={constructionCodeFilter}
              user={userFilter}
            />
          </div>

          <div style={{ display: 'flex', height: '100%', justifyContent: 'center', padding: 20 }}>
            <div style={{ width: '100%' }}>
              <Paper variant="outlined" sx={{ width: '100%', overflow: 'hidden', borderWidth: 2 }}>
                {loading && (<LinearProgress/>)}
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#f3f3f3' }}>
                        <TableCell style={{ padding: 8, fontWeight: 'bold' }}>Indicateurs</TableCell>
                        {existingMonths.map((month) => (
                          <TableCell align="right" style={{
                            padding: 0,
                            borderRight: '1px solid #e0e0e0',
                            borderLeft: '1px solid #e0e0e0',
                          }}>
                            <div style={{ fontWeight: 'bold', borderBottom: '1px solid #e0e0e0' }}>{month}</div>
                            <div style={{
                              display: 'inline-flex',
                              fontSize: 10,
                              fontStyle: 'italic',
                              justifyContent: 'space-evenly',
                              width: '100%',
                            }}>
                              <div>Attendu</div>
                              <div style={{ borderRight: '1px dashed #e0e0e0' }}></div>
                              <div>Réalisé</div>
                            </div>
                          </TableCell>
                        ))}
                        <TableCell style={{ padding: 8, fontWeight: 'bold' }}>% de réalisation</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.indicators.map((indicator) => indicator.display && (
                        <TableRow
                          key={indicator.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          hover
                        >
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'inline-flex', gap: 4 }}>
                              <div style={{ fontWeight: 600 }}>{indicator.group}</div>
                              -
                              <div>{indicator.name}</div>
                            </div>
                          </TableCell>
                          {indicator.data.map((month) => (
                            <TableCell
                              style={{ padding: 0, borderRight: '1px solid #e0e0e0', borderLeft: '1px solid #e0e0e0' }}>
                              <div style={{ display: 'inline-flex', justifyContent: 'center', width: '100%' }}>
                                <div style={{ width: 40 }}>{month.expected || 0}</div>
                                <div style={{ borderRight: '1px dashed #e0e0e0' }}></div>
                                <div style={{ width: 40 }}>{month.executed || 0}</div>
                              </div>
                            </TableCell>
                          ))}
                          <TableCell>
                            {indicator.completion}%
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow
                        key="total"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: '#f3f3f3' }}
                      >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', padding: '16px 0' }}>
                          Pourcentage de réalisation
                        </TableCell>
                        {data.monthlyCompletion.map((value) => (
                          <TableCell
                            style={{ padding: 0, borderRight: '1px solid #e0e0e0', borderLeft: '1px solid #e0e0e0' }}>
                            <div style={{ display: 'inline-flex', justifyContent: 'space-evenly', width: '100%' }}>
                              {value}%
                            </div>
                          </TableCell>
                        ))}
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </>
      ) : (
        <h1>Vous n'avez pas les autorisations pour accéder à cette page</h1>
      )}
    </div>
  )
}

export default Dashboard
