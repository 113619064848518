import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { logUserAPI } from "../API.js";
import InputLog from "../components/inputLog.js";
import SuccessToast from "../components/SuccessToast";

const logUser = async (email, password, history, setErrorMessage, setUser) => {
  const responseJson = await logUserAPI(email, password);
  if (!!responseJson.err) {
    if (responseJson.code === 401)
      setErrorMessage(
        `Votre adresse email ou votre mot de passe n’est pas correct.\n Veillez à bien utiliser votre email professionnel.`
      );
  } else {
    await localStorage.setItem("token", responseJson.token);
    await localStorage.setItem("user", JSON.stringify(responseJson.user));
    await localStorage.setItem("agencyColor", responseJson.agencyColor);
    if (responseJson.firstConnection === true) {
      setUser(JSON.parse(localStorage.getItem("user")));
      history.push("/changepassword");
    } else {
      setUser(JSON.parse(localStorage.getItem("user")));
      history.push("/dashboard");
      SuccessToast("Connecté !");
    }
  }
};

const Login = ({ setUser }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  return (
    <div style={styles.container}>
      <div className="" style={{ paddingTop: "15%" }}>
        <div>
          <InputLog
            holder="Identifiant"
            setItem={setLogin}
            type="email"
            icon="fas fa-envelope"
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <InputLog
            holder="Mot de passe"
            setItem={setPassword}
            type="password"
            icon="fas fa-lock"
          />
        </div>
        <div style={{ marginTop: 20, marginLeft: "40%", marginRight: "40%" }}>
          <p className="has-text-white">{errorMessage}</p>
        </div>
        <div style={{ marginTop: 40 }}>
          <Route
            render={({ history }) => (
              <div className="columns">
                <div className="column">
                  <div className="is-12">
                    <button
                      class="button is-primary"
                      onClick={() =>
                        logUser(login, password, history, setErrorMessage, setUser)
                      }
                    >
                      Se connecter
                    </button>
                  </div>
                  <div className="is-12" style={{ marginTop: 10 }}>
                    <a
                      style={{
                        color: "white",
                        fontStyle: "italic",
                        marginTop: 10,
                      }}
                      onClick={() => history.push("/passwordForgotten")}
                    >
                      Mot de passe oublié ?
                    </a>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundImage: `url('/assets/background.png')`,
    backgroundSize: "cover",

    height: "102vh",
    width: "102	vw",
  },
  box: {
    backgroundColor: "green",
    justifyContent: "center",
  },
};

export default Login;
