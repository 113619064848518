export const fetchCurrentUser = async () => {
  const API_URL = process.env.REACT_APP_API_BASE_URL || 'https://skeeper.etpogroup.com/api/'
  try {
    const response = await fetch(`${API_URL}users/me`, {
      method: "get",
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      localStorage.removeItem("token");
      throw Error(response.statusText);
    }
    const user = await response.json();
    return user;
  } catch (error) {
    console.log(error);
  }
};