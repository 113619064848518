import React from "react";
import Select from "react-select";

const InputAdvancedRight = ({
  label,
  defaultValue,
  values,
  onChange,
  enabled,
}) => {
  const allRules = [
    //Direction groupe + RQSE
    {
      createConstructionCode: true,
      createEditRemoveBackOffice: true,
      createEditRemoveApp: true,
      editDashboard: true,
      exportDashboard: true,
      readDashboard: true,
      useApp: true,
      useBackoffice: true,
      rules: "Direction groupe + RQSE",
    },
    //Direction BU + QSE
    {
      createConstructionCode: true,
      createEditRemoveBackOffice: false,
      createEditRemoveApp: true,
      editDashboard: true,
      exportDashboard: true,
      readDashboard: true,
      useApp: true,
      useBackoffice: true,
      rules: "Direction BU + QSE",
    },
    //Encadrement
    {
      createConstructionCode: true,
      createEditRemoveBackOffice: false,
      createEditRemoveApp: true,
      editDashboard: false,
      exportDashboard: true,
      readDashboard: true,
      useApp: true,
      useBackoffice: true,
      rules: "Encadrement",
    },
    //Maîtrise
    {
      createConstructionCode: false,
      createEditRemoveBackOffice: false,
      createEditRemoveApp: false,
      editDashboard: false,
      exportDashboard: true,
      readDashboard: true,
      useApp: true,
      useBackoffice: true,
      rules: "Maîtrise",
    },
    // Utilisateurs application
    {
      createConstructionCode: false,
      createEditRemoveBackOffice: false,
      createEditRemoveApp: false,
      editDashboard: false,
      exportDashboard: false,
      readDashboard: false,
      useApp: true,
      useBackoffice: false,
      rules: "Utilisateurs application",
    },
  ];

  const checkForRules = (value) => {
		let selectedRules = {};
    allRules.map((rules) => {
      if (rules.rules === value) {
        selectedRules = rules;
      }
    });
		return selectedRules;
  };

  return (
    <div class="columns">
      <div class="column" style={{ alignSelf: "center" }}>
        <p>{label}:</p>
      </div>
      <div class="column">
        <Select
          onChange={(value) => onChange(checkForRules(value.value))}
          defaultValue={!enabled ? { label: defaultValue } : []}
          name="colors"
          isDisabled={!enabled}
          options={values.map((value) => {
            return { value: value, label: value };
          })}
          className="basic-multi-select"
          classNamePrefix="Oui"
        />
      </div>
    </div>
  );
};

export default InputAdvancedRight;
