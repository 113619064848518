import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import './header.css'
// import logo from '../../assets/icons/logoOnly.png'
import logoSkeeper from '../../assets/icons/logo.svg'
import logoWhiteLogo from '../../assets/icons/etpoWhiteLogo.png'
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";

const sectionsTemplate = [
	{title: "Dashboard", isActive: false, url: '/dashboard'},
	{title: "Gestion des attendus", isActive: false, url: '/expected'},
	{title: "Historique formulaire", isActive: false, url: '/historyform'},
	{title: "Gestion des chantiers", isActive: false, url: '/construction'},
	{title: "Gestion des documents", isActive: false, url: '/documents'},
	{title: "Gestion des notifications", isActive: false, url: '/notifications'},
	{title: "Gestion des comptes", isActive: false, url: '/users'},
	{title: "Agences Filiales", isActive: false, url: '/agency'},
	{title: "Gestion de l'application", isActive: false, url: '/application'},
	{title: "Mon compte", isActive: false, url: '/myprofil'},
]


const switchSection = (history, newUrl) => {
	history.push(newUrl)

}

const Header = () => {
	const [sections] = useState(sectionsTemplate)
  const { user } = useContext(UserContext);
	const agencyColor = localStorage.getItem('agencyColor')
	return (
    <div className="header" style={{ backgroundColor: agencyColor }}>
      <div className="columns headerSections">
        <div
          className="column is-1 is-offset-0"
          style={{ width: 80, marginLeft: 20 }}
        >
          <Route
            //
            render={({ history }) => (
              <img
                src={logoSkeeper}
                style={{ height: 40, width: "auto" }}
              />
            )}
          />
        </div>
        {user?.rules.rules != "Utilisateurs application" &&  (
          <div className="column is-1 is-offset-0">
            <Route
              //dashboard

              render={({ history }) => (
                <button
                  onClick={() =>
                    switchSection(history, sectionsTemplate[0].url)
                  }
                  className="btn btn2"
                >
                  {sectionsTemplate[0].title}
                </button>
              )}
            />
          </div>
        )}
        {user?.rules.rules != "Encadrement" &&
          user?.rules.rules != "Utilisateurs application" &&
          user?.rules.rules != "Maîtrise" && (
            <div className="column is-1">
              <Route
                //gestion des attendus

                render={({ history }) => (
                  <button
                    onClick={() =>
                      switchSection(history, sectionsTemplate[1].url)
                    }
                    className="btn btn2"
                  >
                    {sectionsTemplate[1].title}
                  </button>
                )}
              />
            </div>
          )}
        {user?.rules.rules != "Utilisateurs application" && (
          <div className="column is-1">
            <Route
              // historique formulaire

              render={({ history }) => (
                <button
                  onClick={() =>
                    switchSection(history, sectionsTemplate[2].url)
                  }
                  className="btn btn2"
                >
                  {sectionsTemplate[2].title}
                </button>
              )}
            />
          </div>
        )}
        {user?.rules.rules != "Utilisateurs application" && user?.rules.rules != "Maîtrise" &&(
          <div className="column is-1">
            <Route
              // gestion des chantiers

              render={({ history }) => (
                <button
                  onClick={() =>
                    switchSection(history, sectionsTemplate[3].url)
                  }
                  className="btn btn2"
                >
                  {sectionsTemplate[3].title}
                </button>
              )}
            />
          </div>
        )}
        {user?.rules.rules != "Utilisateurs application" && user?.rules.rules != "Maîtrise" &&(
          <div className="column is-1">
            <Route
              // gestion des documents

              render={({ history }) => (
                <button
                  onClick={() =>
                    switchSection(history, sectionsTemplate[4].url)
                  }
                  className="btn btn2"
                >
                  {sectionsTemplate[4].title}
                </button>
              )}
            />
          </div>
        )}
        {user?.rules.rules != "Utilisateurs application" && user?.rules.rules != "Maîtrise" &&(
          <div className="column is-1">
            <Route
              //gestion des notifications

              render={({ history }) => (
                <button
                  onClick={() =>
                    switchSection(history, sectionsTemplate[5].url)
                  }
                  className="btn btn2"
                >
                  {sectionsTemplate[5].title}
                </button>
              )}
            />
          </div>
        )}
        {user?.rules.rules != "Utilisateurs application" && user?.rules.rules != "Maîtrise" &&(
          <div className="column is-1">
            <Route
              // gestion des comptes

              render={({ history }) => (
                <button
                  onClick={() =>
                    switchSection(history, sectionsTemplate[6].url)
                  }
                  className="btn btn2"
                >
                  {sectionsTemplate[6].title}
                </button>
              )}
            />
          </div>
        )}
        {user?.rules.rules === "Direction groupe + RQSE" && (
            <div className="column is-1">
              <Route
                // agences filiales
                render={({ history }) => (
                  <button
                    onClick={() =>
                      switchSection(history, sectionsTemplate[7].url)
                    }
                    className="btn btn2"
                  >
                    {sectionsTemplate[7].title}
                  </button>
                )}
              />
            </div>
          )}
        {user?.rules.rules === "Direction groupe + RQSE" && (
          <div className="column is-1">
            <Route
              // Application
              render={({ history }) => (
                <button
                  onClick={() =>
                    switchSection(history, sectionsTemplate[8].url)
                  }
                  className="btn btn2"
                >
                  {sectionsTemplate[8].title}
                </button>
              )}
            />
          </div>
        )}
      </div>
      <div className="columns headerSections">
        <div
          className="column is-1 is-offset-1"
          style={{ position: "absolute", top: 13, right: 110, height: "100%" }}
        >
          <Route
            render={({ history }) => (
              <button
                onClick={() => switchSection(history, sectionsTemplate[9].url)}
                className="btn btn2"
              >
                {sectionsTemplate[9].title}
              </button>
            )}
          />
        </div>
        <div
          style={{ position: "absolute", top: 0, right: 20, height: "100%" }}
        >
          <Route
            render={({ history }) => (
              <img
                src={logoWhiteLogo}
                style={{
                  height: "115%",
                  marginTop: "-5%",
                  width: "auto",
                  verticalAlign: "middle",
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Header
