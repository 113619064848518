import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { createUserAPI, getCurrentAgenciesAPI } from "../API";
import BackButton from "../components/backButton";
import Select from "react-select";
import SuccessToast from "../components/SuccessToast";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import ErrorToast from "../components/ErrorToast";

const checkEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const checkPhoneNumber = (phoneNumber) => {
  var regExPhoneNumber = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
  return regExPhoneNumber.test(String(phoneNumber));
};

const handlingError = (body, setAnError) => {
  if (body.lastName === null || body.lastName.length <= 0) {
    ErrorToast('Le champs "Nom" doit obligatoirement etre rempli.');
    return false;
  } else if (body.firstName === null || body.firstName.length <= 0) {
    ErrorToast('Le champs "Prenom" doit obligatoirement etre rempli.');
    return false;
  } else if (!checkEmail(body.email)) {
    ErrorToast(`L'email "${body.email}" n'est pas un email valide`);
    return false;
  }
  else if (body.phoneNumber && !checkPhoneNumber(body.phoneNumber)) {
    ErrorToast(`Le format du téléphone "${body.phoneNumber}" n'est pas valide`);
    return false;
  }
  else if (body.rules === null) {
    ErrorToast('Le champs "Niveau d\'accès" doit obligatoirement etre rempli.');
    return false;
  } else if (
    body.rules !== 0 &&
    (body.agency === null ||
      body.agency < 1 ||
      body.activity === null ||
      body.activity < 1)
  ) {
    ErrorToast('Le champs "Agence/Filiales" doit obligatoirement etre rempli.');
    return false;
  } else if (body.backofficeRole === null) {
    ErrorToast( 'Le champs "Poste" doit obligatoirement etre rempli.');
    return false;
  }
  setAnError({ message: null, error: false, type: "" });
  return true;
};

const handleCreationUsers = async (history, body, setBody, setAnError) => {
  if (!handlingError(body, setAnError)) return;
  createUser(body, setAnError, history);
};

const createUser = async (body, setAnError, history) => {
  const responseJson = await createUserAPI(body);
  if (responseJson.error != null) {
    setAnError({ message: responseJson.error, error: true, type: "is-danger" });
    return;
  }
  SuccessToast("Nouveau compte créé !");
  history.goBack();
};

const NewUser = () => {
  const [body, setBody] = useState({
    firstName: null,
    lastName: null,
    email: null,
    rules: null,
    agency: null,
    activity: null,
    backofficeRole: null,
    phoneNumber: null,
  });
  const [agency, setAgency] = useState([{ activity: null, agency: null }]);
  const [error, setAnError] = useState({
    message: null,
    error: false,
    type: "",
  });

  const { user } = useContext(UserContext);

  const userAccess = () => {
    const access = [
      "Direction groupe + RQSE",
      "Direction BU + QSE",
      "Encadrement",
      "Maîtrise",
      "Utilisateurs application",
    ]
    return access.filter((item, index) => {
      return index >= (user?.rules.rules ? access.indexOf(user?.rules.rules) : access.length - 1)
    });
  }

  const getAgency = async (setAgency) => {
    let responseJson = await getCurrentAgenciesAPI()
    if (user?.rules.rules !== 'Direction groupe + RQSE')
      responseJson = responseJson.filter((item) => (user?.agency || []).includes(item.name))
    responseJson = responseJson.sort((a,b)=> (a.name > b.name ? 1 : -1))
    setAgency(responseJson);
  };

  useEffect(() => {
    getAgency(setAgency);
  }, []);
  return (
    <div>
      {user && user?.rules.rules !== "Maîtrise" && user?.rules.rules !== "Utilisateurs application" ? (
        <>
          <div
            class={`notification ${error.type}`}
            style={{
              visibility: error.message === null ? "hidden" : "visible",
            }}
          >
            <button
              class="delete"
              onClick={() =>
                setAnError({ message: null, error: false, type: "" })
              }
            ></button>
            <p>{error.message}</p>
          </div>
          <BackButton />
          <div className="columns is-centered">
            <div className="column is-12">
              <h1 className="has-text-weight-bold	is-size-1">
                Créer un nouvel utilisateur
              </h1>
              <h2 className="has-text-weight-semi-bold is-size-5">
                Complétez les différents champs afin de créer le nouvel
                utilisateur
              </h2>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-3">
              <p className="has-text-right">Nom<span style={{color: 'red'}}>*</span></p>
            </div>
            <div className="column is-3">
              <input
                className="input"
                onChange={(event) =>
                  setBody({ ...body, lastName: event.target.value })
                }
                type="text"
                placeholder="Nom"
              ></input>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-3">
              <p className="has-text-right">Prénom<span style={{color: 'red'}}>*</span></p>
              <p className="has-text-right has-text-danger is-size-7">
                Merci de saisir correctement l’orthographe des Nom et Prénom,
                ils seront vus par votre collaborateur
              </p>
            </div>
            <div className="column is-3">
              <input
                className="input"
                onChange={(event) =>
                  setBody({ ...body, firstName: event.target.value })
                }
                type="text"
                placeholder="Prénom"
              ></input>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-3">
              <p className="has-text-right">Email nouvel utilisateur<span style={{color: 'red'}}>*</span></p>
            </div>
            <div className="column is-3">
              <input
                className="input"
                onChange={(event) =>
                  setBody({ ...body, email: event.target.value })
                }
                type="text"
                placeholder="Email nouvel utilisateur"
              ></input>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-3">
              <p className="has-text-right">Téléphone nouvel utilisateur</p>
            </div>
            <div className="column is-3">
              <input
                className="input"
                onChange={(event) =>
                  setBody({ ...body, phoneNumber: event.target.value })
                }
                type="text"
                placeholder="Téléphone nouvel utilisateur"
              ></input>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-3">
              <p className="has-text-right">Niveau d'accès<span style={{color: 'red'}}>*</span></p>
              <p className="has-text-right has-text-danger is-size-7">
                Attention si vous n'avez pas le plus haut rôle, vous ne pouvez
                pas créer de compte pour le backoffice
              </p>
            </div>
            <div className="column is-3">
              <div className=" is-rounded">
                <Select
                  onChange={(value) =>
                    setBody({ ...body, rules: value.index })
                  }
                  defaultValue={[]}
                  name="colors"
                  options={userAccess().map((value, index) => {
                    return { value, label: value, index };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="Oui"
                />
              </div>
            </div>
          </div>
          {body.rules !== 0 && (
            <div className="columns is-centered">
              <div className="column is-3">
                <p className="has-text-right">Agence(s)/Filiale(s)<span style={{color: 'red'}}>*</span></p>
              </div>
              <div className="column is-3">
                <div className=" is-rounded">
                  <Select
                    onChange={(event) => {
                      setBody({
                        ...body,
                        agency: event.map((value) => value.agency),
                        activity: event.map((value) => value.activity),
                      });
                    }}
                    isMulti
                    defaultValue={[]}
                    name="colors"
                    options={agency.map((value) => {
                      return {
                        value,
                        label: value.name,
                        agency: value.name,
                        activity: value.activity,
                      };
                    })}
                    className="basic-multi-select"
                    classNamePrefix="Oui"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="columns is-centered">
            <div className="column is-3">
              <p className="has-text-right">Poste<span style={{color: 'red'}}>*</span></p>
            </div>
            <div className="column is-3">
              <div className=" is-rounded">
                <Select
                  onChange={(value) =>
                    setBody({ ...body, backofficeRole: value.label })
                  }
                  defaultValue={[]}
                  name="colors"
                  options={[
                    { value: 0, label: "Responsable BU" },
                    { value: 1, label: "Responsable d'Exploitation" },
                    { value: 2, label: "Préventeur.ice" },
                    { value: 3, label: "Responsable Encadrement" },
                    { value: 4, label: "Responsable Maîtrise" },
                    { value: 5, label: "Autres" },
                  ]}
                  className="basic-multi-select"
                  classNamePrefix="Oui"
                />
              </div>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-3">
              <Route
                render={({ history }) => (
                  <button
                    className="button is-success"
                    onClick={() => handleCreationUsers(history, body, setBody, setAnError)}
                  >
                    Créer un nouvel utilisateur
                  </button>
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <h1>Vous n'avez pas les droits pour accéder à cette page...</h1>
      )}
    </div>
  );
};

export default NewUser;
