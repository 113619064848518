import firebase from "firebase/app"
import  "firebase/storage"


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBixdSIR8hyMUV4HmuCl4dWlJN7BCP_YmM",
	authDomain: "technirep-c7c1b.firebaseapp.com",
	projectId: "technirep-c7c1b",
	storageBucket: "technirep-c7c1b.appspot.com",
	messagingSenderId: "662214129917",
	appId: "1:662214129917:web:50cad19f0806346442e694",
	measurementId: "G-DY5KJ9Z0J0"
  };

  firebase.initializeApp(firebaseConfig)

  const storage = firebase.storage()
	const storageRef = storage.ref();

  export {storage, storageRef, firebase as default}